import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';
import {readAndCompressImage} from 'browser-image-resizer';

import {SignInContext, LanguageContext, UserContext} from 'context';
import {translations} from 'resources';
import {apiURL, axiosClient} from 'service';
import styles from './profile-modal.module.css';

export default function ProfileModal(props) {
  const {signOut} = useContext(SignInContext);
  const {
    state: {user},
    resetUser,
    setUser,
  } = useContext(UserContext);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    modal_container,
    profile_card_container,
    icon_container,
    name_info_container,
    name_text,
    mail_text,
    settings_link_container,
    signout_container,
    spacer,
    photo_icon,
    icons_container,
    profile_photo,
  } = styles;

  //TODO: move them to context
  const resizeFiles = async (files, maxWidth, maxHeight) => {
    const config = {
      quality: 1,
      maxWidth: maxWidth || 2048,
      maxHeight: maxHeight || 2048,
      mimeType: 'image/jpeg',
      // autoRotate: true,
      // debug: true
    };
    let fileLen = files.length;
    for (let a = 0; a < fileLen; a++) {
      let resizedImage = await readAndCompressImage(files[a], config);
      let extensionTrim = files[a].name.substring(files[a].name.lastIndexOf('.'));
      extensionTrim = files[a].name.replace(extensionTrim, '');
      resizedImage = new File([resizedImage], 'profilePhoto.jpg', {
        type: files[a].type,
      });
      console.log('new file', resizedImage);
      files[a] = resizedImage;
      console.log('files', files);
    }
    return files;
  };
  //TODO: move them to context
  const fileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
      onUploadProgress: (ProgressEvent) => {
        console.log('progress :', (ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    };
    let response = await axiosClient.post(apiURL.profilePhoto, formData, config);
    console.log('res', response);
    return response.data;
  };
  //TODO: move them to context
  const onFileChange = async (files) => {
    const fileArray = [];
    console.log('files.length', files.length);
    if (!files?.length) return;
    for (let i = 0; i < files.length; i++) fileArray.push(files.item(i));
    files = await resizeFiles(fileArray, 1024, 1024);
    console.log('files.length2', files.length);

    if (files?.[0]) {
      console.log('files.length3', files.length);

      var file = files[0];
      const photoUrl = await fileUpload(file);
      setUser({...user, photoUrl});
      // setPhotoQuery(new Date());
    }
  };

  return ReactDOM.createPortal(
    <div onClick={props.closeModal} className={modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={profile_card_container}>
        <div className={icons_container}>
          <div className={icon_container}>
            {user.photoUrl ? (
              <img className={profile_photo} alt="Profile" src={process.env.PUBLIC_URL + user.photoUrl} />
            ) : (
              <i aria-hidden="true" url className="user mini icon "></i>
            )}
          </div>
          <div className={photo_icon}>
            <label>
              <input type="file" onChange={({target: {files}}) => onFileChange(files)} />
              <Icon name="photo" />
            </label>
          </div>
        </div>
        <div className={name_info_container}>
          <div className={name_text}>{(user.firstName || '') + ' ' + (user.lastName || '')}</div>
          <div className={mail_text}>{user.email || ''}</div>
        </div>
        <div className={settings_link_container}>
          <Link onClick={props.closeModal} to="/accountSettings">
            <span>{translations[appLanguage]['g40']}</span>
          </Link>
        </div>
        <div className={spacer} />
        <div className={signout_container}>
          <Link
            onClick={() => {
              signOut();
              resetUser();
            }}
            to="/"
          >
            <span>{translations[appLanguage]['g24']}</span>
          </Link>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
