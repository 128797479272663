import axios from 'axios';
import apiURL from 'service/apiURL';
import {customResponse} from 'service/interceptors';

const createAxiosClient = () => {
  const axiosClient = axios.create({
    baseURL: apiURL.base,
    withCredentials: true,
    validateStatus: () => true,
  });
  axiosClient.interceptors.response.use(customResponse);
  return axiosClient;
};

const axiosClient = createAxiosClient();

export default axiosClient;
