import React, {useEffect, useState, useContext} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './homeworks.module.css';
import {parsePageSettings} from 'helpers/page';

export default function HomeworksPage(props) {
  const [homeworks, setHomeworks] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  let pageSettings = parsePageSettings(props.match.params.id);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const columns = [
    {
      title: 'Öğrenci',
      dataIndex: 'studentId',
      render: (e) => {
        const user = users.find((el) => el._id === e);
        return user?.firstName + ' ' + user?.lastName;
      },
      key: '1',
    },
    {
      title: 'Puan',
      render: (e, record) => {
        return record?.categories?.length
          ? record.categories.reduce((p, c) => {
              const category = categories.find((el) => el._id === c.categoryId);
              return p + (category?.volume || 0) * Math.max(c?.frequency || 0, c?.dangerLevel || 0);
            }, 0)
          : 'Değerlendirilmedi';
      },
      key: 'durum',
    },
    {
      title: '#',
      key: 'operation',
      fixed: 'right',
      width: 160,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el, record) =>
        new Date(record?.endDate > Date.now()) ? (
          <div style={{display: 'flex', gap: '8px'}}>
            <a
              onClick={() =>
                props.history.push(`${process.env.PUBLIC_URL}/scoringHomework${JSON.stringify({homeworkId: el._id})}`)
              }
            >
              Ödevi Görüntüle
            </a>
            <a
              onClick={() =>
                props.history.push(`${process.env.PUBLIC_URL}/report${JSON.stringify({homeworkId: el?._id})}`)
              }
            >
              Raporu Görüntüle
            </a>
          </div>
        ) : (
          <a
            onClick={() =>
              props.history.push(`${process.env.PUBLIC_URL}/scoringHomework${JSON.stringify({homeworkId: el._id})}`)
            }
          >
            {record?.categories?.length ? 'Yeniden Değerlendir' : 'Değerlendir'}
          </a>
        ),
    },
  ];

  const getHomeworks = async (_id) => {
    const {data} = await axiosClient.get(apiURL.homeworks + '?cycleId=' + pageSettings?.cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setHomeworks(data);
    }
  };
  const getUsers = async (_id) => {
    const {data} = await axiosClient.get(apiURL.users, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setUsers(data);
    }
  };
  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };
  useEffect(() => {
    getHomeworks();
    getUsers();
    getCategories();
  }, []);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={homeworks} scroll={{x: 800, y: 300}} />
      {(user.isMaster || user.isAdmin || user.role === 'teacher') && <BottomMenu {...props} url={'/scoreHomework'} />}
    </div>
  );
}
