import {SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SET_USER, RESET_USER, SET_UNREAD_MESSAGES} from 'context/user/keys';

export const getUser = (dispatch) => async () => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(apiURL.user, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (data.email) {
    dispatch({type: SET_USER, payload: data});
  }
};

export const pushUser = (dispatch) => async (user) => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.put(apiURL.user, user, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
};

export const getMessageCount = (dispatch) => async () => {
  const {data} = await axiosClient.get(apiURL.unreadMessages, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  dispatch({type: SET_UNREAD_MESSAGES, payload: data?.messageCount || 0});
};

export const setUser = (dispatch) => async (user) => {
  dispatch({type: SET_USER, payload: user});
};

export const resetUser = (dispatch) => async () => {
  dispatch({type: RESET_USER});
};
