import {SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SIGN_IN_USER_SUCCESS, SIGN_OUT_USER_SUCCESS, SIGN_IN_ERROR} from 'context/signIn/keys';
import history from 'history.js';

export const signInUser = (dispatch) => async (user) => {
  dispatch({type: SET_LOADING});
  const {success, token, error} = (await axiosClient.post(apiURL.signIn, user)).data;

  if (success) {
    dispatch({type: SIGN_IN_USER_SUCCESS});
    localStorage.setItem('token', token);
    history.push('/main');
  } else {
    dispatch({type: SIGN_IN_ERROR, payload: error});
  }
};

export const signOut = (dispatch) => async () => {
  dispatch({type: SIGN_OUT_USER_SUCCESS});
  localStorage.removeItem('token');
};
