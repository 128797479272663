import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';

import {LanguageContext, UserContext} from 'context';
import {translations} from 'resources';
import styles from './sidebar.module.css';

export default function Sidebar() {
  const [path, setPath] = useState(window.location.pathname);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user, unreadMessages},
  } = useContext(UserContext);

  const {sidebar_container, menu_list_container, menu_icon, active_link, dropdown_menu_container} = styles;

  const renderLink = (name, url, iconName) => {
    if (url.indexOf('/') !== 0) url = '/' + url;
    return (
      <Link onClick={() => setPath(url)} to={url} className={`${path === {url} ? active_link : ''}`}>
        <Icon className={menu_icon} name={iconName} />
        <span>{name}</span>
      </Link>
    );
  };

  return (
    <div className={sidebar_container}>
      {!user.isAdmin && !user.isMaster && (
        <div>
          {'Üyelik Tipi: ' +
            (user.role === 'student'
              ? 'Öğrenci'
              : user.role === 'teacher'
              ? user.isCommissionLeader
                ? 'Komisyon Başkanı'
                : 'Komisyon Üyesi'
              : 'Kurum')}
        </div>
      )}
      <nav className={menu_list_container}>
        {renderLink('Özet', '/main', 'home')}
        {/* {(user.isAdmin || user.isMaster) && renderLink('Universiteler', '/universities', 'book')} */}
        {renderLink('Mesajlar' + (unreadMessages ? '(' + unreadMessages + ')' : ''), '/messages', 'mail')}
        {(user.isAdmin || user.isMaster || user.role === 'teacher') &&
          renderLink('Akademisyenler', '/teachers', 'balance scale')}
        {/* {(user.isAdmin || user.isMaster) && renderLink('Komisyonlar', '/commissions', 'eye')} */}
        {/* {user.role !== 'company' && renderLink('Kurumlar', '/companies', 'building')} */}
        {(user.isAdmin || user.isMaster || user.role !== 'student') && renderLink('Öğrenciler', '/users', 'user')}
        {/* {(user.isAdmin || user.isMaster || user.role !== 'company') && renderLink('Kazanımlar', '/gains', 'calendar plus')} */}
        {user.role !== 'student' && renderLink('Kategoriler', '/categories', 'list ol')}
        {user.role !== 'student' && renderLink('Ödev Dönemleri', '/cycles', 'file audio outline')}
        {user.role !== 'student'
          ? renderLink('Yüklenen Ödevler', '/cyclesForHomework', 'tasks')
          : renderLink('Ödevler', '/cyclesForHomework', 'tasks')}
        {user.role !== 'student' && renderLink('Demografik Veri Raporları', '/generalReports', 'chart area')}
        {/* {renderLink('Görev İlanları', '/tasks', 'check')} */}
        {/* {renderLink(
          user.role === 'student' ? 'Görevlerim' : user.role === 'company' ? 'Görevler/Başvurular' : 'Aktif Görevler',
          '/taskApplications',
          'file'
        )} */}
        {/* { renderLink(translations[appLanguage]['g8'], '/accountSettings', 'settings')} */}
        {/* {user.role === 'teacher' && renderLink('Komisyon Ayarları', '/commissionSettings', 'settings')} */}
      </nav>
    </div>
  );
}
