import {baseReducer} from 'context/shared';
import {SET_USER, RESET_USER, SET_UNREAD_MESSAGES} from 'context/user/keys';

export const initialState = {
  user: {},
  isSigned: false,
  unreadMessages: 0,
};

export default baseReducer((state = initialState, {type, payload}) => {
  switch (type) {
    case SET_USER:
      return {...state, user: payload, isSigned: true};
    case SET_UNREAD_MESSAGES:
      return {...state, unreadMessages: payload};
    case RESET_USER:
      return {...initialState};
    default:
      return state;
  }
});
