import React, {useEffect, useState, useContext} from 'react';
import {Table, Tabs, Tooltip, Select} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './report.css';
import {Button, Input} from 'semantic-ui-react';
import {parsePageSettings} from 'helpers/page';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip as ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import {PolarArea, Radar, Bar, Pie} from 'react-chartjs-2';
import home from '../home';
import {useParams} from 'react-router-dom';

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement
);

// const levels = [
//   {maxPoint: 5, color:"#FFBDBC" risk: 'ÇOK DÜŞÜK', result: 'ÖNEMSİZ RİSKLER', action: 'lorem impusm dolor sit ametxw11111111111111'},
//   {maxPoint: 10, risk: 'DÜŞÜK2', result: 'KATLANILABİLİR RİSKLER', action: 'lorem impusm dolor sit ametxw2222222222'},
//   {maxPoint: 15, risk: 'DÜŞÜK3', result: 'KATLANILABİLİR RİSKLER2', action: 'lorem impusm dolor sit ametxw3333333333'},
//   {maxPoint: 20, risk: 'DÜŞÜK4', result: 'KATLANILABİLİR RİSKLER3', action: 'lorem impusm dolor sit ametxw4444444444'},
//   {maxPoint: 25, risk: 'DÜŞÜK5', result: 'KATLANILABİLİR RİSKLER4', action: 'lorem impusm dolor sit ametxw5555555555'},
// ];

export default function GainsPage(props) {
  const [homeworks, setHomeworks] = useState([]);
  const [students, setStudents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartItems, setCharItems] = useState([]);
  const [categoryAverages, setCategoryAverages] = useState({});
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [availableCategories, setAvailableCategories] = useState([]);

  const params = useParams();
  console.log('params', params);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };

  const getHomeworks = async () => {
    const {data} = await axiosClient.get(apiURL.homeworks, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?.[0]) {
      setHomeworks(data);
    }
  };

  const getStudents = async () => {
    const {data} = await axiosClient.get(apiURL.students, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?.[0]) {
      setStudents(data);
    }
  };

  useEffect(() => {
    getCategories();
    getHomeworks();
    getStudents();
    setSelectedTab('Deprem Dersi');
  }, []);

  useEffect(() => {
    if (!homeworks?.length || !categories?.length) return;

    const categoryAverages = {};
    homeworks
      .filter((el) => el.categories?.length)
      .forEach((homework) => {
        const currentCategories = JSON.parse(JSON.stringify(categories));
        const calcAverageFirst = () => {
          currentCategories.forEach((el) => {
            const subCategories = homework.categories.filter(
              (el2) => el._id === currentCategories.find((el3) => el3._id === el2.categoryId)?.parentId
            );
            let total = 0;
            const average = Math.round(
              subCategories.reduce((a, b) => {
                const volume = currentCategories.find((el3) => el3._id === b.categoryId)?.volume || 0;
                const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
                if (score) total++;
                return a + score;
              }, 0) / total
            );
            el.averageScore = average;
            if (average && availableCategories.indexOf(el._id) === -1)
              setAvailableCategories([...availableCategories, el._id]);

            // console.log('average', average, el.name);
          });
        };
        const calcAverage = () => {
          currentCategories.forEach((el) => {
            const subCategories = currentCategories.filter((el2) => el._id === el2.parentId);
            let total = 0;
            const average = Math.round(
              subCategories.reduce((a, b) => {
                const volume = currentCategories.find((el3) => el3._id === b.categoryId)?.volume || 0;
                const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
                if (score) total++;
                return a + score;
              }, 0) / total
            );
            if (average) el.averageScore = average;
            if (average && availableCategories.indexOf(el._id) === -1)
              setAvailableCategories([...availableCategories, el._id]);

            // console.log('average', average, el.name);
          });
        };
        calcAverageFirst();
        calcAverage();
        calcAverage();
        calcAverage();
        calcAverage();
        calcAverage();
        calcAverage();
        calcAverage();
        calcAverage();
        const averages = currentCategories
          .filter((el) => el.averageScore)
          .map((el) => ({averageScore: el.averageScore, name: el.name, _id: el._id}));
        if (!selectedCategory) categoryAverages[homework._id] = averages?.[0];
        else {
          categoryAverages[homework._id] = {
            averageScore: currentCategories.find((el) => el._id === selectedCategory).averageScore,
          };
        }
      });
    setCategoryAverages(categoryAverages);
  }, [homeworks, categories, selectedCategory]);

  // {
  //   label: el.name,
  //   key: el._id,
  //   averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
  // }

  const renderChart = (chartItems, label = 'Ana Kategori Puanı') => {
    const options = {
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    const data = {
      labels: chartItems.map((el) => el.label),
      datasets: [
        {
          label: 'Proje Puanı',
          data: [5, 10],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Genel Ortalama',
          data: [7, 14],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '500px', height: '500px'}}>
          <Pie
            options={{
              scales: {},
            }}
            data={{
              labels: chartItems.map((el) => el.label),
              datasets: [
                {
                  label,
                  data: chartItems.map((el) => el.averageScore),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  };

  const tabs = [
    {label: 'Deprem Dersi', key: 'Deprem Dersi'},
    {label: 'Deprem Deneyimi', key: 'Deprem Deneyimi'},
    {label: 'Cinsiyet', key: 'Cinsiyet'},
    {label: 'Üniversite', key: 'Üniversite'},
    {label: 'Sınıf', key: 'Sınıf'},
    {label: 'Yaş', key: 'Yaş'},
  ];

  useEffect(() => {
    const tab = params?.tab || selectedTab;
    if (tab === 'Deprem Dersi') {
      const validHomeworks = homeworks.filter((el) => el.categories?.length);
      const positiveStudents = students.filter((el) => el.earthquakeClassesTaken);
      const negativeStudents = students.filter((el) => !el.earthquakeClassesTaken);
      const positiveHomeworks = validHomeworks.filter((el) => positiveStudents.find((el2) => el2._id === el.studentId));
      const negativeHomeworks = validHomeworks.filter((el) => negativeStudents.find((el2) => el2._id === el.studentId));

      setCharItems([
        {
          label: 'Deprem Dersi Alanlar',
          key: 'Deprem Dersi Alanlar',
          averageScore: Math.round(
            positiveHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              positiveHomeworks.length
          ),
        },
        {
          label: 'Deprem Dersi Almayanlar',
          key: 'Deprem Dersi Almayanlar',
          averageScore: Math.round(
            negativeHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              negativeHomeworks.length
          ),
        },
      ]);
    } else if (tab === 'Deprem Deneyimi') {
      const validHomeworks = homeworks.filter((el) => el.categories?.length);
      const positiveStudents = students.filter((el) => el.earthquakeExperienced);
      const negativeStudents = students.filter((el) => !el.earthquakeExperienced);
      const positiveHomeworks = validHomeworks.filter((el) => positiveStudents.find((el2) => el2._id === el.studentId));
      const negativeHomeworks = validHomeworks.filter((el) => negativeStudents.find((el2) => el2._id === el.studentId));

      setCharItems([
        {
          label: 'Deprem Deneyimi Olanlar',
          key: 'Deprem Deneyimi Olanlar',
          averageScore: Math.round(
            positiveHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              positiveHomeworks.length
          ),
        },
        {
          label: 'Deprem Deneyimi Olmayanlar',
          key: 'Deprem Deneyimi Olmayanlar',
          averageScore: Math.round(
            negativeHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              negativeHomeworks.length
          ),
        },
      ]);
    } else if (tab === 'Cinsiyet') {
      const validHomeworks = homeworks.filter((el) => el.categories?.length);
      const positiveStudents = students.filter((el) => el.gender === 'male');
      const negativeStudents = students.filter((el) => el.gender !== 'female');
      const positiveHomeworks = validHomeworks.filter((el) => positiveStudents.find((el2) => el2._id === el.studentId));
      const negativeHomeworks = validHomeworks.filter((el) => negativeStudents.find((el2) => el2._id === el.studentId));

      setCharItems([
        {
          label: 'Kadınlar',
          key: 'Kadınlar',
          averageScore: Math.round(
            negativeHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              negativeHomeworks.length
          ),
        },
        {
          label: 'Erkekler',
          key: 'Erkekler',
          averageScore: Math.round(
            positiveHomeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) /
              positiveHomeworks.length
          ),
        },
      ]);
    } else if (tab === 'Üniversite') {
      const universities = [...new Set(students.map((el) => el.university).filter((el) => el))];
      const universityHomeWorks = universities.map((university) => {
        const validHomeworks = homeworks.filter((el) => el.categories?.length);
        const studentsOfUniversity = students.filter((el) => el.university === university);
        const universityHomeworks = validHomeworks.filter((el) =>
          studentsOfUniversity.find((el2) => el2._id === el.studentId)
        );
        return {
          university,
          homeworks: universityHomeworks,
        };
      });

      setCharItems(
        universityHomeWorks.map((el) => ({
          label: el.university,
          averageScore: Math.round(
            el.homeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) / el.homeworks.length
          ),
        }))
      );
    } else if (tab === 'Sınıf') {
      const classes = [1, 2, 3, 4];
      const groupedHomeworks = classes.map((classLevel) => {
        const validHomeworks = homeworks.filter((el) => el.categories?.length);
        const studentsOfUniversity = students.filter((el) => el.classLevel === classLevel);
        const universityHomeworks = validHomeworks.filter((el) =>
          studentsOfUniversity.find((el2) => el2._id === el.studentId)
        );
        return {
          classLevel,
          homeworks: universityHomeworks,
        };
      });

      setCharItems(
        groupedHomeworks.map((el) => ({
          label: el.classLevel,
          averageScore: Math.round(
            el.homeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) / el.homeworks.length
          ),
        }))
      );
    } else if (selectedTab === 'Yaş') {
      const ages = [...new Set(students.map((el) => el.age).filter((el) => el))];
      const ageHomeworks = ages.map((age) => {
        const validHomeworks = homeworks.filter((el) => el.categories?.length);
        const studentsOfUniversity = students.filter((el) => el.age === age);
        const universityHomeworks = validHomeworks.filter((el) =>
          studentsOfUniversity.find((el2) => el2._id === el.studentId)
        );
        return {
          age,
          homeworks: universityHomeworks,
        };
      });

      setCharItems(
        ageHomeworks.map((el) => ({
          label: el.age,
          averageScore: Math.round(
            el.homeworks.reduce((a, b) => a + categoryAverages[b._id]?.averageScore || 0, 0) / el.homeworks.length
          ),
        }))
      );
    }
  }, [selectedTab, homeworks, students, categoryAverages, params?.tab]);

  useEffect(() => {
    if (categories.length && !selectedCategory) {
      setSelectedCategory(categories.find((el) => !el.parentId)._id);
      console.log(
        '<dasDSADASD',
        categories.filter((el) => !el.parentId)
      );
    }
  }, [categories]);

  return (
    <div className="users_container">
      <h1>Demografik Veri Raporları</h1>
      <div style={{margin: '5px'}}>
        {!params?.tab ? (
          <Tabs items={tabs} onChange={(e) => setSelectedTab(e)} />
        ) : (
          <div style={{display: 'flex', gap: '8px'}}>
            <div>Analiz : </div>
            <div>{params?.tab}</div>
          </div>
        )}
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <div>Kategori</div>
          {categories.length > 0 && (
            <Select
              placeholder="Kategori Seç"
              style={{width: '200px', margin: '5px'}}
              showSearch
              disabled={params?.category}
              optionFilterProp="label"
              value={params?.category && params?.category !== 'all' ? params?.category : selectedCategory}
              options={categories
                .filter((el) => !el.isLeaf)
                // .filter((el) => params?.category || availableCategories.indexOf(el._id) !== -1)
                .map((el) => ({label: el.name, value: el._id}))}
              onChange={(e) => setSelectedCategory(e)}
            />
          )}
        </div>

        <div style={{width: '100%', margin: '5px'}}>{renderChart(chartItems, selectedTab)}</div>
        <div style={{display: 'flex', gap: '16px'}}>
          {chartItems
            .filter((el) => el.averageScore)
            .map((el) => (
              <div style={{borderRight: '1px solid', minWidth: '100px', padding: '4px'}}>
                <div style={{fontWeight: 600}}>{el.label}</div>
                <div>{el.averageScore}</div>
              </div>
            ))}
        </div>
      </div>
      {!params.tab ? (
        <a href={`/generalReports/${selectedTab}/${selectedCategory || 'all'}`} target="_blank">
          Yazdırma Sayfasına Git
        </a>
      ) : (
        <div style={{padding: '32px', display: 'flex'}}>
          <Button className="no-print" onClick={() => window.print()}>
            Print
          </Button>
        </div>
      )}
    </div>
  );
}
