import {SET_ERROR, SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SIGN_UP_USER_SUCCESS} from 'context/signUp/keys';
import history from 'history.js';

export const signUpUser = (dispatch) => async (user, callBack) => {
  dispatch({type: SET_LOADING});
  const {success, token, error} = (await axiosClient.post(apiURL.signUp, user)).data;

  if (success) {
    dispatch({type: SIGN_UP_USER_SUCCESS});
    localStorage.setItem('token', token);
    if (callBack) callBack();
    history.push('/main');
  } else {
    dispatch({type: SET_ERROR, payload: error});
  }
};
