import React, {useState} from 'react';

import BottomMenuModal from 'ui/components/bottom-menu-modal';
import styles from './bottom-menu.module.css';

export default function BottomMenu(props) {
  const [showMenu, setShowMenu] = useState(false);
  const {bottom_menu_container, menu_icon_container, menu_icon_dot, menu_icon_x} = styles;

  const renderBottomMenuModal = () => {
    if (!showMenu) return;

    return <BottomMenuModal {...props}  closeModal={() => setShowMenu(false)} />;
  };

  return (
    <>
      {renderBottomMenuModal()}
      <div className={bottom_menu_container}>
        <div onClick={() => setShowMenu(!showMenu)} className={menu_icon_container}>
          {!showMenu ? (
            <div>
              <span className={menu_icon_dot}>.</span>
              <span className={menu_icon_dot}>.</span>
              <span className={menu_icon_dot}>.</span>
            </div>
          ) : (
            <div>
              <span className={menu_icon_x}>X</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
