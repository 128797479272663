import React, {useEffect, useState, useContext} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';
import {UserContext} from 'context';
import {apiURL, axiosClient} from 'service';
import './messages-page.module.css';


export default function MessagesPage(props) {
  const [titles, setTitles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [key, setKey] = useState('');
  const columns = [
    {title: 'İsim', dataIndex: 'contact', key: '0'},
    {title: 'Son Mesaj', key: '1', dataIndex: 'text'},
    {
      title: 'Görüntüle',
      key: '2',
      fixed: 'right',
      width: 120,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/chat${JSON.stringify({otherUserId: el.contactId})}`)}>Sohbete git</a>
      ),
    },
  ];

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const getTitles = async (_id) => {
    const {data} = await axiosClient.get(apiURL.messageTitles, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTitles(data);
      getContacts();
    }
  };

  const getContacts = async () => {
    const {data} = await axiosClient.get(apiURL.contacts, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
    if (Array.isArray(data)) {
      console.log('a', data);
      setContacts(data?.map?.((el) => ({_id: el._id, text: el.name})));
    }
  };

  useEffect(() => {
    getTitles();
  }, []);


  // useEffect(() => {
  //   getTitles();
  // }, [titles]);

  useEffect(() => {
    setTitles(
      titles.map((el) => {
        let contact, contactId;
        if (el.receiverId === user._id) contact = contacts.find((item) => el.senderId === item._id);
        else if (el.senderId === user._id) contact = contacts.find((item) => el.receiverId === item._id);
        if (!contact) return '';

        contactId = contact._id;
        contact = contact.text;
        // console.log('contactId', contactId);
        return {...el, contact, contactId};
      })
    );
    setKey(new Date().toString());
  }, [contacts]);

  return (
    <div className="users_container">
      <Table key={key} columns={columns} dataSource={titles} scroll={{x: 800, y: 300}} />
      <BottomMenu {...props} url={'/newChat'} />
    </div>
  );
}
