import React, {useEffect, useState, useContext} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './categories-page.css';
import Search from 'antd/lib/input/Search';

export default function GainsPage(props) {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [gains, setGains] = useState([]);
  const [searchKey, setSearchKey] = useState('');

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const adminColumns = [
    {
      title: 'İsim',
      dataIndex: 'fullName',
      key: '2',
    },
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/categoryEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const columns = [
    {
      title: 'İsim',
      dataIndex: 'fullName',
      key: '1',
    },
  ];

  const getTaskCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      data.forEach((record) => {
        record.key = record._id;

        console.log('categories', record, data);
        const parents = [record.name];
        const getParents = (category) => {
          console.log('category', category);
          if (category.parentId) {
            const parent = data.find((el) => el._id === category.parentId);
            if (parent) {
              parents.push(parent.name);
              getParents(parent);
            }
          }
        };
        getParents(record);
        record.fullName = parents.reverse().join(' -> ');
      });
      console.log('data-----------------', data);
      setCategories(data);
    }
  };

  useEffect(() => {
    getTaskCategories();
  }, []);
  useEffect(() => {
    if (searchKey) setFilteredCategories(categories.filter((el) => el.fullName?.toLowerCase?.().includes?.((searchKey || '').toLowerCase?.())));
    else setFilteredCategories(categories);
    console.log('categories', categories);
  }, [categories, searchKey]);

  return (
    <div className="users_container">
      <Search value={searchKey} onChange={(e) => setSearchKey(e.target.value)} placeholder="Kategori Araması" allowClear />
      <Table columns={user.isMaster || user.isAdmin ? adminColumns : columns} dataSource={filteredCategories} scroll={{x: 800, y: 300}} />
      {(user.isMaster || user.isAdmin) && <BottomMenu {...props} url={'/categoryEdit'} />}
    </div>
  );
}
