export const parsePageSettings = pageSettings => {
  let settings;
  if (pageSettings) {
    try {
      settings = JSON.parse(pageSettings);
    } catch {
      settings = {};
    }
  } else {
    settings = {};
  }
  return settings;
};
