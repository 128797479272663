import React, {useEffect, useState, useContext} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './homework-cycles-page.css';
import {WindowsFilled} from '@ant-design/icons';

export default function GainsPage(props) {
  const [cycles, setCycles] = useState([]);
  const [homeworks, setHomeworks] = useState([]);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const columns = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: '1',
    },
    {
      title: 'Durum',
      render: (e, record) => {
        return new Date(record.startDate).getTime() > Date.now()
          ? 'Henüz Başlamadı'
          : new Date(record.endDate).getTime() > Date.now()
          ? 'Ödev Yükleme Süreci'
          : new Date(record.scoringEndDate).getTime() > Date.now()
          ? 'Değerlendirme Sürecinde'
          : 'Sonuçlandı';
      },
      key: 'durum',
    },
    {
      title: 'Başlangıç Tarihi',
      render: (e, record) => new Date(record.startDate)?.toLocaleDateString(),
      key: '2',
    },
    {
      title: 'Teslim Tarihi',
      render: (e, record) => new Date(record.endDate)?.toLocaleDateString(),
      key: '3',
    },
    {
      title: 'Değerlendirme Tarih',
      render: (e, record) => new Date(record.scoringEndDate)?.toLocaleDateString(),
      key: '4',
    },
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 120,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el, record) => {
        const homework = homeworks.find((el2) => el2.cycleId === record._id);
        return window.location.href?.indexOf('cyclesForHomework') < 0 ? (
          <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/cycleEdit${JSON.stringify({_id: record._id})}`)}>Düzenle</a>
        ) : user.role === 'student' ? (
          new Date(el.endDate).getTime() > Date.now() ? (
            <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/loadHomeWork${JSON.stringify({cycleId: record._id})}`)}>Ödev Yükleme</a>
          ) : (
            <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/report${JSON.stringify({homeworkId: homework?._id})}`)}>
              Raporu Görüntüle
            </a>
          )
        ) : (
          <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/homeworks${JSON.stringify({cycleId: record._id})}`)}>Ödevleri Görüntüle</a>
        );
      },
    },
  ];

  const getCycles = async (_id) => {
    const {data} = await axiosClient.get(apiURL.cycles, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCycles(data);
    }
  };

  const getHomeworks = async (cycleId) => {
    const {data} = await axiosClient.get(apiURL.homeworkByCycle + '?cycleId=' + cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      return data;
    }
  };

  useEffect(() => {
    getCycles();
  }, []);
  const getAllHomeworks = async () => {
    if (!cycles) return;
    const homeworks = [];
    for (const cycle of cycles) {
      homeworks.push(...((await getHomeworks(cycle._id)) || []));
    }
    setHomeworks(homeworks);
  };
  useEffect(() => {
    getAllHomeworks();
  }, [cycles]);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={cycles} scroll={{x: 800, y: 300}} />
      {(user.isMaster || user.isAdmin) && <BottomMenu {...props} url={'/cycleEdit'} />}
    </div>
  );
}
