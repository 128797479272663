import React, {useContext, useRef, useState} from 'react';
import {Input, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {useForm} from 'hooks';
import {translations} from 'resources';
import {isValidPassword} from 'utils';
import {LanguageContext} from 'context';
import logo from 'resources/images/logo.png';
import styles from './password-reset-page.module.css';
export default function PasswordResetPage({history}) {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  //const {INCORRECT_EMAIL_OR_PASSWORD} = constants.errors;
  const [showPassword, setShowPassword] = useState(false);
	const newPasswordInput = useRef();
	const confirmNewPasswordInput = useRef();
  const { form: { newPassword, confirmNewPassword } } = useForm({
		newPassword: {
				value: '',
				getError: value => isValidPassword(value) ? '' : 'g106.password-reset'
		},
		confirmNewPassword: {
				value: '',
				getError: value => isValidPassword(value) ? '' : 'g106.password-reset'
		}
	})

  // const handleFormSubmission = (event) => {
  //   event.preventDefault();
  //   validate() &&
  //     signInUser({
  //       email: email.value,
  //       password: password.value,
  //     });
  // };

  // const handleKeyPress = (event) => {
  //   if (event.which === 13 || event.keyCode === 13) {
  //     event.preventDefault();
  //     passwordInput.current.focus();
  //   }
  // };

  return (
    <form className={styles.password_reset_container}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g37']}</div>
        <Input
          ref={newPasswordInput}
          value={newPassword?.value}
          onChange={(e) => newPassword?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g4']}
          //onKeyPress={handleKeyPress}
				>
					<input />
          <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
					</span>
				</Input>

        <div className={styles.error_message}>{newPassword?.error ? newPassword?.error : <span>&nbsp;</span>}</div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g38']}</div>
        <Input
          ref={confirmNewPasswordInput}
          value={confirmNewPassword?.value}
          onChange={(e) => confirmNewPassword?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g4']}
          type={showPassword ? 'text' : 'password'}
          name="password"
        >
          <input />
          <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
          </span>
        </Input>
        <div className={styles.error_message}>{confirmNewPassword?.error ? confirmNewPassword?.error : <span>&nbsp;</span>}</div>
      </div>

			<div className={styles.simple_text}>{translations[appLanguage]['g39']}</div>
			
      <div className={styles.input_container}>
        <Button inverted size="large">
          {translations[appLanguage]['g6']}
        </Button>
      </div>
    </form>
  );
}
