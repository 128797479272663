import {baseReducer} from 'context/shared';
import {SIGN_UP_USER_SUCCESS, SET_ERROR} from 'context/signUp/keys';

export const initialState = {
  isSignUpSuccess: false,
  token: '',
  signUpError: '',
  user: {},
};

export default baseReducer((state = initialState, {type, payload}) => {
  switch (type) {
    case SIGN_UP_USER_SUCCESS:
      return {...state, isLoading: false, isSignUpSuccess: true};
    case SET_ERROR:
      return {...state, isLoading: false, signUpError: payload};
    default:
      return state;
  }
});
