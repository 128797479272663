import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Table, Input, Tooltip, Modal} from 'antd';
import {Button, Checkbox} from 'semantic-ui-react';
import {FilterOutlined} from '@ant-design/icons';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import BottomMenu from 'ui/components/bottom-menu';
import './companies-page.css';

export default function UsersPage(props) {
  const [companies, setCompanies] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedStudentName, setSelectedCompanyName] = useState('');

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const columns = [
    {title: 'Şirket adı', width: 250, dataIndex: 'firstName', key: '1'},
    // {title: 'Vergi Numarası', dataIndex: 'taxNumber', key: '2'},
    user.role === 'student' ? {} : {title: 'Email', dataIndex: 'email', key: '3'},
    user.role !== 'teacher'
      ? {title: 'Onay', key: '6', render: (el) => (el.availableCommissions?.indexOf(user.commissionId) >= 0 ? 'Evet' : 'Hayır')}
      : {},

    // {title: 'Action', key: 'operation', fixed: 'right', width: 100, render: () => <Link to="/main" />},
  ];
  if (user.role !== 'student') {
    columns.push({
      title: 'Düzenle',
      key: '7',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/companyEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    });
  }

  if (user.role === 'teacher') {
    columns.push({
      title: 'Onay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <Checkbox
          disabled={loading}
          checked={el.availableCommissions?.indexOf(user.commissionId) >= 0}
          onChange={async (e, {checked}) => {
            setLoading(true);

            if (checked) el.availableCommissions = [...el.availableCommissions, user.commissionId];
            else el.availableCommissions = el.availableCommissions.filter((el) => el !== user.commissionId);

            await axiosClient.put(
              apiURL.user,
              {_id: el._id, availableCommissions: el.availableCommissions},
              {
                headers: {
                  Authorization: 'ARGA ' + localStorage.getItem('token'),
                },
              }
            );
            setLoading(false);
          }}
          toggle
        />
      ),
      // render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    });
  }

  const adminColumns = [
    {title: 'Şirket adı', width: 250, dataIndex: 'firstName', key: '1'},
    {title: 'Vergi Numarası', dataIndex: 'taxNumber', key: '2'},
    {title: 'Email', dataIndex: 'email', key: '3'},
    {
      title: 'Onay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <Checkbox
          disabled={loading}
          checked={el.approved}
          onChange={async (e, {checked}) => {
            setLoading(true);
            el.approved = checked;
            await axiosClient.put(
              apiURL.user,
              {_id: el._id, approved: el.approved},
              {
                headers: {
                  Authorization: 'ARGA ' + localStorage.getItem('token'),
                },
              }
            );
            setLoading(false);
          }}
          toggle
        />
      ),
    },

    {
      title: 'Düzenle',
      key: '7',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/companyEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
    {
      title: 'Sil',
      key: '0',
      render: (el) => (
        <div>
          <Button
            color="red"
            onClick={() => {
              setShowModal(true);
              setSelectedCompany(el._id);
              setSelectedCompanyName(el.firstName);
            }}
          >
            Sil
          </Button>
        </div>
      ),
    },
  ];

  const getCompanies = async () => {
    const {data} = await axiosClient.get(apiURL.companies, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      if (user.isMaster || user.isAdmin) setCompanies(data.filter((el) => el.role === 'company'));
      else if (user.role !== 'student') setCompanies(data.filter((el) => el.role === 'company' && el.approved));
      else setCompanies(data.filter((el) => el.availableCommissions?.indexOf(user.commissionId) >= 0));
    }
  };

  const deleteStudent = async () => {
    console.log('selectedStudent', selectedCompany);
    await axiosClient.delete(apiURL.user + '?_id=' + selectedCompany, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
  };

  useEffect(() => {
    // if (user.isMaster || user.isAdmin) {
    //   getUniversities();
    //   getDepartments();
    //   getCommissions();
    // }
    getCompanies();
  }, []);

  return (
    <div className="users_container">
      <Input
        placeholder="Filter"
        suffix={
          <Tooltip title="Click to filter">
            <FilterOutlined style={{color: 'rgba(0,0,0,.45)'}} />
          </Tooltip>
        }
      />
      <Table columns={user.isMaster || user.isAdmin ? adminColumns : columns} dataSource={companies} scroll={{x: 800, y: 300}} />
      <Modal
        title="Kurum Siliniyor"
        visible={showModal}
        onOk={() => {
          setShowModal(false);
          deleteStudent();
          setCompanies(companies.filter((el) => el._id !== selectedCompany));
        }}
        onCancel={() => setShowModal(false)}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>{selectedStudentName + ' silmek istiyor musunuz (geri çevrilemez)'}</p>
      </Modal>
    </div>
  );
}
