import React from 'react';
import ReactDOM from 'react-dom';

import styles from './picture-modal.module.scss';

export default function PictureModal({person, closeModal}) {
  const {img, projectTask, name, university, title, about} = person;

  return ReactDOM.createPortal(
    <div onClick={closeModal} className={styles.modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={styles.team_works_cards}>
        <div className={styles.team_works_card_img}>
          <img src={img} />
        </div>
        <div className={styles.team_works_card_header}>
          <h3>{projectTask}</h3>
        </div>
        <div className={styles.team_works_card_container}>
          <p>{name}</p>
        </div>
        <div className={styles.team_works_card_container}>
          <p>{university}</p>
        </div>
        <div className={styles.team_works_card_container}>
          <p>{title}</p>
        </div>
        <div className={styles.team_works_card_container_about}>
          <p>{about}</p>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
