import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, TextArea} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './index.module.css';
import {Checkbox} from 'antd';

export default function LoadHomework(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [studentNote, setStudentNote] = useState('');
  const [categories, setCategories] = useState([]);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getCategories = async () => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    setCategories(data);
  };

  const getHomework = async () => {
    const {data} = await axiosClient.get(apiURL.homeworkByCycle + '?cycleId=' + pageSettings.cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    console.log('datadata', data);
    if (data.length) {
      setStudentNote(data[0].studentNote);
      setFile(data[0].file);
      setFileName(data[0].fileName || '');
    }
  };

  const handleClick = async () => {
    const formData = new FormData();
    formData.append('studentNote', studentNote);
    if (file && typeof file !== 'string') formData.append('file', file);

    await axiosClient.post(
      apiURL.loadHomework + '?cycleId=' + pageSettings.cycleId + '&studentNote=' + studentNote + (file?.name ? '&fileName=' + file?.name : ''),
      formData,
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    props.history.goBack();
  };

  useEffect(() => {
    if (pageSettings.cycleId) {
      getCategories(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getHomework();
  }, []);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Ödev Dosyası:'}</div>
        {file &&
          (typeof file === 'string' ? (
            <a href={file} download={file}>
              {fileName}
            </a>
          ) : (
            <div>{file.name}</div>
          ))}
        {file ? (
          <label style={{cursor: 'pointer', margin: '5px', color: 'blue'}}>
            Değiştir
            <input type="file" onChange={({target: {files}}) => console.log('1fileeee', files) || setFile(files?.[0])} />
          </label>
        ) : (
          <label style={{cursor: 'pointer', margin: '5px', color: 'blue'}}>
            Dosya Yükle
            <input type="file" onChange={({target: {files}}) => setFile(files?.[0])} />
          </label>
        )}

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Ek Açıklama(isteğe bağlı) : '}</div>
          <TextArea value={studentNote} onChange={(e) => setStudentNote(e.target.value)} placeholder={'Ek açılama'} />
        </div>
        {/* <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kategori Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Açıklaması'} />
        </div> */}

        <div>
          <Button disabled={!file} onClick={() => handleClick()}>
            {_id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
