import createDataContext from 'context/createDataContext';
import reducer, {initialState} from 'context/user/reducer';
import * as actions from 'context/user/actions';
import * as sharedActions from 'context/shared/actions';

export const {Context: UserContext, Provider: UserProvider} = createDataContext(
  reducer,
  {...actions, ...sharedActions},
  initialState
);
