import React, {useEffect, useState, useContext} from 'react';
import {Table, Tabs, Tooltip} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './scoring.css';
import {Button, Input} from 'semantic-ui-react';
import {parsePageSettings} from 'helpers/page';

export default function GainsPage(props) {
  const [categories, setCategories] = useState([]);
  const [homework, setHomework] = useState();
  const [studentNote, setStudentNote] = useState('');
  const [file, setFile] = useState('');
  const [reportFiles, setReportFiles] = useState([]);
  const [fileName, setFileName] = useState('');
  const [cycle, setCycle] = useState();
  const [cycleId, setCycleId] = useState();
  const [homeworkCategories, setHomeworkCategories] = useState([]);
  const [notes, setNotes] = useState([]);

  const [level1Categories, setLevel1Categories] = useState([]);
  const [selectedLevel1Category, setSelectedLevel1Category] = useState('');

  const [level2Categories, setLevel2Categories] = useState([]);
  const [selectedLevel2Category, setSelectedLevel2Category] = useState('');
  const [level3Categories, setLevel3Categories] = useState([]);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState('');
  const [level4Categories, setLevel4Categories] = useState([]);
  const [selectedLevel4Category, setSelectedLevel4Category] = useState('');
  const [level5Categories, setLevel5Categories] = useState([]);
  const [selectedLevel5Category, setSelectedLevel5Category] = useState('');
  const [selectedNote, setSelectedNote] = useState('');

  let pageSettings = parsePageSettings(props.match.params.id);

  const level1TabItems = level1Categories.map((el) => ({label: el.name, key: el._id}));
  const level2TabItems = level2Categories.map((el) => ({label: el.name, key: el._id}));
  const level3TabItems = level3Categories.map((el) => ({label: el.name, key: el._id}));
  const level4TabItems = level4Categories.map((el) => ({
    label: <div style={{whiteSpace: 'pre-wrap', textAlign: 'initial'}}>{el.name}</div>,
    key: el._id,
  }));
  const level5TabItems = level5Categories.map((el) => ({
    label: <div style={{whiteSpace: 'pre-wrap', textAlign: 'initial'}}>{el.name}</div>,
    key: el._id,
  }));

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  useEffect(() => {
    if (categories.length < 1) return;
    const level1Categories = categories.filter((el) => !el.parentId).sort((a, b) => a.priority - b.priority);
    setLevel1Categories(level1Categories);
    // console.log('level1Categories', level1Categories, categories);
    if (level1Categories.length > 0) {
      setSelectedLevel1Category(level1Categories[0]._id);
    }
  }, [categories]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel1Category) return;
    const level2Categories = categories
      .filter((el) => el.parentId === selectedLevel1Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel2Categories(level2Categories);
    if (level2Categories.length > 0) {
      setSelectedLevel2Category(level2Categories[0]._id);
    }
  }, [categories, selectedLevel1Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel2Category) return;
    const level3Categories = categories
      .filter((el) => el.parentId === selectedLevel2Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel3Categories(level3Categories);
    if (level3Categories.length > 0) {
      setSelectedLevel3Category(level3Categories[0]._id);
    }
  }, [categories, selectedLevel2Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel3Category) return;
    const level4Categories = categories
      .filter((el) => el.parentId === selectedLevel3Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel4Categories(level4Categories);
    if (level4Categories.length > 0) {
      setSelectedLevel4Category(level4Categories[0]._id);
    }
  }, [categories, selectedLevel3Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel4Category) return;
    const level5Categories = categories
      .filter((el) => el.parentId === selectedLevel4Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel5Categories(level5Categories);
    if (level5Categories.length > 0) {
      setSelectedLevel5Category(level5Categories[0]._id);
    }
  }, [categories, selectedLevel4Category]);

  const columns = [
    {
      title: 'Değerlendirme Ölçütü',
      dataIndex: 'name',
      key: '2',
      width: 10,
    },
    {
      title: (
        <Tooltip placement="bottom" title={'İlgili Kategorinin Projedeki Kullanım Sıklığı'}>
          Kullanım Sıklığı
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '2',
      width: 20,
      render: (value, record) => {
        return (
          <Input
            style={{width: 80}}
            disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now || record.frequencyDisabled}
            className="scoreInput"
            type="number"
            value={homeworkCategories.find((el) => el.categoryId === record._id)?.frequency || 0}
            onChange={(e) => {
              const item = homeworkCategories.find((el) => el.categoryId === record._id);
              if (!item) homeworkCategories.push({categoryId: record._id, frequency: e.target.value});
              else {
                item.frequency = e.target.value;
              }
              setHomeworkCategories([...homeworkCategories]);
            }}
          />
        );
      },
    },
    {
      title: (
        <Tooltip placement="bottom" title={'İlgili Kategorinin Projedeki Konumunun Tehlike Derecesi'}>
          Konumu
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '2',
      width: 20,
      render: (value, record) => (
        <Input
          style={{width: 80}}
          disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now || record.dangerLevelDisabled}
          className="scoreInput"
          type="number"
          value={homeworkCategories.find((el) => el.categoryId === record._id)?.dangerLevel || 0}
          onChange={(e) => {
            const item = homeworkCategories.find((el) => el.categoryId === record._id);
            if (!item) homeworkCategories.push({categoryId: record._id, dangerLevel: e.target.value});
            else {
              item.dangerLevel = e.target.value;
            }
            setHomeworkCategories([...homeworkCategories]);
          }}
        />
      ),
    },
    {
      title: (
        <Tooltip placement="bottom" title={'Uzman Gurup Tarafından Belirlenen Şiddet Derecesidir'}>
          Şiddet
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '4',
      width: 20,
      render: (value, record) => {
        return (
          <Input
            style={{width: 80}}
            disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now() || record.volume}
            className="scoreInput"
            type="number"
            value={categories.find((el) => el._id === record._id)?.volume || 0}
          />
        );
      },
    },
    {
      title: 'Puan',
      dataIndex: 'name',
      key: '5',
      width: 20,
      render: (value, record) => {
        const item = homeworkCategories.find((el) => el.categoryId === record._id);
        const category = categories.find((el) => el._id === record._id);
        return Math.max(item?.frequency || 0, item?.dangerLevel || 0) * (category?.volume || 0);
      },
    },
  ];

  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };
  const setHomeworkScore = async () => {
    const {data} = await axiosClient.post(
      apiURL.homeworkScore,
      {_id: pageSettings.homeworkId, categories: homeworkCategories, notes},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };

  const getHomework = async () => {
    const {data} = await axiosClient.get(apiURL.homeworkFromId + '?_id=' + pageSettings.homeworkId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?.[0]) {
      setHomework(data[0]);
    }
  };
  const getCycle = async () => {
    const {data} = await axiosClient.get(apiURL.cycle + '?_id=' + cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data) {
      setCycle(data);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (cycleId) getCycle();
  }, [cycleId]);
  useEffect(() => {
    if (homework) {
      setHomeworkCategories(homework.categories || []);
      setNotes(homework.notes || []);

      setFile(homework.file || '');
      setReportFiles(homework.reportFiles || []);
      setCycleId(homework.cycleId || '');
      setFileName(homework.fileName || '');
      setStudentNote(homework.studentNote || '');
      console.log('homeworkhomework', homework);
    }
  }, [homework]);
  useEffect(() => {
    if (pageSettings.homeworkId) getHomework();
  }, [pageSettings.homeworkId]);

  const handleClick = () => {
    setHomeworkScore();
    props.history.goBack();
  };
  // useEffect(() => {
  //   const leafCategories = categories.filter((el) => !categories.find((el2) => el2.parentId === el._id));
  //   leafCategories.forEach((el) => {
  //     const parents = [];
  //     const getParents = (category) => {
  //       if (!category) return;
  //       parents.push(category);
  //       if (category.parentId) getParents(categories.find((el2) => el2._id === category.parentId));
  //     };
  //     if (el.parentId) getParents(categories.find((el2) => el2._id === el.parentId));
  //     // el.parents = parents;
  //     el.isLeaf = true;
  //   });

  //   console.log(leafCategories);
  //   setLeafCategories(leafCategories);
  //   // axiosClient.put(apiURL.categoryAll, leafCategories, {
  //   //   headers: {
  //   //     Authorization: 'ARGA ' + localStorage.getItem('token'),
  //   //   },
  //   // });
  // }, [categories]);

  return (
    <div className="users_container">
      <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
        <div style={{margin: '10px', width: '200px'}}>
          <div style={{fontWeight: 600}}>Ödev Dosyası</div>
          {file ? (
            <a href={file} download={file}>
              {fileName}
            </a>
          ) : (
            <div>Ödev Yüklenmemiş!!!</div>
          )}
        </div>
        <div>
          <div style={{margin: '10px'}}>
            <div style={{fontWeight: 600}}>Öğrenci Notu</div>
            <div>{studentNote || '--'}</div>
          </div>

          <div style={{margin: '10px'}}>
            <div style={{fontWeight: 800}}>Toplam Puan</div>
            <div style={{fontWeight: 800}}>
              {homeworkCategories.reduce((p, c) => {
                const category = categories.find((el) => el._id === c.categoryId);
                return p + (category?.volume || 0) * Math.max(c?.frequency || 0, c?.dangerLevel || 0);
              }, 0)}
            </div>
          </div>
        </div>
      </div>

      <div style={{margin: '5px'}}>
        <Tabs items={level1TabItems} onChange={(e) => setSelectedLevel1Category(e)} />
        <div style={{width: '100%', margin: '5px'}}>
          <Tabs items={level2TabItems} onChange={(e) => setSelectedLevel2Category(e)} />
        </div>
        <div style={{width: '100%', margin: '8px'}}>
          <Tabs items={level3TabItems} onChange={(e) => setSelectedLevel3Category(e)} />
        </div>
        <div style={{width: '100%', margin: '8px'}}>
          <Tabs items={level4TabItems} onChange={(e) => setSelectedLevel4Category(e)} />
        </div>
        <div style={{width: '700px', display: 'flex'}}>
          <Tabs
            tabBarStyle={{maxWidth: '150px'}}
            style={{maxWidth: '200px', height: '400px'}}
            tabPosition="left"
            items={level5TabItems}
            renderTabBar={(props, DefaultTabBar) => (
              <DefaultTabBar {...props} style={{whiteSpace: 'pre-wrap', maxWidth: '150px'}} />
            )}
            onChange={(e) => setSelectedLevel5Category(e)}
          />
          <div style={{marginLeft: 25}}>
            <Table
              style={{height: '400px', width: '700px', overflowY: 'auto', overflowX: 'hidden'}}
              columns={columns}
              dataSource={categories
                .filter((el) => el.isLeaf && el.parentId === selectedLevel5Category)
                .sort((a, b) => a.priority - b.priority)}
              pagination={false}
              onRow={(record) => ({
                onClick: (e) => {
                  // console.log('record', record);
                  setSelectedNote(record.note);
                },
                onMouseEnter: (e) => {
                  // console.log('record', record);
                  setSelectedNote(record.note);
                },
              })}
            />
            <div>
              {reportFiles
                .filter((el) => el.includes(selectedLevel5Category))
                .map((el) => (
                  <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <a href={el} download={el}>
                      <img style={{width: '100px'}} src={el} />
                    </a>

                    <button
                      style={{
                        width: '70px',
                        height: '30px',
                      }}
                      onClick={async () => {
                        await axiosClient.delete(
                          apiURL.loadReportFile + '?_id=' + pageSettings.homeworkId + '&url=' + el,
                          {
                            headers: {
                              Authorization: 'ARGA ' + localStorage.getItem('token'),
                            },
                          }
                        );
                        setReportFiles(reportFiles.filter((el2) => el2 !== el));
                      }}
                    >
                      Sil
                    </button>
                  </div>
                ))}
            </div>
            <label style={{cursor: 'pointer', margin: '5px', color: 'blue'}}>
              Dosya Yükle
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={async ({target: {files}}) => {
                  if (files.length < 1) return;

                  const formData = new FormData();
                  formData.append('file', files[0]);

                  await axiosClient.post(
                    apiURL.loadReportFile +
                      '?homeworkId=' +
                      pageSettings.homeworkId +
                      ('&categoryId=' + selectedLevel5Category) +
                      (files[0]?.name
                        ? '&fileName=' + files[0]?.name.substring(0, files[0]?.name.lastIndexOf('.'))
                        : ''),
                    formData,
                    {
                      headers: {
                        Authorization: 'ARGA ' + localStorage.getItem('token'),
                      },
                    }
                  );
                  getHomework();
                }}
              />
            </label>

            <div>Ek Not:</div>

            <textarea
              style={{width: '600px'}}
              value={notes.find((el) => el.categoryId === selectedLevel5Category)?.note || ''}
              onChange={(e) => {
                const item = notes.find((el) => el.categoryId === selectedLevel5Category);
                if (!item) notes.push({categoryId: selectedLevel5Category, note: e.target.value});
                else item.note = e.target.value;
                setNotes([...notes]);
              }}
            />
          </div>
          <div style={{minWidth: '230px', margin: '20px', marginTop: '80px'}}>
            <div style={{fontWeight: 600, fontSize: '20px'}}>{'Açıklama:'}</div>
            <div>{selectedNote}</div>
          </div>
        </div>
      </div>
      {console.log('cycle?.scoringDate', cycle, new Date(cycle?.scoringEndDate))}
      {((user.role === 'teacher' && new Date(cycle?.scoringEndDate) > Date.now()) || user.isAdmin) && (
        <div>
          <Button disabled={false} onClick={() => handleClick()}>
            {'Kaydet'}
          </Button>
        </div>
      )}
    </div>
  );
}
