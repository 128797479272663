import { RESET_ERROR, RESET_STATE, RESET_TOAST_STATE, SET_ERROR } from 'context/shared/keys'

export const resetError = dispatch => () => dispatch({ type: RESET_ERROR })

export const resetState = dispatch => () => dispatch({ type: RESET_STATE })

export const setError = dispatch => errorMessage => dispatch({
    type: SET_ERROR,
    payload: errorMessage
})

export const resetToastState = dispatch => () => dispatch({ type: RESET_TOAST_STATE })