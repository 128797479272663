import React, {useEffect, useState, useContext} from 'react';
import {Table, Button} from 'antd';
import {Checkbox} from 'semantic-ui-react';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './gains-page.css';

export default function GainsPage(props) {
  const [gains, setGains] = useState([]);
  const [selectedGains, setSelectedGains] = useState([]);
  const [_id, set_id] = useState('');
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commissions, setCommissions] = useState([]);
  const [minGainPoint, setMinGainPoint] = useState(0);
  const [selectedPoint, setSelectedPoint] = useState(0);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const adminColumns = [
    {title: 'Genel Beceri Türü', dataIndex: 'gainType', key: '3'},
    {title: 'Alt Beceri Türü', dataIndex: 'gainSubType', key: '4'},
    {title: 'Kodu', dataIndex: 'code', width: '70px', key: '2'},
    // {title: 'İsim', dataIndex: 'name', key: 'name', fixed: 'left'},
    {title: 'Açıklama', dataIndex: 'description', width: '500px', key: '0'},
    {title: 'Puan', dataIndex: 'point', width: '70px', key: '1'},
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const columns = [
    {title: 'Genel Beceri Türü', dataIndex: 'gainType', key: '3'},
    {title: 'Alt Beceri Türü', dataIndex: 'gainSubType', key: '4'},
    {title: 'Kodu', dataIndex: 'code', width: '70px', key: '2'},
    // {title: 'İsim', dataIndex: 'name', key: 'name', fixed: 'left'},
    {title: 'Açıklama', dataIndex: 'description', width: '500px', key: '0'},
    {title: 'Puan', dataIndex: 'point', width: '70px', key: '1'},
  ];

  const studentColumns = [
    {title: 'Türü', dataIndex: 'gainType', width: '80px', key: '3'},
    {title: 'Alt Türü', dataIndex: 'gainSubType', key: '4'},
    {title: 'Kodu', dataIndex: 'code', width: '70px', key: '2'},
    // {title: 'İsim', dataIndex: 'name', key: 'name', fixed: 'left'},
    {title: 'Açıklama', dataIndex: 'description', width: '500px', key: '0'},
    {title: 'Puan', dataIndex: 'point', width: '70px', key: '1'},
    {
      title: 'Listeme Ekle',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <Checkbox
          disabled={loading}
          checked={selectedGains.indexOf(el._id) >= 0}
          onChange={(e, {checked}) => {
            let newGains;
            if (checked) newGains = [...selectedGains, el._id];
            else newGains = selectedGains.filter((item) => item !== el._id);
            setSelectedGains(newGains);
            // setChanged(true);
            handleClick(newGains);
          }}
          toggle
        />
      ),
      // render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const handleClick = async (selectedGains) => {
    setLoading(true);
    // setChanged(false);
    try {
      await axiosClient.put(
        apiURL.user,
        {_id, selectedGains},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } catch (error) {}
    setLoading(false);
  };

  const getCommissions = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setCommissions(data);
  };

  const getGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setGains(data);
    }
  };

  const calcPoint = () => {
    return gains.filter((el) => selectedGains.indexOf(el._id) >= 0).reduce((prev, val) => prev + val.point, 0);
  };

  const renderSelectedGains = () => {
    return gains
      .filter((el) => selectedGains.indexOf(el._id) >= 0)
      .map((item) => (
        <div style={{margin: '5px', fontSize: '20px', border: 'solid 1px', borderColor: 'cadetblue', padding: '0px 3px'}}>
          <span style={{margin: '5px'}}>{item.code}</span>
          {!loading && (
            <span
              style={{fontSize: '12px', verticalAlign: 'top', cursor: 'pointer'}}
              onClick={() => {
                const newGains = selectedGains.filter((el) => el !== item._id);
                setSelectedGains(newGains);
                handleClick(newGains);
              }}
            >
              x
            </span>
          )}
        </div>
      ));
  };

  useEffect(() => {
    getGains();
    getCommissions();
  }, []);
  useEffect(() => {
    setMinGainPoint(commissions?.find?.((el) => el._id === user.commissionId)?.minGainPoint || 0);
  }, [commissions]);
  useEffect(() => {
    setSelectedPoint(calcPoint());
  }, [selectedGains, gains]);

  useEffect(() => {
    if (user && isSigned) {
      setSelectedGains(user.selectedGains || []);
      set_id(user._id);
    }
  }, [user, isSigned]);
  return (
    <div className="users_container">
      {user.role === 'student' && (
        <div style={{margin: '5px', fontWeight: '700', fontSize: 'larger'}}>
          <span style={{margin: '5px'}}>Kazanım Puanı Sınırı:</span>
          <span style={{margin: '5px'}}>{minGainPoint}</span>
        </div>
      )}
      {user.role === 'student' && (
        <div style={{margin: '5px', color: selectedPoint >= minGainPoint ? 'green' : 'red', fontWeight: '700', fontSize: 'larger'}}>
          <span style={{margin: '5px'}}>Seçilen Kazanım Puanı:</span>
          <span style={{margin: '5px'}}>{selectedPoint}</span>
        </div>
      )}
      <div style={{display: 'flex', minHeight: '50px', alignItems: 'center'}}>{renderSelectedGains()}</div>

      <Table
        columns={user.isMaster || user.isAdmin ? adminColumns : user.role === 'student' ? studentColumns : columns}
        dataSource={gains}
        scroll={{x: 800, y: 300}}
      />
      {(user.isAdmin || user.isMaster) && <BottomMenu {...props} url={'/gainEdit'} />}
      {/* {user.role === 'student' && changed && (
        <div>
          <Button onClick={() => handleClick()}>{'Kaydet'}</Button>
        </div>
      )} */}
    </div>
  );
}
