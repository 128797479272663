export default {
  signIn: '/account/auth:signin',
  signUp: '/account/auth:signup',
  profilePhoto: '/account/profile/photo',
  cycle: '/api/cycle',
  cycles: '/api/cycles',
  homeworks: '/api/homeworks',
  loadHomework: '/api/loadHomework',
  loadReportFile: '/api/loadHomeworkImage',
  user: '/user',
  gain: '/api/gain',
  getCategorizedGains: '/api/getCategorizedGains',
  university: '/api/university',
  department: '/api/department',
  commission: '/api/commission',
  category: '/api/category',
  homeworkScore: '/api/homeworkScore',
  categoryAll: '/api/categoryAll',
  homeworkFromId: '/api/homeworkFromId',
  homeworkByCycle: '/api/homeworkByCycle',
  task: '/api/task',
  taskApplication: '/api/taskApplication',
  taskApplicationFile: '/api/taskApplication/file',
  taskApplicationFinalFile: '/api/taskApplication/presentationFile',
  users: '/api/users',
  students: '/api/students',
  teachers: '/api/teachers',
  companies: '/api/companies',
  unreadMessages: '/api/message/unreadMessages',
  messageTitles: '/api/message/titles',
  chat: '/api/message/chat',
  messageSetRead: '/api/message/setRead',
  message: '/api/message',
  contacts: '/api/contacts',
};
