import React, {useContext, useRef, useState} from 'react';
import {Input, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {useForm} from 'hooks';
import {translations, constants} from 'resources';
import {isEmail, isEmpty, isValidPassword} from 'utils';
import {SignInContext, LanguageContext} from 'context';
import logo from 'resources/images/logo.png';
import styles from './signin-page.module.css';

export default function SignInPage({history}) {
  const {
    state: {signInError},
    signInUser,
  } = useContext(SignInContext);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {INCORRECT_EMAIL_OR_PASSWORD} = constants.errors;
  const [showPassword, setShowPassword] = useState(false);
  const emailInput = useRef();
  const passwordInput = useRef();
  const {
    form: {email, password, validate},
  } = useForm({
    email: {
      value: '',
      getError: (value) => (isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : ''),
    },
    password: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
  });

  const handleFormSubmission = (event) => {
    event.preventDefault();
    validate() &&
      signInUser({
        email: email.value,
        password: password.value,
      });
  };

  const handleKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      passwordInput.current.focus();
    }
  };

  return (
    <div className={styles.background_container}>
      <form onSubmit={handleFormSubmission} className={styles.signin_container}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </Link>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
          <Input
            ref={emailInput}
            value={email?.value}
            onChange={(e) => email?.setValue(e.target.value)}
            placeholder={translations[appLanguage]['g3']}
            onKeyPress={handleKeyPress}
          />

          <div className={styles.error_message}>{email?.error ? email?.error : <span>&nbsp;</span>}</div>
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g4']}</div>
          <Input
            ref={passwordInput}
            value={password?.value}
            onChange={(e) => password?.setValue(e.target.value)}
            placeholder={translations[appLanguage]['g4']}
            type={showPassword ? 'text' : 'password'}
            name="password"
          >
            <input />
            <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
            </span>
          </Input>
          <div className={styles.error_message}>{password?.error ? password?.error : <span>&nbsp;</span>}</div>
        </div>
        {signInError === INCORRECT_EMAIL_OR_PASSWORD ? (
          <div className={styles.error_message}>{translations[appLanguage]['g33']}</div>
        ) : (
          <span>&nbsp;</span>
        )}
        <Link to="/verify_email" className={styles.route_text_container}>
          {translations[appLanguage]['g30']}
        </Link>

        <div className={styles.input_container}>
          <Button onClick={handleFormSubmission} inverted size="large">
            {translations[appLanguage]['g6']}
          </Button>
        </div>

        <div className={styles.simple_text}>{translations[appLanguage]['g9']}</div>
        <Link to="/signup" className={styles.route_text_container}>
          {translations[appLanguage]['g31']}
        </Link>
      </form>
    </div>
  );
}
