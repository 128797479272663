import { RESET_ERROR, RESET_LOADING, RESET_STATE, RESET_TOAST_STATE, SET_ERROR, SET_LOADING } from 'context/shared/keys'

export default reducer => (state, { type, payload }) => {
    let calculatedResult = reducer(state, { type, payload })
    let commonResult = {}
    switch (type) {
        case RESET_ERROR:
            commonResult.error = ''
            break
        case RESET_STATE:
            state = {}
            calculatedResult = getDefaultValuesOf(calculatedResult)
            break
        case SET_ERROR:
            commonResult.error = payload
            commonResult.isLoading = false
            break
        case SET_LOADING:
            commonResult.isLoading = true
            break
        case RESET_LOADING:
            commonResult.isLoading = false
            break
        case RESET_TOAST_STATE:
            commonResult.error = ''
            commonResult.message = ''
            break
        default:
            break
    }
    return { ...state, ...calculatedResult, ...commonResult }
}

const getDefaultValuesOf = object => {
    for (const key in object) {
        if (Array.isArray(object[key])) {
            object[key] = []
            continue
        }

        switch (typeof object[key]) {
            case 'bigint':
            case 'number':
                object[key] = 0
                break
            case 'string':
                object[key] = ''
                break
            case 'object':
                object[key] = getDefaultValuesOf(object[key])
                break
            default:
                break
        }
    }
}