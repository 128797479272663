import React, {useContext, useRef, useState, useEffect} from 'react';
import {Input, Button, Icon, Select} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {useForm} from 'hooks';
import {apiURL, axiosClient} from 'service';
import {translations, constants} from 'resources';
import {isEmail, isEmpty, isValidPassword} from 'utils';
import {SignUpContext, LanguageContext, UserContext} from 'context';
import logo from 'resources/images/logo.png';
import styles from './signup-page.module.css';
let globalRole = 'student';

export default function SignUpPage({history}) {
  const {EMAIL_IN_USE} = constants.errors;
  const {getUser} = useContext(UserContext);
  const {
    state: {signUpError},
    signUpUser,
  } = useContext(SignUpContext);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const [showPassword, setShowPassword] = useState(false);
  const emailInput = useRef();
  const passwordInput = useRef();
  const firstNameInput = useRef();
  const lastNameInput = useRef();
  const idNumberInput = useRef();
  // const taxNumberInput = useRef();
  const {
    form: {email, password, confirmPassword, validate, role, university, firstName, lastName, idNumber},
  } = useForm({
    email: {
      value: '',
      getError: (value) => (isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : ''),
    },
    university: {
      value: '',
      getError: (value) => (isEmpty(value) ? 'university!!' : ''),
    },
    password: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
    confirmPassword: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
    role: {
      value: 'student',
      // getError: (value) => (isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : ''),
    },
    firstName: {value: '', getError: (value) => isEmpty(value) && translations[appLanguage]['g43']},
    lastName: {value: ''},
    idNumber: {value: '', getError: (value) => globalRole === 'student' && isEmpty(value) && translations[appLanguage]['g43']},
    // taxNumber: {value: '', getError: (value) => globalRole === 'company' && isEmpty(value) && translations[appLanguage]['g43']},
  });

  const handleFormSubmission = (event) => {
    console.log('1', password?.value, confirmPassword?.value);
    event.preventDefault();
    const signUpObj = {
      email: email.value,
      password: password.value,
      firstName: firstName.value,
      role: role.value,
    };
    if (role.value === 'student' || role.value === 'teacher') {
      signUpObj.lastName = lastName.value;
      signUpObj.university = university.value;

      if (role.value === 'student') signUpObj.idNumber = idNumber.value;
    }
    validate() && password?.value === confirmPassword?.value && signUpUser(signUpObj, getUser);
    console.log('2');
  };

  const handleKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      passwordInput.current.focus();
    }
  };

  useEffect(() => {}, []);
  useEffect(() => {
    globalRole = role.value;
  }, [role.value]);
  return (
    <div className={styles.background_container}>
      <form onSubmit={handleFormSubmission} className={styles.signup_container}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </Link>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g42']}</div>
          <Select
            ref={emailInput}
            value={role?.value}
            onChange={(e, {value}) => role?.setValue(value)}
            placeholder={translations[appLanguage]['g42']}
            options={[
              {key: 1, value: 'teacher', text: 'Akademisyen'},
              {key: 2, value: 'student', text: 'Öğrenci'},
              // {key: 3, value: 'company', text: 'Kurum'},
            ]}
          />
          {role?.error && <div className={styles.error_message}>{role?.error}</div>}
        </div>

        {role?.value === 'student' && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{translations[appLanguage]['university']}</div>
            <Select
              ref={emailInput}
              value={university?.value}
              onChange={(e, {value}) => university?.setValue(value)}
              placeholder={translations[appLanguage]['university']}
              options={[
                {key: 1, value: 'Akdeniz Üniversitesi', text: 'Akdeniz Üniversitesi'},
                {key: 2, value: 'Altınbaş Üniversitesi', text: 'Altınbaş Üniversitesi'},
                {key: 3, value: 'Mimar Sinan Güzel Sanatlar Üniversitesi', text: 'Mimar Sinan Güzel Sanatlar Üniversitesi'},
                {key: 4, value: 'Karadeniz Teknik Üniversitesi', text: 'Karadeniz Teknik Üniversitesi'},
              ]}
            />
            {university?.error && <div className={styles.error_message}>{university?.error}</div>}
          </div>
        )}

        <div className={styles.input_container}>
          {role?.value !== 'company' ? (
            <div className={styles.input_label}>{translations[appLanguage]['g14']}</div>
          ) : (
            <div className={styles.input_label}>{translations[appLanguage]['g49']}</div>
          )}
          <Input
            type="name"
            ref={firstNameInput}
            value={firstName?.value}
            onChange={(e) => firstName?.setValue(e.target.value)}
            placeholder={role?.value !== 'company' ? translations[appLanguage]['g14'] : translations[appLanguage]['g49']}
            onKeyPress={handleKeyPress}
          />

          {firstName?.error && <div className={styles.error_message}>{firstName?.error}</div>}
        </div>

        {role?.value !== 'company' && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{translations[appLanguage]['g15']}</div>
            <Input
              type="name"
              ref={lastNameInput}
              value={lastName?.value}
              onChange={(e) => lastName?.setValue(e.target.value)}
              placeholder={translations[appLanguage]['g15']}
              onKeyPress={handleKeyPress}
            />
            {lastName?.error && <div className={styles.error_message}>{lastName?.error}</div>}
          </div>
        )}

        {role?.value === 'student' && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{translations[appLanguage]['g44']}</div>
            <Input
              type="name"
              ref={idNumberInput}
              value={idNumber?.value}
              onChange={(e) => idNumber?.setValue(e.target.value)}
              placeholder={translations[appLanguage]['g44']}
              onKeyPress={handleKeyPress}
            />
            {idNumber?.error && <div className={styles.error_message}>{idNumber?.error}</div>}
          </div>
        )}

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
          <Input
            type="email"
            ref={emailInput}
            value={email?.value}
            onChange={(e) => email?.setValue(e.target.value)}
            placeholder={translations[appLanguage]['g3']}
            onKeyPress={handleKeyPress}
          />

          {email?.error && <div className={styles.error_message}>{email?.error}</div>}
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g4']}</div>
          <Input
            ref={passwordInput}
            value={password?.value}
            onChange={(e) => password?.setValue(e.target.value)}
            onBlur={(e) =>
              confirmPassword?.value && password?.value !== confirmPassword?.value && password?.setError(translations[appLanguage]['g26'])
            }
            placeholder={translations[appLanguage]['g4']}
            type={showPassword ? 'text' : 'password'}
            name="password"
          >
            <input />
            <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
            </span>
          </Input>
          {password?.error && <div className={styles.error_message}>{password?.error}</div>}
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{translations[appLanguage]['g25']}</div>
          <Input
            value={confirmPassword?.value}
            onChange={(e) => confirmPassword?.setValue(e.target.value)}
            onBlur={(e) => password?.value !== confirmPassword?.value && password?.setError(translations[appLanguage]['g26'])}
            placeholder={translations[appLanguage]['25']}
            type={showPassword ? 'text' : 'password'}
            name="passwordConfirm"
          >
            <input />
            <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
            </span>
          </Input>
          {confirmPassword?.error && <div className={styles.error_message}>{confirmPassword?.error}</div>}
        </div>
        {signUpError === EMAIL_IN_USE ? <div className={styles.error_message}>{translations[appLanguage]['g32']}</div> : <span>&nbsp;</span>}
        <div className={styles.input_container}>
          <Button onClick={handleFormSubmission} inverted size="large">
            {translations[appLanguage]['g5']}
          </Button>
        </div>
        <Link to="/signin" className={styles.route_text_container}>
          {translations[appLanguage]['g10']}
        </Link>
      </form>
    </div>
  );
}

//test@test1.com
//testStudent@test.com
//testStudent2@test.com
//testStudent3@test.com
//kayaSert@sert.com
