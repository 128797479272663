import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';
import styles from './new-chat-page.module.css';

export default function NewChatPage(props) {
  const [receiverId, setReceiverId] = useState('');
  const [receiverOptions, setReceiverOptions] = useState([]);
  const [text, setText] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [contacts, setContacts] = useState([]);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getContacts = async () => {
    const {data} = await axiosClient.get(apiURL.contacts, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
    if (Array.isArray(data)) setContacts(data);
  };

  const handleClick = async () => {
    await axiosClient.post(apiURL.message, {receiverId, text}, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
    props.history.push('/messages');
  };

  useEffect(() => {
    getContacts();
  }, []);
  useEffect(() => {
    let newContacts = contacts?.map?.((el) => ({key: el._id, value: el._id, text: el.name}));
    setReceiverOptions(newContacts);
  }, [contacts]);
  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Yeni Mesaj:'}</div>
        <div>
          <div className={styles.input_label}>
            <Dropdown
              search={true}
              // searchQuery={searchVal}
              // onSearchChange={({target}) => setSearchVal(target.value?.toUpperCase?.())}
              options={receiverOptions}
              value={receiverId}
              placeholder="Alıcı Seçiniz"
              onChange={(e, {value}) => setReceiverId(value)}
            ></Dropdown>
          </div>

          <div className={styles.input_label}>
            <textarea
              style={{width: '100%', height: '200px'}}
              value={text}
              placeholder={'Mesajınızı giriniz'}
              onChange={({target}) => setText(target.value)}
            ></textarea>
          </div>

          {
            <div style={{display: 'flex', float: 'right'}}>
              <Button disabled={!text.length || !receiverId} onClick={handleClick}>
                {'Yolla'}
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
