import React, {useContext, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {Col, Row, Modal} from 'antd';

import PictureModal from 'ui/components/picture-modal';

//persons
import elifsonmez from 'resources/images/elifsonmez.png';
import ilkayozdemir from 'resources/images/ilkayozdemir.png';
import merihkasap from 'resources/images/merihkasap.png';
import sebnemertas from 'resources/images/sebnem.png';
import sevcansonmez from 'resources/images/sevcansonmez.png';
import eda from 'resources/images/eda.png';
import ilgin from 'resources/images/ılgın.png';
import tuba from 'resources/images/tuba.png';
import seyma from 'resources/images/seyma.png';
import muco from 'resources/images/muco.png';
import irem from 'resources/images/irem.png';
import goksu from 'resources/images/goksu.png';
import umitarpaci from 'resources/images/umitarpaci.png';
import derya from 'resources/images/derya.png';
import oner from 'resources/images/oner.png';
import hatice from 'resources/images/hatice.png';
import hakan from 'resources/images/hakan.png';
import osman from 'resources/images/osman.png';
import etem from 'resources/images/ethem.png';
import kurtulus from 'resources/images/kurtulus.png';
import gulcin from 'resources/images/gulcin.png';
import zuhal from 'resources/images/zuhal.png';
import filiz from 'resources/images/filiz.png';
import tulay from 'resources/images/tülay.png';
import melis from 'resources/images/melis.jpeg';
import busra from 'resources/images/busra.png';
import busraCelik from 'resources/images/busraCelik.jpeg';
import beyza from 'resources/images/beyza.jpeg';
import hilal from 'resources/images/hilal.png';
import merve from 'resources/images/merve.jpeg';
import emine from 'resources/images/emine.jpeg';
import serenay from 'resources/images/serenay.png';

import styles from './team.module.scss';
import {LanguageContext} from 'context';
import {translations} from 'resources';

const Team = () => {
  const [showPictureModal, setShowPictureModal] = useState(false);
  const [person, setPerson] = useState({});

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);

  const persons = [
    {
      img: sebnemertas,
      projectTask: translations[appLanguage]['projectManager'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Şebnem ERTAŞ BEŞİR ',
      university: 'Akdeniz Üniversitesi',
      title: 'Mimar',
      about:
        "Karadeniz Teknik Üniversitesi Mimarlık Bölümü’nde yüksek öğrenimini 2003 yılında tamamladı. Aynı yıl Karadeniz Teknik Üniversitesi Fen Bilimleri Enstitüsü kadrosunda İç Mimarlık bölümünde araştırma görevlisi olarak çalıştı. 2008-2011 yılları arasında Ankara Bayındırlık ve İskan İl Müdürlüğünde mimar olarak görev aldı. 2011 yılında Karadeniz Teknik Üniversitesi İç Mimarlık Bölümünde Öğretim Görevlisi olarak atandı. 2015-2018 yılları arasında Dr. Öğr. Üyesi; 2018 yılından itibaren de Doç. Dr. öğretim üyesi olarak görevine devam etmiştir.  2020 yılında Akdeniz Üniversitesi'nde Öğretim Üyesi kadrosuna geçti ve akademik çalışmalarını sürdürmektedir. Bilimsel dergi, ulusal/uluslararası konferans ve bilimsel toplantılarda sunulan çeşitli yayınları bulunmaktadır. Ulusal mobilya tasarım yarışmalarında dereceleri bulunmaktadır. 2019-2020 yılları arasında Karadeniz Teknik Üniversitesi Kariyer Merkezi Koordinatörlüğü yapmıştır. Çeşitli TÜBİTAK, BAP vb. projelerde de yürütücü veya araştırmacı olarak yer almaktadır.",
    },
    {
      img: oner,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Prof. Dr.'] + ' Öner DEMİREL',
      university: 'Kırıkkale Üniversitesi',
      title: 'Mimar',
      about: 'Güzel Sanatlar Fakültesi Peyzaj Mimarlığı Bölümü',
    },
    {
      img: umitarpaci,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Ümit T. ARPACIOĞLU',
      university: 'Mimar Sinan Güzel Sanatlar Üniversitesi',
      title: 'Mimar',
      about:
        'Lisans öğrenimini Mimar Sinan Üniversitesi Mimarlık Fakültesi, Mimarlık Bölümü’nde yaparak 2001 yılında mimar unvanı ile mezun oldu. 2004 yılında Mimar Sinan Üniversitesi Fen Bilimleri Enstitüsü Mimarlık Ana Bilim Dalı, Yapı Fiziği ve Malzemesi Programı’nda Yüksek Lisans eğitimini tamamladı. 2005 yılında Mimar Sinan Güzel Sanatlar Üniversitesi Yapı Fiziği ve Malzemesi Programı’nda başladığı doktora çalışmasını 2010 yılında tamamladı. 2001 yılında Araştırma Görevlisi olarak çalışmaya başladığı Mimar Sinan Üniversitesi Mimarlık Fakültesi, Mimarlık Bölümü Yapı Fiziği ve Malzemesi Bilim Dalı’ndaki görevine Doç. Dr. öğretim üyesi olarak devam etmektedir. Bilimsel dergi, ulusal/uluslararası konferans ve bilimsel toplantılarda sunulan çeşitli yayınları bulunmaktadır. Çeşitli TÜBİTAK, BAP vb. projelerde de araştırmacı veya yürütücü olarak yer almaktadır.',
    },
    {
      img: ilkayozdemir,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Prof. Dr.'] + ' İlkay ÖZDEMİR',
      university: 'Karadeniz Teknik Üniversitesi',
      title: 'Mimar',
      about:
        'Karadeniz Teknik Üniversitesi (KTÜ) Mimarlık Bölümü’nden 1985 yılında “mimar” ünvanı ile mezun oldu. Aynı üniversitenin mimarlık bölümünde 1988 yılında yük. lisans eğitimini, 1994 yılında doktora eğitimini tamamladı. 1986 yılında Arş. Gör. olarak başladığı KTÜ Mimarlık Bölümü Bina Anabilim Dalında, 1996-2008 yıllarında Yrd. Doç., 2008-2014 yıllarında  Doç.Dr. ünvanı ile görev yaptı. 2014 yılından itibaren Prof. Dr.  olarak aynı bölümde akademik çalışmalarına devam etmektedir. 2009-2012 ve 2013-2014 yılları arasında KTÜ Mimarlık Fakültesi Dekan Yardımcılığı, 2014-2018 yıllarında KTÜ Mimarlık Bölüm Başkanlığı yapmıştır ve 2017 yılından itibaren KTÜ Mimarlık Fakültesi’nde dekan olarak görevini sürdürmektedir. Bilimsel dergi, ulusal/uluslararası konferans ve bilimsel toplantılarda sunulan çeşitli yayınları bulunmaktadır. Çeşitli TÜBİTAK, BAP vb. projelerde de araştırmacı, yürütücü ve danışman olarak yer almaktadır.',
    },
    {
      img: hatice,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Prof. Dr.'] + ' Hatice ODACI',
      university: 'Karadeniz Teknik Üniversitesi',
      title: 'Akademisyen',
      about: 'Edebiyat Fakültesi Psikoloji Bölümü',
    },
    {
      img: osman,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Prof. Dr.'] + ' Osman ARAYICI',
      university: 'Mimar Sinan Güzel Sanatlar Üniversit',
      title: '',
      about: 'Mimarlık Fakültesi Mimarlık Bölümü',
    },
    {
      img: zuhal,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Prof. Dr.'] + ' Zuhal KAYNAKÇI ELİNÇ',
      university: 'Akdeniz Üniversitesi',
      title: 'İç Mimar',
      about: 'İç Mimarlık Bölümü',
    },
    {
      img: filiz,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Filiz TAVŞAN',
      university: 'Karadeniz Teknik Üniversitesi',
      title: 'İç Mimar',
      about: 'İç Mimarlık Bölümü',
    },
    {
      img: elifsonmez,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Elif SÖNMEZ',
      university: 'Altınbaş Üniversitesi',
      title: 'İç Mimar',
      about:
        'Karadeniz Teknik Üniversitesi İç Mimarlık Bölümü’nden 2006 yılında “iç mimar” olarak mezun oldu. Aynı yıl İç Mimarlık Anabilim dalında başladığı yüksek lisans eğitimini 2010 yılında tamamlayarak “yüksek iç mimar” unvanını aldı. KTÜ Mimarlık Anabilim dalında başladığı doktora eğitimini 2016 yılında tamamladı. 2009 yılında atandığı KTÜ İç Mimarlık Bölümü’nde araştırma görevlisi olarak akademik çalışmalarına başlamış olup farklı kurumlarda görev aldı. 2017 yılında Altınbaş Üniversitesi İç Mimarlık ve Çevre Tasarımı Bölümü’nde Bölüm Başkanı olarak başladığı görevine devam etmektedir. 2020 yılında Doçent unvanını almıştır. Bilimsel dergi, ulusal/uluslararası konferans ve bilimsel toplantılarda sunulan çeşitli yayınları bulunmaktadır. Çeşitli TÜBİTAK, BAP vb. projelerde de araştırmacı veya yürütücü olarak yer almaktadır.',
    },
    {
      img: merihkasap,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Dr. Öğr.'] + ' Üyesi Merih KASAP',
      university: 'Altınbaş Üniversitesi',
      title: 'İç Mimar',
      about:
        "Lisans öğrenimini Karadeniz Teknik Üniversitesi Mimarlık Fakültesi, İç Mimarlık Bölümü’nde yaparak 2008 yılında ‘’iç mimar’’ unvanı ile mezun oldu. 2012 yılında Mimar Sinan Üniversitesi Fen Bilimleri Enstitüsü İç Mimarlık Ana Bilim Dalı, İç Mimarlık Programı’nda Yüksek Lisans eğitimini tamamladı. 2012 yılında Mimar Sinan Güzel Sanatlar Üniversitesi İç Mimarlık Programı’nda başladığı doktora çalışmasını 2016 yılında tamamladı. Lisans mezuniyetiyle çeşitli kurumlarda iç mimar ve akademik olarak Öğr. Görevlisi olarak çalıştı. 2016 yılında öğretim görevlisi olarak çalışmalarına devam ettiği Altınbaş Üniversitesi'nde 2017'de yardımcı doçentlik ünvanını aldı. Halen Dr. Öğr. üyesi olarak Altınbaş Üniversitesi'nde çalışmalarını sürdürmektedir. Bilimsel dergi, ulusal/uluslararası konferans ve bilimsel toplantılarda sunulan çeşitli yayınları bulunmaktadır. Çeşitli TÜBİTAK, BAP vb. projelerde de araştırmacı olarak yer almaktadır.",
    },
    // {
    //   img: sevcansonmez,
    //   projectTask: 'Bursiyer',
    //   name: 'Sevcan SÖNMEZ',
    //   university: '',
    //   title: 'Şehir Plancısı',
    //   about:
    //     '2017 yılında İstanbul Teknik Üniversitesi Şehir ve Bölge Planlama bölümünden mezun oldu. Aynı yıl İTÜ’de Gayrimenkul Geliştirme yüksek lisans programına başladı.  Tez çalışmasını bir yıl süreyle Bauhaus Üniversitesi-Weimar’da ve İTÜ’de tamamlayarak 2020 yılında Gayrimenkul Geliştirme programından mezun oldu. Yüksek lisans döneminden itibaren üniversite proje ve çalışmalarında yer almaktadır.',
    // },

    {
      img: hakan,
      projectTask: translations[appLanguage]['researcher'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Hakan KOĞAR',
      university: 'Akdeniz Üniversitesi',
      title: 'Şehir Plancısı',
      about: 'Eğitim Fakültesi Eğitim Bilimleri Bölümü',
    },
    {
      img: etem,
      projectTask: translations[appLanguage]['consultant'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Etem YEŞİLYURT',
      university: 'Akdeniz Üniversitesi',
      title: '',
      about: 'Eğitim Fakültesi Eğitim Bilimleri Bölümü',
    },
    {
      img: tulay,
      projectTask: translations[appLanguage]['consultant'],
      name: translations[appLanguage]['Doç. Dr.'] + ' Tülay CANBOLAT',
      university: 'Çukurova Üniversitesi',
      title: '',
      about: 'İç Mimarlık Bölümü',
    },

    {
      img: derya,
      projectTask: translations[appLanguage]['consultant'],
      name: translations[appLanguage]['Dr. Öğr.'] + ' Üyesi Derya DENİZ',
      university: 'Özyeğin Üniversitesi',
      title: 'İnşaat Mühendisi',
      about: 'Mühendislik Fakültesi İnşaat Mühendisliği Bölümü',
    },
    {
      img: kurtulus,
      projectTask: translations[appLanguage]['consultant'],
      name: translations[appLanguage]['Arş. Gör.'] + ' Kurtuluş ATASEVER',
      university: 'Mimar Sinan Güzel Sanatlar Üniversitesi',
      title: 'İnşaat Mühendisi',
      about: 'Mimarlık Fakültesi Mimarlık Bölümü',
    },
    {
      img: gulcin,
      projectTask: translations[appLanguage]['consultant'],
      name: translations[appLanguage]['Dr. Öğr.'] + ' Üyesi Gülçin GÜREŞÇİ',
      university: 'Dokuz Eylül Üniversitesi',
      title: 'İnşaat Mühendisi',
      about: 'İktisadi ve İdari Bilimler Fakültesi AKUT Arama Kurtarma Derneği Üyesi',
    },
    {
      img: irem,
      projectTask: '',
      name: 'İrem ÖZPINAR',
      university: '',
      title: '',
      about: '',
    },
    {
      img: muco,
      projectTask: '',
      name: 'Mücahit GÜL',
      university: 'Akdeniz Üniversitesi',
      title: 'İç Mimar',
      about:
        "Karadeniz Teknik Üniversitesi İç Mimarlık Bölümü'nden 2020 yılında iç mimar olarak mezun oldu. 2021 yılında Akdeniz Üniversitesi İç Mimarlık Anabilim dalında başladığı yüksek lisans eğitimine halen devam etmektedir. Lisans mezuniyetinden sonra çeşitli kurumlarda iç mimar olarak görev yaptı. Erasmus+ Programı ile Avusturya’da stajyerlik yaptı. Lisans dönemi boyunca çeşitli workshop ve yarışmalara katılıp ödüller kazanmıştır. Yüksek lisans döneminde çeşitli dergi ve konferansta yayınları bulunmaktadır. TUBİTAK projesinde bursiyer olarak yer almaktadır. Ayrıca gönüllü olarak arama kurtarma (kentsel, doğa ve hayvan) faaliyetlerinde görev almaktadır.",
    },
    {
      img: sevcansonmez,
      projectTask: '',
      name: 'Sevcan SÖNMEZ',
      university: '',
      title: '',
      about: '',
    },
    {
      img: goksu,
      projectTask: '',
      name: 'Goksu KARADENİZ',
      university: '',
      title: '',
      about: '',
    },
    {
      img: seyma,
      projectTask: '',
      name: 'Şeyma DERECİ',
      university: '',
      title: '',
      about: '',
    },

    {
      img: tuba,
      projectTask: '',
      name: 'Tuba ÇOLAKOĞLU',
      university: '',
      title: '',
      about: '',
    },
    {
      img: ilgin,
      projectTask: '',
      name: 'Ayşan Ilgın POLAT',
      university: '',
      title: '',
      about: '',
    },
    {
      img: eda,
      projectTask: '',
      name: 'Eda GÜL',
      university: '',
      title: '',
      about: '',
    },
    {
      img: melis,
      projectTask: '',
      name: 'Melis SEYFETTİNOĞLU',
      university: '',
      title: '',
      about: '',
    },
    {
      img: busra,
      projectTask: '',
      name: 'Büşra GÖKÜZ',
      university: '',
      title: '',
      about: '',
    },
    {
      img: busraCelik,
      projectTask: '',
      name: 'Büşra ÇELİKTÜRK',
      university: '',
      title: '',
      about: '',
    },
    {
      img: serenay,
      projectTask: '',
      name: 'Serenay ULUSOY',
      university: '',
      title: '',
      about: '',
    },
    {
      img: emine,
      projectTask: '',
      name: 'Emine KARSU',
      university: '',
      title: '',
      about: '',
    },
    {
      img: hilal,
      projectTask: '',
      name: 'Hilal ÖZTÜRK',
      university: '',
      title: '',
      about: '',
    },
    {
      img: merve,
      projectTask: '',
      name: 'Merve TÜRKKÖYLÜ',
      university: '',
      title: '',
      about: '',
    },
    {
      img: beyza,
      projectTask: '',
      name: 'Beyza TEMUR',
      university: '',
      title: '',
      about: '',
    },
  ];

  const showModal = (personi) => {
    setShowPictureModal(true);
    setPerson(personi);
  };

  const renderPictureModal = () => {
    if (showPictureModal) {
      return <PictureModal person={person} closeModal={() => setShowPictureModal(false)} />;
    }
  };

  return (
    <>
      {renderPictureModal()}
      <section id="section_team" className={styles.section_team_container}>
        <Fade bottom>
          <article className={styles.section_team_details}>
            <h2 className={styles.section_team_title}>{translations[appLanguage]['team']}</h2>
          </article>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[0])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[0].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[0].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[0].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[1])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_3}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[1].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[1].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[1].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[2])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_3}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[2].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[2].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[2].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[3])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_3}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[3].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[3].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[3].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[4])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_3}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[4].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[4].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[4].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[5])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[5].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[5].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[5].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[6])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[6].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[6].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[6].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[7])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[7].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[7].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[7].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[8])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[8].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[8].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[8].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[9])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[9].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[9].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[9].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[10])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[10].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[10].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[10].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[11])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[11].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[11].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[11].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[12])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[12].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[12].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[12].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[13])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[13].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[13].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[13].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[14])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[14].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[14].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[14].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[15])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[15].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[15].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[15].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[16])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[16].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[16].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[16].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[17])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[17].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[17].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[17].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[18])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[18].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[18].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[18].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[19])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[19].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[19].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[19].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[20])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[20].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[20].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[20].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[21])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[21].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[21].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[21].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[22])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[22].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[22].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[22].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[23])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[23].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[23].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[23].name}</p>
                </div>
              </div>
            </Col>
            {/* <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container} style={{backgroundColor: '#f4f6fd'}}>
              <div
                onClick={() => showModal(persons[20])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
                style={{backgroundColor: '#f4f6fd'}}
              ></div>
            </Col> */}
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[24])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[24].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[24].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[24].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[25])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[25].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[25].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[25].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[26])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[26].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[26].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[26].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[27])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[27].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[27].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[27].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[28])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[28].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[28].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[28].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[29])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[29].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[29].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[29].name}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[30])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_1}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[30].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[30].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[30].name}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
              <div
                onClick={() => showModal(persons[31])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
              >
                <div className={styles.team_works_card_img}>
                  <img src={persons[31].img} alt="" />
                </div>
                <div className={styles.team_works_card_header}>
                  <h3>{persons[31].projectTask}</h3>
                </div>
                <div className={styles.team_works_card_container}>
                  <p>{persons[31].name}</p>
                </div>
              </div>
            </Col>

            <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container} style={{backgroundColor: '#f4f6fd'}}>
              <div
                onClick={() => showModal(persons[20])}
                className={`${styles.team_works_cards} ${styles.team_works_cards_2}`}
                style={{backgroundColor: '#f4f6fd'}}
              ></div>
            </Col>
          </Row>
        </Fade>
      </section>
    </>
  );
};

export default Team;
