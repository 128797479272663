import React, {useContext, useEffect, useState} from 'react';
import {Menu, Layout, Button, Input, Tooltip} from 'antd';

import {MenuUnfoldOutlined, MenuFoldOutlined, SendOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {Table} from 'antd';
import ScrollToBottom from 'react-scroll-to-bottom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';
import styles from './chat-page.module.css';

let chatIntervalID;

export default function ChatPage(props) {
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [text, setText] = useState('');

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);
  const otherUserId = pageSettings?.otherUserId;

  // const handleClick = async () => {
  //   await axiosClient.post(apiURL.message, {receiverId, text}, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
  //   props.history.push('/messages');
  // };
  const getContacts = async () => {
    const {data} = await axiosClient.get(apiURL.contacts, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
    if (Array.isArray(data)) {
      console.log('a', data);
      setContacts(data);
    }
  };

  const getMessages = async () => {
    const {data} = await axiosClient.get(apiURL.chat + '?otherUserId=' + otherUserId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setMessages(data);
    }
  };

  const messageSetRead = async () => {
    await axiosClient.post(
      apiURL.messageSetRead,
      {otherUserId},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
  };

  const sendMessage = async () => {
    setMessages([...messages, {receiverId: otherUserId, senderId: user._id, createdOn: Date.now(), text}]);
    await axiosClient.post(apiURL.message, {receiverId: otherUserId, text}, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
  };

  useEffect(() => {
    getMessages();
    getContacts();
    messageSetRead();
    chatIntervalID = setInterval(() => {
      getMessages();
    }, 2000);
    return () => clearInterval(chatIntervalID);
  }, []);

  const renderMessages = () => {
    const returnThis = messages.map((item, i) => {
      if (item.senderId === user._id)
        return (
          <div key={i} className={styles.messageContainerRight}>
            <div className={styles.messageRight}>
              <div>{item.text}</div>
              <div className={styles.messageDate}>{new Date(item.createdOn).toLocaleTimeString()}</div>
            </div>
          </div>
        );
      else
        return (
          <div key={i} className={styles.messageContainerLeft}>
            <div className={styles.messageLeft}>
              <div>{item.text}</div>
              <div className={styles.messageDate}>{new Date(item.createdOn).toLocaleTimeString()}</div>
            </div>
          </div>
        );
    });
    // returnThis.push(<div style={{float: 'left', clear: 'both'}} ref={(el) => setScrollElement(el)}></div>);
    return returnThis;
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Mesajlar: ' + (contacts?.find?.((el) => el._id === otherUserId)?.name || '')}</div>
        <ScrollToBottom className={styles.messagesContainer}>{renderMessages()}</ScrollToBottom>
        <div style={{border: 'solid 1px blue', display: 'flex', marginTop: '10px'}}>
          <Input
            value={text}
            onKeyPress={(e) => {
              if ((e.charCode === 13 || e.keyCode === 13) && text?.length) {
                sendMessage();
                setText('');
              }
            }}
            onChange={(e) => {
              setText((e.target.value || '').substring(0, 250));
            }}
          />

          {/* <Tooltip title="Send"> */}
          <Button
            disabled={!text?.length}
            onClick={() => {
              sendMessage();
              setText('');
            }}
            shape="circle"
            icon={<SendOutlined />}
          />
          {/* </Tooltip> */}
        </div>
      </div>
    </div>
  );
}
