import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';

import {UserContext} from 'context';
import SidebarModal from 'ui/components/sidebar-modal';
import ProfileModal from 'ui/components/profile-modal';
import logo from 'resources/images/logo.png';
import styles from './topbar.module.css';

export default function Topbar() {
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const {
    topbar_container,
    logo_container,
    icons_container,
    hamburger_menu,
    hamburger_menu_icon,
    app_logo,
    icons,
    icon_container,
    profile_photo,
  } = styles;

  const {
    state: {user},
  } = useContext(UserContext);

  const renderSidebarModal = () => {
    if (!showModal) return;

    return <SidebarModal closeModal={() => setShowModal(false)} />;
  };

  const renderProfileModal = () => {
    if (!showProfileModal) return;

    return <ProfileModal closeModal={() => setShowProfileModal(false)} />;
  };

  return (
    <>
      {renderSidebarModal()}
      {renderProfileModal()}
      <div className={topbar_container}>
        <div className={logo_container}>
          <Link to="/main">
            <img src={logo} className={app_logo} alt="logo" />
          </Link>
          <div onClick={() => setShowModal(!showModal)} className={hamburger_menu}>
            <Icon className={hamburger_menu_icon} name="bars" size="large" />
          </div>
        </div>

        <div className={icons_container}>
          <div onClick={() => setShowProfileModal(!showProfileModal)} className={icon_container}>
            {user.photoUrl ? (
              <img className={profile_photo} alt="Profile" src={process.env.PUBLIC_URL + user.photoUrl} />
            ) : (
              <Icon src={logo} className={icons} name="user" size="large" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
