import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './task-apply-page.module.css';

export default function GainEditPage(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [steps, setSteps] = useState(['']);
  const [description, setDescription] = useState(['']);
  const [taskTimeInDays, setTaskTimeInDays] = useState(0);
  const [taskCategoryId, setTaskCategoryId] = useState('');
  const [options, setOptions] = useState([]);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getTask = async (_id) => {
    const {data} = await axiosClient.get(apiURL.task + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setOwnerId(data.ownerId);
      setSteps(data.steps?.length > 0 ? data.steps : ['']);
      setDescription(data.description);
      set_id(data._id);
      setTaskCategoryId(data.taskCategoryId);
      setTaskTimeInDays(data.taskTimeInDays || 1);
    }
  };

  const getTaskCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setOptions(data.map((el) => ({text: el.name, value: el._id, key: el._id})));
    }
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.post(
        apiURL.taskApplication,
        {taskId: _id, companyId: ownerId, taskCategoryId},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/tasks');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getTask(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getTaskCategories();
  }, []);

  const renderSteps = () => {
    return steps
      .filter((el) => el)
      .map((el, i) => {
        return (
          <div className={styles.input_container}>
            <div className={styles.step_label}>{'Adım ' + (i + 1)}</div>
            <Input value={steps[i]} placeholder={'Görev Adımı'} />
          </div>
        );
      });
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Görev:'}</div>

        {/* <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Adı'} />
        </div> */}
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} placeholder={'Görev Adı'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={options.find((el) => el.value === taskCategoryId)?.text} placeholder={'Görev Adı'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Süresi (gün) : '}</div>
          <Input value={taskTimeInDays} placeholder={'Süresi (gün)'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Açıklaması : ' + (user.role !== 'company' ? description : '')}</div>
          {user.role === 'company' && (
            <form className={'ui form ' + styles.connection_input}>
              <textarea value={description} placeholder={'Görev Açıklması'} />
            </form>
          )}
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adımlar : '}</div>
          {renderSteps()}
        </div>
        <div>
          <Button color="green" onClick={() => handleClick()}>
            {'Başvur'}
          </Button>
          <Button color="red" onClick={() => props.history.push('/tasks')}>
            {'Geri'}
          </Button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
