import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Table, Input, Tooltip} from 'antd';
import {FilterOutlined} from '@ant-design/icons';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import BottomMenu from 'ui/components/bottom-menu';
import './teachers-page.css';

export default function UsersPage(props) {
  const [teachers, setTeachers] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [commissions, setCommissions] = useState([]);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const columns = [
    {title: 'Adı', width: 100, dataIndex: 'firstName', key: '1'},
    {title: 'Soyadı', width: 100, dataIndex: 'lastName', key: '2'},
    {title: 'Email', dataIndex: 'email', key: '5'},
    // {
    //   title: 'Düzenle',
    //   key: '7',
    //   fixed: 'right',
    //   width: 90,
    //   // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
    //   render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/teacherEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    // },
    // {title: 'Action', key: 'operation', fixed: 'right', width: 100, render: () => <Link to="/main" />},
  ];

  const adminColumns = [
    {title: 'Adı', width: 100, dataIndex: 'firstName', key: '1'},
    {title: 'Soyadı', width: 100, dataIndex: 'lastName', key: '2'},
    {title: 'Email', dataIndex: 'email', key: '5'},
    {title: 'Onay', key: '6', render: (el) => (el.approved ? 'Evet' : 'Hayır')},
    {title: 'Universite', key: '7', render: (el) => universities?.find?.((i) => i._id === el.universityId)?.name},
    {title: 'Fakülte', key: '8', render: (el) => departments?.find?.((i) => i._id === el.departmentId)?.name},
    {title: 'Bölüm', key: '9', render: (el) => commissions?.find?.((i) => i._id === el.commissionId)?.name},
    {
      title: 'Düzenle',
      key: '10',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/teacherEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
    // {title: 'Action', key: 'operation', fixed: 'right', width: 100, render: () => <Link to="/main" />},
  ];

  const getTeachers = async () => {
    const {data} = await axiosClient.get(apiURL.teachers, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTeachers(data.filter((el) => el.role === 'teacher'));
    }
  };

  const getUniversities = async () => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setUniversities(data);
  };
  const getCommissions = async () => {
    const {data} = await axiosClient.get(apiURL.commission, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setCommissions(data);
  };
  const getDepartments = async () => {
    const {data} = await axiosClient.get(apiURL.department, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setDepartments(data);
  };

  useEffect(() => {
    if (user.isMaster || user.isAdmin) {
      getUniversities();
      getDepartments();
      getCommissions();
    }
    getTeachers();
  }, []);

  return (
    <div className="users_container">
      <Input
        placeholder="Filter"
        suffix={
          <Tooltip title="Click to filter">
            <FilterOutlined style={{color: 'rgba(0,0,0,.45)'}} />
          </Tooltip>
        }
      />
      <Table columns={user.isMaster || user.isAdmin ? adminColumns : columns} dataSource={teachers} scroll={{x: 800, y: 300}} />
      <BottomMenu />
    </div>
  );
}
