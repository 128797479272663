import React, {useEffect, useState, useContext} from 'react';
import {Table} from 'antd';
import {Checkbox} from 'semantic-ui-react';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './tasks-page.css';

export default function GainsPage(props) {
  const [tasks, setTasks] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [taskCategories, setTaskCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [gains, setGains] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskApplications, setTaskApplications] = useState([]);

  const pushTask = async (task) => {
    setLoading(true);
    try {
      const {data} = await axiosClient.put(apiURL.task, task, {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      });

      if (Array.isArray(data)) setTasks(data);
    } catch (error) {}

    setLoading(false);
  };

  const generateColumns = (isOwner) => {
    const returnThis = [
      {
        title: 'Adı',
        key: '0',
        dataIndex: 'name',
      },
      {
        title: 'Kategori',
        key: '1',
        render: (el) => {
          const taskCategory = taskCategories?.find((item) => item._id === el.taskCategoryId);
          // let gainCodes = taskCategory?.gains?.map((t) => gains[gains.findIndex((g) => t === g._id)]?.code);
          // return gainCodes?.reduce((p, item) => p + ', ' + item, '')?.substring?.(2);
          return taskCategory?.name;
        },
      },
      {
        title: 'Puanı',
        key: '1',
        render: (el) => {
          const taskCategory = taskCategories?.find((item) => item._id === el.taskCategoryId);
          let gainPoints = taskCategory?.gains?.map((t) => gains[gains.findIndex((g) => t === g._id)]?.point);
          return gainPoints?.reduce((p, item) => p + item, 0);
          // return taskCategory?.name;
        },
      },
      {
        title: 'Açıklaması',
        key: '2',
        // render: (el) => el?.steps?.reduce((p, item) => p + (p ? ' , ' : '') + item, ''),
        dataIndex: 'description',
      },
    ];
    if (user.role === 'teacher')
      returnThis.push({
        title: 'Onay',
        key: '8',
        width: 90,
        render: (el) => (
          <Checkbox
            disabled={loading}
            checked={el.commissionSettings?.[user.commissionId]?.selected}
            onChange={(e, {checked}) => {
              if (!el.commissionSettings) el.commissionSettings = {};
              if (!el.commissionSettings?.[user.commissionId]) el.commissionSettings[user.commissionId] = {};
              el.commissionSettings[user.commissionId].selected = checked;
              pushTask(el);
            }}
            toggle
          />
        ),
      });
    if (isOwner)
      returnThis.push({
        title: 'Detay',
        key: '3',
        fixed: 'right',
        width: 90,
        render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/taskEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
      });
    else {
      returnThis.push({title: 'Şirket', key: '4', render: (el) => companies?.find?.((i) => i._id === el.ownerId)?.firstName});
      returnThis.push({
        title: 'Başvur',
        key: '7',
        fixed: 'right',
        width: 90,
        render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/applyTask${JSON.stringify({_id: el._id})}`)}>Başvur</a>,
      });
    }
    return returnThis;
  };

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const getGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setGains(data);
    }
  };

  const getTaskApplications = async () => {
    const {data} = await axiosClient.get(apiURL.taskApplication, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskApplications(data);
    }
  };

  const getTasks = async (_id) => {
    const {data} = await axiosClient.get(apiURL.task, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTasks(data);
    }
  };

  const getTaskCategories = async () => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskCategories(data);
    }
  };

  const getCompanies = async () => {
    const {data} = await axiosClient.get(apiURL.companies, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCompanies(data);
    }
  };

  useEffect(() => {
    getGains();
    getTasks();
    getCompanies();
    getTaskCategories();
    getTaskApplications();
  }, []);

  useEffect(() => {
    if (user.role === 'company') setMyTasks(tasks.filter((el) => el.ownerId === user._id));
    else if (user.isMaster || user.isAdmin) setMyTasks(tasks);
    else
      setMyTasks(
        tasks.filter((el) => {
          console.log(
            'el._id',
            el._id,
            taskApplications?.find?.((ta) => ta.taskId === el._id)
          );
          if (user.role === 'student')
            return el.commissionSettings?.[user.commissionId]?.selected && !taskApplications?.find?.((ta) => ta.taskId === el._id);

          const owner = companies.find((item) => item._id === el.ownerId);
          if (owner) return owner.availableCommissions.indexOf(user.commissionId) >= 0;
          return false;
        })
      );
  }, [tasks, companies, taskApplications]);
  useEffect(() => {
    setColumns(generateColumns(user.role !== 'student'));
  }, [companies, myTasks, taskCategories, gains]);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={myTasks} scroll={{x: 800, y: 300}} />
      {user.role === 'company' && <BottomMenu {...props} url={'/taskEdit'} />}
    </div>
  );
}
