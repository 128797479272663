import React, {useContext} from 'react';
import {EnvironmentOutlined, PhoneOutlined, MailOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import Fade from 'react-reveal/Fade';

import styles from './mentors.module.scss';
import {LanguageContext} from 'context';
import {translations} from 'resources';

export default function Mentors() {
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  return (
    <section id="section_mentors" className={styles.section_mentors_container}>
      <Fade>
        <h2 className={styles.section_mentors_title}>{translations[appLanguage]['expertGroup']}</h2>

        <div className={styles.section_mentors_content}>
          <div className={styles.section_mentors_part}>
            <h3 className={styles.mentors_part_title}>{translations[appLanguage]['academicExpertGroup']}</h3>

            <p className={styles.mentors_part_text}>
              {translations[appLanguage]['PROF. DR.'] + ' SEDEN ACUN ÖZGÜNLER'}
            </p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['PROF. DR.'] + ' MUSTAFA ÖZGÜNLER  '}</p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DOÇ. DR.'] + ' RAMAZAN ÖZÇELİK    '}</p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DOÇ. DR.'] + ' SEPANTA NAİMİ      '}</p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DOÇ. DR.'] + ' ALPASLAN HAMDİ KUZUCUOĞLU'}</p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DR. ÖĞR.'] + ' ÜYESİ FERHAT PAKDAMAR    '}</p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DR. ÖĞR.'] + ' ÜYESİ GÖKSEL ULAY        '} </p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['DR. ÖĞR.'] + ' ÜYESİ HÜSEYİN BAYRAKTAR  '} </p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['ÖĞR. GÖR.'] + ' TANSU DİNÇER            '} </p>
            <p className={styles.mentors_part_text}>{translations[appLanguage]['ARŞ. GÖR.'] + ' FARUK BÜYÜKYORAN        '} </p>
          </div>
          <div className={styles.section_mentors_part}>
            <h3 className={styles.mentors_part_title}>{translations[appLanguage]['akutExpertGroup']}</h3>
            <p className={styles.mentors_part_text}>BURCU KÖMÜRCÜ</p>
            <p className={styles.mentors_part_text}>BÜLENT İŞLER</p>
            <p className={styles.mentors_part_text}>DOLUNAY VAROL</p>
            <p className={styles.mentors_part_text}>ERTUĞ KARADUMAN</p>
            <p className={styles.mentors_part_text}>GİRAY BİRCAN</p>
            <p className={styles.mentors_part_text}>GÜRKAN KAVRAZLI</p>
            <p className={styles.mentors_part_text}>HATİCE YAKINCAN</p>
            <p className={styles.mentors_part_text}>İBRAHİM ETEM GÜL</p>
            <p className={styles.mentors_part_text}>KAAN ERMAN</p>
            <p className={styles.mentors_part_text}>KEMAL ESİNER</p>
            <p className={styles.mentors_part_text}>MUSTAFA ATAKAY</p>
            <p className={styles.mentors_part_text}>TOLGA BABACAN</p>
            <p className={styles.mentors_part_text}>TUĞÇE SEZEN</p>
          </div>

          <div className={styles.section_mentors_part}>
            <h3 className={styles.mentors_part_title}>{translations[appLanguage]['afadExpertGroup']}</h3>
            <p className={styles.mentors_part_text}>T. SELÇUK KÖKSAL </p>
            <p className={styles.mentors_part_text}>AYSEL GÜLDEMİR</p>
            <p className={styles.mentors_part_text}>FADİME ÖZBEY</p>
            <p className={styles.mentors_part_text}>İSMET DEMİR</p>
            <p className={styles.mentors_part_text}>PERİHAN AKBAŞ KAYHAN</p>
            <p className={styles.mentors_part_text}>LÜTFİ KARYAĞDI</p>
            <p className={styles.mentors_part_text}>MEHRİ REİS</p>
            <p className={styles.mentors_part_text}>MEHMET AKKANAT</p>
          </div>

          <div className={styles.section_mentors_part}>
            <h3 className={styles.mentors_part_title}>{translations[appLanguage]['tmmob']}</h3>

            <p className={styles.mentors_part_text}>MUHİTTİN İNCE</p>
            <p className={styles.mentors_part_text}>SERKAN EŞMELİ</p>
          </div>
        </div>
      </Fade>
    </section>
  );
}
