import {baseReducer} from 'context/shared';
import {SIGN_IN_USER_SUCCESS, SIGN_OUT_USER_SUCCESS, SIGN_IN_ERROR} from 'context/signIn/keys';

export const initialState = {
  isSignInSuccess: false,
  signInError: '',
};

export default baseReducer((state = initialState, {type, payload}) => {
  switch (type) {
    case SIGN_IN_USER_SUCCESS:
      return {...state, isLoading: false, isSignInSuccess: true, signInError: ''};
    case SIGN_OUT_USER_SUCCESS:
      return {...state, token: null, isSignInSuccess: false};
    case SIGN_IN_ERROR:
      return {...state, token: null, signInError: payload};
    default:
      return state;
  }
});
