import React, {useContext, useEffect, useState} from 'react';
import {Icon, Select, Input, Button, Checkbox} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './homework-cycle-edit-page.module.css';
import {DatePicker} from 'antd';
import moment from 'moment';

export default function GainEditPage(props) {
  console.log('cycleEdit');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [scoringEndDate, setScoringEndDate] = useState(moment().toString());
  const [_id, set_id] = useState('');
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  // const [name, setName] = useState('');
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getCycle = async (_id) => {
    const {data} = await axiosClient.get(apiURL.cycle + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      console.log('data', data.availableCommissions);
      setName(data.name);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setScoringEndDate(data.scoringEndDate);
      set_id(data._id);
    }
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.cycle,
        {_id, cycle: {name, startDate, endDate, scoringEndDate}},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.cycle,
        {name, startDate, endDate, scoringEndDate},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/cycles');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getCycle(pageSettings._id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  console.log('startDate.toString()', startDate?.toString?.());
  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Dönem Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Dönem adı örn.: 2017-2018 1. dönem'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Başlangıç Tarihi: '}</div>
          <DatePicker allowClear={false} value={moment(startDate)} onChange={(e) => setStartDate(e.toString())} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Teslim Tarihi: '}</div>
          <DatePicker allowClear={false} value={moment(endDate)} onChange={(e) => setEndDate(e)} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Değerlendirme Tarihi: '}</div>
          <DatePicker allowClear={false} value={moment(scoringEndDate)} onChange={(e) => setScoringEndDate(e)} />
        </div>

        <div>
          <Button disabled={!name} onClick={() => handleClick()}>
            {_id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </div>
      </div>
    </div>
  );
}
