import React, {useContext, useRef} from 'react';
import {Input, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {useForm} from 'hooks';
import {translations} from 'resources';
import {isEmail, isEmpty} from 'utils';
import {LanguageContext} from 'context';
import logo from 'resources/images/logo.png';
import styles from './email-verification-page.module.css';

export default function EmailVerificationPage({history}) {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const emailInput = useRef();
  const {
    form: {email},
  } = useForm({
    email: {
      value: '',
      getError: (value) => (isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : ''),
    }
  });

  // const handleFormSubmission = (event) => {
  //   event.preventDefault();
  //   validate() &&
  //     signInUser({
  //       email: email.value,
  //       password: password.value,
  //     });
  // };

  return (
		<form className={styles.email_verification_container}>
			<div className={styles.header_container}>
				<Link to="/">
					<img className={styles.logo} src={logo} alt="logo" />
				</Link>
				<div className={styles.page_header}>{translations[appLanguage]['g34']}</div>
			</div>
			
      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
        <Input
          ref={emailInput}
          value={email?.value}
          onChange={(e) => email?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g3']}
        />

        <div className={styles.error_message}>{email?.error ? email?.error : <span>&nbsp;</span>}</div>
      </div>

      <div className={styles.input_container}>
				<div className={styles.simple_text}>{translations[appLanguage]['g36']}</div>
        <Button inverted size="large">
          {translations[appLanguage]['g35']}
        </Button>
			</div>
    </form>
  );
}
