import React from 'react';

//components
import HomeHeader from './home-header';
import About from './about';
import Mentors from './mentors';
import Team from './team';
import Activities from './activities';

//styles
import styles from './home.module.scss';

export default function Home() {
  return (
    <>
      <HomeHeader />
      <div className={styles.page_container}>
        <About />
        <Team />
        <Mentors />
        <Activities />
      </div>
    </>
  );
}
