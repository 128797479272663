import React, {useContext, useState} from 'react';
import gunlukPDF from 'resources/files/2020gunluk.pdf';
import rehberPDF from 'resources/files/rehber.pdf';
import {Link} from 'react-router-dom';
import {NavHashLink} from 'react-router-hash-link';
import {UserOutlined, UserAddOutlined, MenuOutlined, CloseOutlined} from '@ant-design/icons';

// context
//import {AuthContext} from 'context';

// styles
import styles from './home-header.module.scss';
import logo from 'resources/images/logo.png';
import {LanguageContext} from 'context';
import {Select} from 'antd';
import {translations} from 'resources';

export default function HomeHeader() {
  const [menuIconVisible, setMenuIconVisible] = useState(true);
  // const {
  //   state: {authenticated},
  // } = useContext(AuthContext);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);

  return (
    <>
      <div className={styles.home_header}>
        <div className={styles.logo_menu_icon_container}>
          <Link to="/" className={styles.logo_container}>
            <img className={styles.logo} src={logo} alt="logo" />
          </Link>
          <div onClick={() => setMenuIconVisible(!menuIconVisible)} className={styles.menu_icon_container}>
            {menuIconVisible ? (
              <MenuOutlined className={styles.menu_icon} />
            ) : (
              <CloseOutlined className={styles.menu_icon} />
            )}
          </div>
        </div>

        <div
          className={
            !menuIconVisible ? `${styles.menu_auth_container}` : `${styles.menu_auth_container} ${styles.hide_menu}`
          }
        >
          <div className={styles.menu_container}>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to="/#section_about"
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['about']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to="/#section_team"
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['team']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to="/#section_mentors"
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['expertGroup']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to="/#section_activities"
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['events']}
            </NavHashLink>
            <a
              className={styles.home_header_menu_link}
              // style={{display: 'table-cell'}}
              // href={rehberPDF}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translations[appLanguage]['educationBook']}
            </a>
            {/* <a
              className={styles.home_header_menu_link}
              // style={{display: 'table-cell'}}
              href={gunlukPDF}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ders Günlükleri
            </a> */}
          </div>

          <div className={styles.spacer} />
          <Select
            style={{margin: 8}}
            value={appLanguage}
            onChange={(e, {value}) => changeAppLanguage(value)}
            className={styles.select}
            options={[
              {key: 'Türkçe', value: 'tr', text: 'Türkçe'},
              {key: 'English', value: 'en', text: 'English'},
            ]}
          />

          <div className={styles.auth_btn_container}>
            <Link to="/signin" className={styles.auth_btn}>
              <UserOutlined className={styles.auth_btn_icon} />
              <span className={styles.auth_btn_text}>{translations[appLanguage]['g6']}</span>
            </Link>
            <Link to="/signup" className={styles.auth_btn2}>
              <UserAddOutlined className={styles.auth_btn_icon} />
              <span className={styles.auth_btn_text}>{translations[appLanguage]['g5']}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
