import React, {useContext, useState, useEffect} from 'react';
import {Icon, Select, Input, Checkbox} from 'semantic-ui-react';
import {Button} from 'antd';
import {Link} from 'react-router-dom';

import {LanguageContext, UserContext} from 'context';
import {translations} from 'resources';
import styles from './account-settings-page.module.css';
import {apiURL, axiosClient} from 'service';

const languageOptions = [
  {key: 'Türkçe', value: 'tr', text: 'Türkçe'},
  {key: 'English', value: 'en', text: 'English'},
  {key: 'Arabic', value: 'ar', text: 'Arabic'},
];

export default function SettingsPage() {
  const [_id, set_id] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [university, setUniversity] = useState('');
  const [department, setDepartment] = useState('');
  const [classLevel, setClassLevel] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [earthquakes, setEarthquakes] = useState([]);
  const [earthquakeExperienced, setEarthquakeExperienced] = useState(false);
  const [averagePoint, setAveragePoint] = useState('');
  const [earthquakeClassesTaken, setEarthquakeClassesTaken] = useState(false);
  const [earthquakeClasses, setEarthquakeClasses] = useState([]);
  const [changed, setChanged] = useState(false);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  const handleClick = async () => {
    await axiosClient.put(
      apiURL.user,
      {
        _id,
        firstName,
        lastName,
        earthquakeClasses: earthquakeClassesTaken ? earthquakeClasses : [],
        earthquakeExperienced,
        averagePoint,
        earthquakeClassesTaken,
        gender,
        university,
        department,
        classLevel,
        age,
        earthquakes: earthquakeExperienced ? earthquakes : [],
      },
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
    setChanged(false);
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      set_id(user._id);
      setUniversity(user.university);
      setDepartment(user.department);
      setClassLevel(user.classLevel || 0);
      setAge(user.age || 0);
      setEarthquakeClasses(user.earthquakeClasses || []);
      setEarthquakeClassesTaken(!!user.earthquakeClassesTaken);
      setEarthquakeExperienced(!!user.earthquakeExperienced);
      setAveragePoint(user.averagePoint);
      setEarthquakes(user.earthquakes || []);
      setGender(user.gender);
    }
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{translations[appLanguage]['g28']}</div>

        <div className={settings_card_container}>
          <div className={settings_row}>
            <Link className={settings_row_label} to="/main">
              <Icon name="key" />
              {translations[appLanguage]['g21']}
            </Link>
            <Icon name="caret right" />
          </div>

          <div className={settings_row}>
            <div className={settings_row_label}>
              <Icon name="language" />
              {translations[appLanguage]['g29']}
            </div>
            <Select
              value={appLanguage}
              onChange={(e, {value}) => changeAppLanguage(value)}
              className={styles.select}
              placeholder="Türkçe"
              options={languageOptions}
            />
          </div>
          <div className={settings_row}>
            <div className={settings_row_label}>{translations[appLanguage]['g14']}</div>
            <Input
              value={firstName}
              onChange={(e, {value}) => setFirstName(value) || setChanged(true)}
              className={styles.select}
              placeholder={translations[appLanguage]['g14']}
            />
          </div>
          <div className={settings_row}>
            <div className={settings_row_label}>{translations[appLanguage]['g15']}</div>
            <Input
              value={lastName}
              onChange={(e, {value}) => setLastName(value) || setChanged(true)}
              className={styles.select}
              placeholder={translations[appLanguage]['g15']}
            />
          </div>
          {user?.role === 'student' && (
            <>
              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['university']}</div>
                <Select
                  value={university}
                  onChange={(e, {value}) => {
                    setUniversity(value) || setChanged(true);
                  }}
                  className={styles.select}
                  placeholder={translations[appLanguage]['university']}
                  options={[
                    {key: 1, value: 'Akdeniz Üniversitesi', text: 'Akdeniz Üniversitesi'},
                    {key: 2, value: 'Altınbaş Üniversitesi', text: 'Altınbaş Üniversitesi'},
                    {key: 3, value: 'Mimar Sinan Güzel Sanatlar Üniversitesi', text: 'Mimar Sinan Güzel Sanatlar Üniversitesi'},
                    {key: 4, value: 'Karadeniz Teknik Üniversitesi', text: 'Karadeniz Teknik Üniversitesi'},
                  ]}
                />
              </div>
              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['department']}</div>
                <Select
                  value={department}
                  onChange={(e, {value}) => setDepartment(value) || setChanged(true)}
                  className={styles.select}
                  placeholder={translations[appLanguage]['department']}
                  options={[
                    {key: 1, value: 'İç Mimarlık', text: 'İç Mimarlık'},
                    {key: 2, value: 'İç Mimarlık Ve Çevre Tasarımı', text: 'İç Mimarlık Ve Çevre Tasarımı'},
                  ]}
                />
              </div>

              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['gender']}</div>
                <Select
                  value={gender}
                  onChange={(e, {value}) => setGender(value) || setChanged(true)}
                  className={styles.select}
                  placeholder={translations[appLanguage]['gender']}
                  options={[
                    {key: 1, value: 'male', text: translations[appLanguage]['male']},
                    {key: 2, value: 'female', text: translations[appLanguage]['female']},
                  ]}
                />
              </div>

              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['classLevel']}</div>
                <Input
                  type="number"
                  value={classLevel}
                  onChange={(e, {value}) => setClassLevel(value) || setChanged(true)}
                  className={styles.select}
                  placeholder={translations[appLanguage]['classLevel']}
                />
              </div>
              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['age']}</div>
                <Input
                  type="number"
                  value={age}
                  onChange={(e, {value}) => setAge(value) || setChanged(true)}
                  className={styles.select}
                  placeholder={translations[appLanguage]['age']}
                />
              </div>
              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['earthquakeExperience']}</div>
                <div style={{display: 'flex', gap: '32px'}}>
                  <div style={{alignItems: 'center', display: 'flex', gap: '4px'}} onClick={() => setEarthquakeExperienced(true) || setChanged(true)}>
                    <Checkbox checked={earthquakeExperienced} placeholder={translations[appLanguage]['earthquakeExperience']} />
                    <div>Evet</div>
                  </div>
                  <div
                    style={{alignItems: 'center', display: 'flex', gap: '4px'}}
                    onClick={() => setEarthquakeExperienced(false) || setChanged(true)}
                  >
                    <Checkbox checked={!earthquakeExperienced} placeholder={translations[appLanguage]['earthquakeExperience']} />
                    <div>Hayır</div>
                  </div>
                </div>
              </div>

              {earthquakeExperienced && (
                <div className={settings_row}>
                  <div className={settings_row_label}>{translations[appLanguage]['earthquakes']}</div>
                  <Select
                    value={earthquakes}
                    multiple
                    onChange={(e, {value}) => setEarthquakes(value) || setChanged(true)}
                    className={styles.select}
                    placeholder={translations[appLanguage]['earthquakes']}
                    options={[
                      {key: 1, value: '6 Şubat 2023 Kahramanmaraş Pazarcık Elbistan', text: '6 Şubat 2023 Kahramanmaraş Pazarcık Elbistan'},
                      {key: 2, value: '30 Ekim 2020 İzmir', text: '30 Ekim 2020 İzmir'},
                      {key: 3, value: '24 Ocak 2020 Elazığ', text: '24 Ocak 2020 Elazığ'},
                      {key: 4, value: '21 Temmuz 2017 Gökova', text: '21 Temmuz 2017 Gökova'},
                      {key: 5, value: '24 Mayıs 2014 Gökçeada', text: '24 Mayıs 2014 Gökçeada'},
                      {key: 6, value: '23 Ekim 2011 Van', text: '23 Ekim 2011 Van'},
                      {key: 7, value: '8 Mart 2010 Elazığ', text: '8 Mart 2010 Elazığ'},
                      {key: 8, value: '1 Mayıs 2003 Bingöl', text: '1 Mayıs 2003 Bingöl'},
                      {key: 9, value: '3 Şubat 2002 Afyonkarahisar', text: '3 Şubat 2002 Afyonkarahisar'},
                      {key: 10, value: 'other', text: translations[appLanguage]['other']},
                    ]}
                  />
                </div>
              )}

              <div className={settings_row}>
                <div className={settings_row_label}>{translations[appLanguage]['earthquakeClassesTaken']}</div>
                <div style={{display: 'flex', gap: '32px'}}>
                  <div
                    style={{alignItems: 'center', display: 'flex', gap: '4px'}}
                    onClick={() => setEarthquakeClassesTaken(true) || setChanged(true)}
                  >
                    <Checkbox checked={earthquakeClassesTaken} />
                    <div>Evet</div>
                  </div>
                  <div
                    style={{alignItems: 'center', display: 'flex', gap: '4px'}}
                    onClick={() => setEarthquakeClassesTaken(false) || setChanged(true)}
                  >
                    <Checkbox checked={!earthquakeClassesTaken} />
                    <div>Hayır</div>
                  </div>
                </div>
              </div>

              {earthquakeClassesTaken && (
                <div className={settings_row}>
                  <div className={settings_row_label}>{translations[appLanguage]['earthquakeClasses']}</div>
                  <Select
                    value={earthquakeClasses}
                    multiple
                    onChange={(e, {value}) => setEarthquakeClasses(value) || setChanged(true)}
                    className={styles.select}
                    placeholder={translations[appLanguage]['earthquakeClasses']}
                    options={[
                      {key: 1, value: 'Afet Bilinci', text: 'Afet Bilinci'},
                      {key: 2, value: 'Deprem Yönetmelikleri ve Mimari Tasarıma Etkileri', text: 'Deprem Yönetmelikleri ve Mimari Tasarıma Etkileri'},
                      {key: 3, value: 'Depreme Dayanıklı Yapı Tasarımı İlkeleri', text: 'Depreme Dayanıklı Yapı Tasarımı İlkeleri'},
                      {key: 4, value: 'Yapıların Depreme Karşı Tasarım İlkeleri', text: 'Yapıların Depreme Karşı Tasarım İlkeleri'},
                      {key: 5, value: 'Bina Güvenliği', text: 'Bina Güvenliği'},
                      {key: 6, value: 'AFAD Deprem Eğitimi', text: 'AFAD Deprem Eğitimi'},
                      {key: 7, value: 'STK ve Derneklerin Deprem Eğitimi', text: 'STK ve Derneklerin Deprem Eğitimi'},
                      {key: 10, value: 'other', text: translations[appLanguage]['other']},
                    ]}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {changed && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button
              color="blue"
              type="primary"
              onClick={() => handleClick()}
              disabled={
                user?.role === 'student' &&
                (!age ||
                  !classLevel ||
                  !department ||
                  (earthquakeExperienced && !earthquakes?.length) ||
                  (earthquakeClassesTaken && !earthquakeClasses?.length) ||
                  !university ||
                  !firstName ||
                  !lastName)
              }
            >
              {'Kaydet'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
