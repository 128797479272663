import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, Select} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './commission-edit-page.module.css';

export default function Page(props) {
  const [_id, set_id] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [universityId, setUniversityId] = useState('');
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [minGainPoint, setMinGainPoint] = useState(50);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  // const columns = [
  //   {title: 'İsmi', dataIndex: 'name', key: '0'},
  //   {title: 'Kullanıcılar', key: '3', render: (el) => el?.users?.length},
  //   {
  //     title: 'Detay',
  //     key: 'operation',
  //     fixed: 'right',
  //     width: 90,
  //     // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
  //     render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/commissionEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
  //   },
  // ];

  // const getUniversities = async () => {
  //   const {data} = await axiosClient.get(apiURL.university, {
  //     headers: {
  //       Authorization: 'ARGA ' + localStorage.getItem('token'),
  //     },
  //   });

  //   if (Array.isArray(data)) {
  //     setOptions(data.map((el) => ({text: el.name, value: el._id, key: el._id})));
  //   }
  // };

  // const getCommissions = async (_id) => {
  //   const {data} = await axiosClient.get(apiURL.commission + '?departmentId =' + _id, {
  //     headers: {
  //       Authorization: 'ARGA ' + localStorage.getItem('token'),
  //     },
  //   });

  //   if (Array.isArray(data)) {
  //     setCommissions(data);
  //   }
  // };

  const getCommission = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setUniversityId(data.universityId);
      set_id(data._id);
      setMinGainPoint(data.minGainPoint);
    }
  };

  useEffect(() => {
    if (pageSettings._id) getCommission(pageSettings._id);
  }, [pageSettings._id]);

  useEffect(() => {
    setUniversityId(pageSettings.universityId);
    setDepartmentId(pageSettings.departmentId);
  }, [pageSettings.universityId, pageSettings.departmentId]);

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.commission,
        {_id, name, universityId, departmentId, minGainPoint},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.commission,
        {name, universityId, departmentId, minGainPoint},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    if (pageSettings.departmentId) props.history.push(`/departmentEdit${JSON.stringify({_id: pageSettings.departmentId})}`);
    else props.history.push('/commissions');
  };
  const handleNew = async () => {
    props.history.push(`/commissionEdit${JSON.stringify({departmentId: _id})}`);
  };

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Komisyon:'}</div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Komisyon adı'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kazanım Puanı Limiti : '}</div>
          <Input value={minGainPoint} onChange={(e) => setMinGainPoint(e.target.value)} placeholder={'Kazanım Puanı Limiti'} />
        </div>
        <div className={styles.input_container}>
          <Button onClick={() => handleClick()}>{_id ? 'Güncelle' : 'Kaydet'}</Button>
        </div>

        {/* {_id && (
          <div>
            <div className={styles.input_label}>{'Komisyonlar : '}</div>
            <Table columns={columns} dataSource={commissions} scroll={{x: 500, y: 300}} />
            <div>
              <Button onClick={() => handleNew()}>{'Komisyon Ekle'}</Button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
