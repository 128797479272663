import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, Select} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './department-edit-page.module.css';

export default function Page(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [universityId, setUniversityId] = useState('');
  const [options, setOptions] = useState([]);
  const [commissions, setCommissions] = useState([]);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const columns = [
    {title: 'İsmi', dataIndex: 'name', key: '0'},
    {title: 'Kullanıcılar', key: '3', render: (el) => el?.users?.length},
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/commissionEdit${JSON.stringify({_id: el._id, departmentId: _id})}`)}>
          Düzenle
        </a>
      ),
    },
  ];

  const getUniversities = async () => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setOptions(data.map((el) => ({text: el.name, value: el._id, key: el._id})));
    }
  };

  const getCommissions = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission + '?departmentId=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCommissions(data);
    }
  };

  const getDepartment = async (_id) => {
    const {data} = await axiosClient.get(apiURL.department + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setUniversityId(data.universityId);
      set_id(data._id);
    }
  };

  useEffect(() => {
    getUniversities();
  }, []);
  useEffect(() => {
    if (_id) getCommissions(_id);
  }, [_id]);

  useEffect(() => {
    if (pageSettings._id) getDepartment(pageSettings._id);
  }, [pageSettings._id]);

  useEffect(() => {
    setUniversityId(pageSettings.universityId);
  }, [pageSettings.universityId]);

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.department,
        {_id, name, universityId},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.department,
        {name, universityId},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    if (pageSettings.universityId) props.history.push(`/universityEdit${JSON.stringify({_id: pageSettings.universityId})}`);
    else props.history.push('/universities');
  };
  const handleNew = async () => {
    props.history.push(`/commissionEdit${JSON.stringify({departmentId: _id, universityId})}`);
  };

  useEffect(() => {
    if (pageSettings._id) {
      getUniversities(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Fakülte:'}</div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Universite : '}</div>
          <Select options={options} value={universityId} onChange={(e, {value}) => setUniversityId(value)} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Fakülte adı'} />
        </div>
        <div className={styles.input_container}>
          <Button onClick={() => handleClick()}>{_id ? 'Güncelle' : 'Kaydet'}</Button>
        </div>

        {_id && (
          <div>
            <div className={styles.input_label}>{'Komisyonlar : '}</div>
            <Table columns={columns} dataSource={commissions} scroll={{x: 500, y: 300}} />
            <div>
              <Button onClick={() => handleNew()}>{'Komisyon Ekle'}</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
