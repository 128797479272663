import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, Select} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './commission-settings-page.module.css';

export default function Page(props) {
  const [_id, set_id] = useState('');
  const [minGainPoint, setMinGainPoint] = useState(0);
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [changed, setChanged] = useState(false);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  const getCommission = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setMinGainPoint(data.minGainPoint || 0);
      set_id(data._id);
    }
  };

  useEffect(() => {
    if (user) getCommission(user.commissionId);
  }, [user]);

  const handleClick = async () => {
    setChanged(false);
    await axiosClient.put(
      apiURL.commission,
      {_id, name, minGainPoint},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
  };

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Komisyon Ayarları:'}</div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Komisyon Adı : '}</div>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setChanged(true);
            }}
            placeholder={'Komisyon adı'}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kazanım Puanı Limiti : '}</div>
          <Input
            value={minGainPoint}
            onChange={(e) => {
              setMinGainPoint(e.target.value);
              setChanged(true);
            }}
            placeholder={'Kazanım Puanı Limiti'}
          />
        </div>
        {changed && (
          <div className={styles.input_container}>
            <Button onClick={() => handleClick()}>{'Kaydet'}</Button>
          </div>
        )}

        {/* {_id && (
          <div>
            <div className={styles.input_label}>{'Komisyonlar : '}</div>
            <Table columns={columns} dataSource={commissions} scroll={{x: 500, y: 300}} />
            <div>
              <Button onClick={() => handleNew()}>{'Komisyon Ekle'}</Button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
