import React, {useContext, useState} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';

import {LanguageContext, UserContext} from 'context';
import {translations} from 'resources';
import logo from 'resources/images/logo.png';
import styles from './sidebar-modal.module.css';

export default function SidebarModal({closeModal}) {
  const [path, setPath] = useState(window.location.pathname);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user, unreadMessages},
  } = useContext(UserContext);
  const {modal_container, sidebar_container, menu_list_container, app_logo, logo_container, menu_icon, active_link} = styles;

  const renderLink = (name, url, iconName) => {
    if (url.indexOf('/') !== 0) url = '/' + url;
    return (
      <Link
        onClick={() => {
          setPath(url);
          closeModal();
        }}
        to={url}
        className={`${path === url ? active_link : ''}`}
      >
        {iconName && <Icon className={menu_icon} name={iconName} />}
        <span>{name}</span>
      </Link>
    );
  };

  console.log('path', path);
  return ReactDOM.createPortal(
    <div onClick={closeModal} className={modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={sidebar_container}>
        <Link className={logo_container} to="/main">
          <img src={logo} className={app_logo} alt="logo" />
        </Link>
        {!user.isAdmin && !user.isMaster && (
          <div>{'Üyelik Tipi: ' + user.role === 'student' ? 'Öğrenci' : user.role === 'teacher' ? 'Eğitmen' : 'Kurum'}</div>
        )}
        <nav className={menu_list_container}>
          {renderLink('Özet', '/main', 'home')}
          {renderLink('Mesajlar' + (unreadMessages ? '(' + unreadMessages + ')' : ''), '/messages', 'mail')}
          {/* {(user.isAdmin || user.isMaster) && renderLink('Universiteler', '/universities', 'book')} */}
          {(user.isAdmin || user.isMaster || user.role === 'teacher') && renderLink('Akademisyenler', '/teachers', 'balance scale')}
          {/* {(user.isAdmin || user.isMaster) && renderLink('Komisyonlar', '/commissions', 'eye')} */}
          {/* {user.role !== 'company' && renderLink('Kurumlar', '/companies', 'building')} */}
          {(user.isAdmin || user.isMaster || user.role !== 'student') && renderLink('Öğrenciler', '/users', 'user')}
          {/* {(user.isAdmin || user.isMaster || user.role !== 'company') && renderLink('Kazanımlar', '/gains', 'calendar plus')} */}
          {renderLink('Kategoriler', '/categories', 'list ol')}
          {/* {renderLink('Görev İlanları', '/tasks', 'check')} */}
          {/* {renderLink(
            user.role === 'student' ? 'Görevlerim' : user.role === 'company' ? 'Görevler/Başvurular' : 'Aktif Görevler',
            '/taskApplications',
            'file'
          )} */}
          {/* { renderLink(translations[appLanguage]['g8'], '/accountSettings', 'settings')} */}
          {/* {user.role === 'teacher' && renderLink('Komisyon Ayarları', '/commissionSettings', 'settings')} */}
        </nav>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
