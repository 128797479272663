import {CHANGE_APP_LANGUAGE} from 'context/language/keys';

const acceptedLanguages = ['en', 'tr', 'arb'];

const localLanguage = localStorage.getItem('appLanguage');
if (!acceptedLanguages.includes(localLanguage)) {
  localStorage.setItem('appLanguage', 'tr');
}
const language = acceptedLanguages.includes(localLanguage) ? localLanguage : 'tr';

export const initialState = {
  appLanguage: language,
  languageSetted: localStorage.getItem('languageSetted'),
};

export default (state, {type, payload}) => {
  switch (type) {
    case CHANGE_APP_LANGUAGE:
      return {...state, appLanguage: payload, languageSetted: true};
    default:
      return state;
  }
};
