import React, {useEffect, useState, useContext} from 'react';
import {Table, Tabs, Tooltip} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './report.css';
import {Button, Input} from 'semantic-ui-react';
import {parsePageSettings} from 'helpers/page';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip as ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import {PolarArea, Radar, Bar} from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement
);

// const levels = [
//   {maxPoint: 5, color:"#FFBDBC" risk: 'ÇOK DÜŞÜK', result: 'ÖNEMSİZ RİSKLER', action: 'lorem impusm dolor sit ametxw11111111111111'},
//   {maxPoint: 10, risk: 'DÜŞÜK2', result: 'KATLANILABİLİR RİSKLER', action: 'lorem impusm dolor sit ametxw2222222222'},
//   {maxPoint: 15, risk: 'DÜŞÜK3', result: 'KATLANILABİLİR RİSKLER2', action: 'lorem impusm dolor sit ametxw3333333333'},
//   {maxPoint: 20, risk: 'DÜŞÜK4', result: 'KATLANILABİLİR RİSKLER3', action: 'lorem impusm dolor sit ametxw4444444444'},
//   {maxPoint: 25, risk: 'DÜŞÜK5', result: 'KATLANILABİLİR RİSKLER4', action: 'lorem impusm dolor sit ametxw5555555555'},
// ];
const levels = [
  {
    maxPoint: 5,
    color: '#FED5D5',
    risk: 'ÇOK DÜŞÜK',
    result: 'ÖNEMSİZ RİSK',
    action: 'Belirlenen riskler kabul edilebilir düzeydedir ve kontrol önlemleri almaya gerek duyulmayabilir.',
  },
  {
    maxPoint: 10,
    color: '#FFBDBC',
    risk: 'DÜŞÜK',
    result: 'KATLANILABİLİR RİSK',
    action:
      'Belirlenen riskler katlanılabilir düzeydedir.  Mevcut ve gerekli kontroller yapılırken ilave önlemlere gerek duyulmayabilir.',
  },
  {
    maxPoint: 15,
    color: '#FF7D7D',
    risk: 'ORTA',
    result: 'ORTA DÜZEY RİSK',
    action:
      'Belirlenen riskleri kabul edilebilir düzeye düşürmek için gerekli kontroller yapılmalı ve ek önlemler alınmalıdır. Bu önlemler zaman alabilir.',
  },
  {
    maxPoint: 20,
    risk: 'YÜKSEK',
    color: '#C91A30',
    result: ' ÖNEMLİ RİSK ',
    action:
      'Belirlenen riskler kabul edilebilir düzeye getirilene kadar tasarıma derhal müdahale edilmeli, gerekli kontroller sıklıkla yapılmalı, ek önlemler alınmalı ve kabul edilebilir seviyeye gelene kadar risk azaltma önlemleri devam etmelidir.',
  },
  {
    maxPoint: 25,
    risk: 'ÇOK YÜKSEK',
    color: '#C91A30',
    result: 'KATLANILMAZ RİSK',
    action:
      'Belirlenen risk katlanılamaz düzeydedir ve tasarıma derhal müdahale edilmelidir. Risk seviyesi azaltılıncaya ve kabul edilebilir seviyeye gelinceye kadar gerekli kontroller çok sık yapılmalı, ek önlemler alınmalı ve gerekirse değiştirilerek yeniden tasarlanmalıdır.',
  },
];

export default function GainsPage(props) {
  const [categories, setCategories] = useState([]);
  const [homework, setHomework] = useState();
  const [studentNote, setStudentNote] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [cycle, setCycle] = useState();
  const [cycleId, setCycleId] = useState();
  const [homeworkCategories, setHomeworkCategories] = useState([]);
  const [notes, setNotes] = useState([]);
  const [reportFiles, setReportFiles] = useState([]);
  const [level1Categories, setLevel1Categories] = useState([]);
  const [selectedLevel1Category, setSelectedLevel1Category] = useState('');
  const [categoryAverages, setCategoryAverages] = useState([]);

  const [level2Categories, setLevel2Categories] = useState([]);
  const [selectedLevel2Category, setSelectedLevel2Category] = useState('');
  const [level3Categories, setLevel3Categories] = useState([]);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState('');
  const [level4Categories, setLevel4Categories] = useState([]);
  const [selectedLevel4Category, setSelectedLevel4Category] = useState('');
  const [level5Categories, setLevel5Categories] = useState([]);
  const [selectedLevel5Category, setSelectedLevel5Category] = useState('');

  let pageSettings = parsePageSettings(props.match.params.id);

  const level1TabItems = level1Categories.map((el) => ({label: el.name, key: el._id}));
  const level2TabItems = level2Categories.map((el) => ({label: el.name, key: el._id}));
  const level2ChartItems = level2Categories
    .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
    .map((el) => ({
      label: el.name,
      key: el._id,
      averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
    }));
  const level3TabItems = level3Categories.map((el) => ({label: el.name, key: el._id}));
  const level3ChartItems = level3Categories
    .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
    .map((el) => ({
      label: el.name,
      key: el._id,
      averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
    }));
  const level4TabItems = level4Categories.map((el) => ({
    label: <div style={{whiteSpace: 'pre-wrap', textAlign: 'initial'}}>{el.name}</div>,
    key: el._id,
  }));
  const level4ChartItems = level4Categories
    .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
    .map((el) => ({
      label: el.name,
      key: el._id,
      averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
    }));

  const level5TabItems = level5Categories.map((el) => ({
    label: <div style={{whiteSpace: 'pre-wrap', textAlign: 'initial'}}>{el.name}</div>,
    key: el._id,
  }));

  const level5ChartItems = level5Categories
    .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
    .map((el) => ({
      label: el.name,
      key: el._id,
      averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
    }));

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  useEffect(() => {
    if (categories.length < 1) return;
    const level1Categories = categories.filter((el) => !el.parentId).sort((a, b) => a.priority - b.priority);
    setLevel1Categories(level1Categories);
    // console.log('level1Categories', level1Categories, categories);
    if (level1Categories.length > 0) {
      setSelectedLevel1Category(level1Categories[0]._id);
    }
  }, [categories]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel1Category) return;
    const level2Categories = categories
      .filter((el) => el.parentId === selectedLevel1Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel2Categories(level2Categories);
    if (level2Categories.length > 0) {
      setSelectedLevel2Category(level2Categories[0]._id);
    }
  }, [categories, selectedLevel1Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel2Category) return;
    const level3Categories = categories
      .filter((el) => el.parentId === selectedLevel2Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel3Categories(level3Categories);
    if (level3Categories.length > 0) {
      setSelectedLevel3Category(level3Categories[0]._id);
    }
  }, [categories, selectedLevel2Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel3Category) return;
    const level4Categories = categories
      .filter((el) => el.parentId === selectedLevel3Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel4Categories(level4Categories);
    if (level4Categories.length > 0) {
      setSelectedLevel4Category(level4Categories[0]._id);
    }
  }, [categories, selectedLevel3Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel4Category) return;
    const level5Categories = categories
      .filter((el) => el.parentId === selectedLevel4Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel5Categories(level5Categories);
    if (level5Categories.length > 0) {
      setSelectedLevel5Category(level5Categories[0]._id);
    }
  }, [categories, selectedLevel4Category]);

  const columns = [
    {
      title: 'Değerlendirme Ölçütü',
      dataIndex: 'name',
      key: '2',
      width: 10,
      // render: (value, record) => {
      //   //console.log('categories', record, categories);
      //   const parents = [value];
      //   let dangers = [...(record.dangers || [])];
      //   let risks = [...(record.risks || [])];
      //   let precautions = [...(record.precautions || [])];
      //   const getParents = (category) => {
      //     if (category.parentId) {
      //       const parent = categories.find((el) => el._id === category.parentId);
      //       if (parent) {
      //         parents.push(parent.name);
      //         dangers = [...dangers, ...(parent.dangers || [])];
      //         risks = [...risks, ...(parent.risks || [])];
      //         precautions = [...precautions, ...(parent.precautions || [])];
      //         getParents(parent);
      //       }
      //     }
      //   };
      //   getParents(record);
      //   return (
      //     <div>
      //       <div>{parents.reverse().join(' -> ')}</div>
      //       <div>
      //         {/* <Table
      //           columns={[
      //             {
      //               title: 'Tehlikeler',
      //               key: 'precautions',
      //             },
      //           ]}
      //           dataSource={precautions.map((el) => ({precautions: el}))}
      //           scroll={{x: 100, y: 50}}
      //           pagination={{pageSize: 100}}
      //         /> */}
      //       </div>
      //     </div>
      //   );
      // },
    },
    {
      title: (
        <Tooltip placement="bottom" title={'İlgili Kategorinin Projedeki Kullanım Sıklığı'}>
          Kullanım Sıklığı
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '2',
      width: 20,
      render: (value, record) => (
        <Input
          style={{width: 80}}
          disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now()}
          className="scoreInput"
          type="number"
          value={homeworkCategories.find((el) => el.categoryId === record._id)?.frequency || 0}
          onChange={(e) => {
            const item = homeworkCategories.find((el) => el.categoryId === record._id);
            if (!item) homeworkCategories.push({categoryId: record._id, frequency: e.target.value, dangerLevel: 0});
            else {
              item.frequency = e.target.value;
              item.dangerLevel = 0;
            }
            setHomeworkCategories([...homeworkCategories]);
          }}
        />
      ),
    },
    {
      title: (
        <Tooltip placement="bottom" title={'İlgili Kategorinin Projedeki Konumunun Tehlike Derecesi'}>
          Tehlike Derecesi
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '2',
      width: 20,
      render: (value, record) => (
        <Input
          style={{width: 80}}
          disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now()}
          className="scoreInput"
          type="number"
          value={homeworkCategories.find((el) => el.categoryId === record._id)?.dangerLevel || 0}
          onChange={(e) => {
            const item = homeworkCategories.find((el) => el.categoryId === record._id);
            if (!item) homeworkCategories.push({categoryId: record._id, dangerLevel: e.target.value, frequency: 0});
            else {
              item.dangerLevel = e.target.value;
              item.frequency = 0;
            }
            setHomeworkCategories([...homeworkCategories]);
          }}
        />
      ),
    },
    {
      title: (
        <Tooltip placement="bottom" title={'Uzman Gurup Tarafından Belirlenen Şiddet Derecesidir'}>
          Şiddet
        </Tooltip>
      ),
      dataIndex: 'name',
      key: '4',
      width: 20,
      render: (value, record) => (
        <Input
          style={{width: 80}}
          disabled={user.role !== 'teacher' || new Date(cycle?.scoringDate) < Date.now()}
          className="scoreInput"
          type="number"
          value={homeworkCategories.find((el) => el.categoryId === record._id)?.volume || 0}
          onChange={(e) => {
            const item = homeworkCategories.find((el) => el.categoryId === record._id);
            if (!item) homeworkCategories.push({categoryId: record._id, volume: e.target.value});
            else item.volume = e.target.value;
            setHomeworkCategories([...homeworkCategories]);
          }}
        />
      ),
    },
    {
      title: 'Puan',
      dataIndex: 'name',
      key: '5',
      width: 20,
      render: (value, record) => {
        const item = homeworkCategories.find((el) => el.categoryId === record._id);
        return (item?.frequency || item?.dangerLevel) * item?.volume || 0;
      },
    },
  ];

  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };
  const setHomeworkScore = async () => {
    const {data} = await axiosClient.post(
      apiURL.homeworkScore,
      {_id: pageSettings.homeworkId, categories: homeworkCategories},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };

  const getHomework = async () => {
    const {data} = await axiosClient.get(apiURL.homeworkFromId + '?_id=' + pageSettings.homeworkId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?.[0]) {
      setHomework(data[0]);
    }
  };
  const getCycle = async () => {
    const {data} = await axiosClient.get(apiURL.cycle + '?_id=' + cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data) {
      setCycle(data);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (homeworkCategories.length > 0) {
      const calcAverageFirst = () => {
        categories.forEach((el) => {
          const frequencies = [];
          const dangerLevels = [];
          const volumes = [];
          const subCategories = homeworkCategories.filter(
            (el2) => el._id === categories.find((el3) => el3._id === el2.categoryId)?.parentId
          );
          let total = 0;
          let maxVolume = 0,
            maxFrequency = 0,
            maxDangerLevel = 0;
          const average = Math.round(
            subCategories.reduce((a, b) => {
              const volume = categories.find((el2) => el2._id === b.categoryId)?.volume || 0;

              const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
              if (score) total++;
              if (b?.frequency) {
                frequencies.push(b?.frequency);
                if (maxFrequency < b?.frequency) maxFrequency = b?.frequency;
              } else if (b?.maxFrequency) {
                if (maxFrequency < b?.maxFrequency) maxFrequency = b?.maxFrequency;
              }
              if (b?.dangerLevel) {
                dangerLevels.push(b?.dangerLevel);
                if (maxDangerLevel < b?.dangerLevel) maxDangerLevel = b?.dangerLevel;
              } else if (b?.maxDangerLevel) {
                if (maxDangerLevel < b?.maxDangerLevel) maxDangerLevel = b?.maxDangerLevel;
              }
              if (volume) {
                volumes.push(volume);
                if (maxVolume < volume) maxVolume = volume;
              } else if (b?.maxVolume) {
                if (maxVolume < b?.maxVolume) maxVolume = b?.maxVolume;
              }
              return a + score;
            }, 0) / total
          );
          el.averageScore = average;
          if (frequencies.length > 0) {
            el.frequencyScore = Math.round(frequencies.reduce((a, b) => a + b, 0) / frequencies.length);
          }
          if (dangerLevels.length > 0) {
            el.dangerLevelScore = Math.round(dangerLevels.reduce((a, b) => a + b, 0) / dangerLevels.length);
          }
          if (volumes.length > 0) {
            el.volumeScore = Math.round(volumes.reduce((a, b) => a + b, 0) / volumes.length);
          }
          el.maxVolume = maxVolume;
          el.maxFrequency = maxFrequency;
          el.maxDangerLevel = maxDangerLevel;

          // console.log('average', average, el.name);
        });
      };
      const calcAverage = () => {
        categories.forEach((el) => {
          const subCategories = categories.filter((el2) => el._id === el2.parentId);
          let total = 0;
          const frequencies = [];
          const dangerLevels = [];
          const volumes = [];
          let maxVolume = 0,
            maxFrequency = 0,
            maxDangerLevel = 0;
          const average = Math.round(
            subCategories.reduce((a, b) => {
              const volume = categories.find((el2) => el2._id === b.categoryId)?.volume || 0;

              const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
              if (b?.frequency) {
                frequencies.push(b?.frequency);
                if (maxFrequency < b?.frequency) maxFrequency = b?.frequency;
              } else if (b?.maxFrequency) {
                if (maxFrequency < b?.maxFrequency) maxFrequency = b?.maxFrequency;
              }
              if (b?.dangerLevel) {
                dangerLevels.push(b?.dangerLevel);
                if (maxDangerLevel < b?.dangerLevel) maxDangerLevel = b?.dangerLevel;
              } else if (b?.maxDangerLevel) {
                if (maxDangerLevel < b?.maxDangerLevel) maxDangerLevel = b?.maxDangerLevel;
              }
              if (volume) {
                volumes.push(volume);
                if (maxVolume < volume) maxVolume = volume;
              } else if (b?.maxVolume) {
                if (maxVolume < b?.maxVolume) maxVolume = b?.maxVolume;
              }
              if (score) total++;
              return a + score;
            }, 0) / total
          );
          if (average) {
            el.averageScore = average;
          }
          if (frequencies.length > 0) {
            el.frequencyScore = Math.round(frequencies.reduce((a, b) => a + b, 0) / frequencies.length);
          }
          if (dangerLevels.length > 0) {
            el.dangerLevelScore = Math.round(dangerLevels.reduce((a, b) => a + b, 0) / dangerLevels.length);
          }
          if (volumes.length > 0) {
            el.volumeScore = Math.round(volumes.reduce((a, b) => a + b, 0) / volumes.length);
          }
          if (!el.maxVolume) el.maxVolume = maxVolume;
          if (!el.maxFrequency) el.maxFrequency = maxFrequency;
          if (!el.maxDangerLevel) el.maxDangerLevel = maxDangerLevel;
          // console.log('average', average, el.name);
        });
      };
      calcAverageFirst();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      const averages = categories
        .filter((el) => el.averageScore)
        .map((el) => ({
          averageScore: el.averageScore,
          name: el.name,
          _id: el._id,
          frequencyScore: el.frequencyScore,
          dangerLevelScore: el.dangerLevelScore,
          volumeScore: el.volumeScore,
        }));
      setCategoryAverages(averages);
      console.log('averrages', averages);
    }
  }, [homeworkCategories, categories]);

  useEffect(() => {
    if (cycle) getCycle();
  }, [cycleId]);
  useEffect(() => {
    if (homework) {
      setHomeworkCategories(homework.categories || []);
      setNotes(homework.notes || []);
      setReportFiles(homework.reportFiles || []);
      setFile(homework.file || '');
      setCycleId(homework.cycleId || '');
      setFileName(homework.fileName || '');
      setStudentNote(homework.studentNote || '');
      console.log('homeworkhomework', homework);
    }
  }, [homework]);
  useEffect(() => {
    if (pageSettings.homeworkId) getHomework();
  }, [pageSettings.homeworkId]);

  const handleClick = () => {
    setHomeworkScore();
    props.history.goBack();
  };
  // useEffect(() => {
  //   const leafCategories = categories.filter((el) => !categories.find((el2) => el2.parentId === el._id));
  //   leafCategories.forEach((el) => {
  //     const parents = [];
  //     const getParents = (category) => {
  //       if (!category) return;
  //       parents.push(category);
  //       if (category.parentId) getParents(categories.find((el2) => el2._id === category.parentId));
  //     };
  //     if (el.parentId) getParents(categories.find((el2) => el2._id === el.parentId));
  //     // el.parents = parents;
  //     el.isLeaf = true;
  //   });

  //   console.log(leafCategories);
  //   setLeafCategories(leafCategories);
  //   // axiosClient.put(apiURL.categoryAll, leafCategories, {
  //   //   headers: {
  //   //     Authorization: 'ARGA ' + localStorage.getItem('token'),
  //   //   },
  //   // });
  // }, [categories]);

  const renderChart = (chartItems, label = 'Ana Kategori Puanı') => {
    const options = {
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    const data = {
      labels: chartItems.map((el) => el.label),
      datasets: [
        {
          label: 'Proje Puanı',
          data: [5, 10],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Genel Ortalama',
          data: [7, 14],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '500px', height: '500px'}}>
          <PolarArea
            options={{
              scales: {},
            }}
            data={{
              labels: chartItems.map((el) => el.label),
              datasets: [
                {
                  label,
                  data: chartItems.map((el) => el.averageScore),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
        {chartItems?.length > 2 ? (
          <div style={{width: '500px', height: '500px'}}>
            <Radar
              data={{
                labels: chartItems.map((el) => el.label),
                datasets: [
                  {
                    label: 'Proje Puanı',
                    data: chartItems.map((el) => el.averageScore),
                    backgroundColor: 'rgba(29,226,180,0.2)',
                    borderColor: 'rgba(29,226,180,1)',
                    borderWidth: 1,
                  },
                  {
                    label: 'Genel Ortalama',
                    data: chartItems.map((el) => el.averageScore).map((el) => Math.round(el + Math.random() * 4 - 2)),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        ) : (
          <div style={{width: '500px', height: '600px'}}>
            <Bar options={options} data={data} />
          </div>
        )}
      </div>
    );
  };

  const renderResult = (categoryId, withNote) => {
    const category = categories.find((el) => el._id === categoryId);

    const level = levels.find(
      (el) => el.maxPoint >= categoryAverages?.find((el2) => el2._id === categoryId)?.averageScore
    );
    const averageScore = categoryAverages?.find((el2) => el2._id === categoryId)?.averageScore;
    if (!level) return;
    return (
      <div>
        <div style={{display: 'flex'}}>
          <div>
            <div style={{margin: '5px'}}>
              <div style={{fontWeight: 600, width: 'fit-content'}}>RİSK Puanı</div>
              <div style={{backgroundColor: level.color}}>{averageScore}</div>
            </div>
            <div style={{margin: '5px'}}>
              <div style={{fontWeight: 600, width: 'fit-content'}}>RİSK SEVİYESİ</div>
              <div style={{backgroundColor: level.color}}>{level.risk}</div>
            </div>
            <div style={{margin: '5px'}}>
              <div style={{fontWeight: 600, width: 'fit-content'}}>SONUÇ</div>
              <div style={{backgroundColor: level.color}}>{level?.result}</div>
            </div>
          </div>
          <div style={{margin: '5px'}}>
            <div style={{fontWeight: 600}}>EYLEM</div>
            <div style={{width: '400px'}}>{level?.action}</div>
            {category?.frequencyMessages?.[category?.maxFrequency - 1] && (
              <>
                <div>Alınacak Sıklık Önlemi:</div>
                <div style={{width: '400px'}}>{category?.frequencyMessages?.[category?.maxFrequency - 1]}</div>
              </>
            )}
            {category?.dangerLevelMessages?.[category?.maxDangerLevel - 1] && (
              <>
                <div>Alınacak Konum Önlemi:</div>
                <div style={{width: '400px'}}>{category?.dangerLevelMessages?.[category?.maxDangerLevel - 1]}</div>
              </>
            )}
            {category?.volumeMessages?.[category?.maxVolume - 1] && (
              <>
                <div>Alınacak Şiddet Önlemi:</div>
                <div style={{width: '400px'}}>{category?.volumeMessages?.[category?.maxVolume - 1]}</div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '12px',
            flexDirection: 'column',
          }}
        >
          {reportFiles
            .filter((el) => el.includes(categoryId))
            .map((el) => (
              <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                <a href={el} download={el}>
                  <img style={{width: '500px'}} src={el} />
                </a>
              </div>
            ))}
        </div>
        {withNote && notes.find((el) => el.categoryId === categoryId)?.note && (
          <div style={{margin: '5px', fontWeight: 500, fontSize: '20px'}}>
            <div style={{fontWeight: 600}}>Ek Not: </div>
            <div style={{width: '400px'}}>{notes.find((el) => el.categoryId === categoryId)?.note}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="users_container">
      <a
        // onClick={() =>
        //   props.history.push(`${process.env.PUBLIC_URL}/report${JSON.stringify({homeworkId: homework?._id})}`)
        // }
        href={`/printReport${JSON.stringify({homeworkId: homework?._id})}`}
        target="_blank"
      >
        Yazdırma Sayfasına Git
      </a>
      <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
        <div style={{margin: '10px', width: '200px'}}>
          <div style={{fontWeight: 600}}>Ödev Dosyası</div>
          {file ? (
            <a href={file} download={file}>
              {fileName}
            </a>
          ) : (
            <div>Ödev Yüklenmemiş!!!</div>
          )}
        </div>
        <div>
          <div style={{margin: '10px', width: '200px'}}>
            <div style={{fontWeight: 600}}>Öğrenci Notu</div>
            <div>{studentNote || '--'}</div>
          </div>
        </div>
        {renderResult(categoryAverages?.[0]?._id, false, 1)}
      </div>

      <div style={{margin: '5px'}}>
        <Tabs items={level1TabItems} onChange={(e) => setSelectedLevel1Category(e)} />
        <div style={{width: '100%', margin: '5px'}}>
          {renderChart(level2ChartItems, 'Ana Kategori Risk Puanı')}
          <Tabs items={level2TabItems} onChange={(e) => setSelectedLevel2Category(e)} />
        </div>
        <div style={{width: '100%', margin: '8px'}}>
          <div>
            <div> {renderResult(selectedLevel2Category, false, 2)}</div>
            <div>{renderChart(level3ChartItems, 'Modül Risk Puanı')}</div>
          </div>

          <Tabs items={level3TabItems} onChange={(e) => setSelectedLevel3Category(e)} />
        </div>

        <div style={{width: '100%', margin: '8px'}}>
          <div>
            <div> {renderResult(selectedLevel3Category)}</div>
            <div>{renderChart(level4ChartItems, 'Modül Risk Puanı')}</div>
          </div>

          <Tabs items={level4TabItems} onChange={(e) => setSelectedLevel4Category(e)} />
        </div>

        <div>
          <div> {renderResult(selectedLevel4Category)}</div>
          <div>{renderChart(level5ChartItems, 'Alt Kategori Risk Puanı')}</div>
        </div>

        <div style={{width: '700px', display: 'flex', maxHeight: '400px'}}>
          <Tabs
            tabBarStyle={{maxWidth: '150px'}}
            style={{maxWidth: '200px', height: '400px'}}
            tabPosition="left"
            items={level5TabItems}
            renderTabBar={(props, DefaultTabBar) => (
              <DefaultTabBar {...props} style={{whiteSpace: 'pre-wrap', maxWidth: '150px'}} />
            )}
            onChange={(e) => setSelectedLevel5Category(e)}
          />
          <div>
            <div> {renderResult(selectedLevel5Category, true)}</div>
          </div>
        </div>
      </div>
      {((user.role === 'teacher' && new Date(cycle?.scoringDate) > Date.now()) || user.isAdmin) && (
        <div>
          <Button disabled={false} onClick={() => handleClick()}>
            {'Kaydet'}
          </Button>
        </div>
      )}
    </div>
  );
}
