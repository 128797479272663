import React, {useContext, useEffect, useState} from 'react';
import {Icon, Select, Input, Button, Checkbox} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './teacher-edit-page.module.css';

export default function GainEditPage(props) {
  const [availableCommissions, setAvailableCommissions] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [_id, set_id] = useState('');
  const [approved, setApproved] = useState(false);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  // const [name, setName] = useState('');
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getStudent = async (_id) => {
    const {data} = await axiosClient.get(apiURL.user + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      console.log('data', data.availableCommissions);
      setAvailableCommissions(data.availableCommissions || []);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      set_id(data._id);
      setApproved(data.approved);
    }
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.user,
        {_id, availableCommissions, approved},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/teachers');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getStudent(pageSettings._id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Komisyon Üyesi:' + firstName + ' ' + lastName}</div>
        {(user.isAdmin || user.isMaster) && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Akademisyen Onayı : '}</div>
            <Checkbox
              toggle
              checked={user?.isAdmin || user?.isMaster ? approved : availableCommissions?.indexOf(user.commissionId) >= 0}
              onChange={(e, {checked}) => {
                console.log('value', checked);
                if (user?.isAdmin || user?.isMaster) {
                  setApproved(checked);
                } else {
                  if (checked) setAvailableCommissions([...availableCommissions, user.commissionId]);
                  else setAvailableCommissions(availableCommissions.filter((el) => el !== user.commissionId));
                }
              }}
              className={styles.selectInput}
            />
          </div>
        )}

        <div style={{marginTop: '10px'}}>
          <Button onClick={() => handleClick()}>{'Güncelle'}</Button>
        </div>
      </div>
    </div>
  );
}
