import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {Table} from 'antd';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './university-edit-page.module.css';

export default function GainEditPage(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [options, setOptions] = useState([]);
  const [departments, setDepartments] = useState([]);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const columns = [
    {title: 'İsmi', dataIndex: 'name', key: '0'},
    {title: 'Komisyonlar', key: '2', render: (el) => el?.commissions?.length},
    {title: 'Kullanıcılar', key: '3', render: (el) => el?.users?.length},
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/departmentEdit${JSON.stringify({_id: el._id, universityId: _id})}`)}>
          Düzenle
        </a>
      ),
    },
  ];

  const getDepartments = async (_id) => {
    const {data} = await axiosClient.get(apiURL.department + '?universityId =' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setDepartments(data);
    }
  };

  useEffect(() => {
    if (pageSettings._id) getDepartments(pageSettings._id);
  }, [pageSettings._id]);

  const getUniversity = async (_id) => {
    const {data} = await axiosClient.get(apiURL.university + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      set_id(data._id);
    }
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.university,
        {_id, name},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.university,
        {name},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/universities');
  };
  const handleNew = async () => {
    props.history.push(`/departmentEdit${JSON.stringify({universityId: _id})}`);
  };

  useEffect(() => {
    if (pageSettings._id) {
      getUniversity(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Universite:'}</div>

        {/* <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Adı'} />
        </div> */}
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <div style={{display: 'flex'}}>
            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Universite adı'} />
            <Button onClick={() => handleClick()}>{_id ? 'Güncelle' : 'Kaydet'}</Button>
          </div>
        </div>
        {_id && (
          <div>
            <div className={styles.input_label}>{'Fakülteler : '}</div>
            <Table columns={columns} dataSource={departments} scroll={{x: 500, y: 300}} />
            <div>
              <Button onClick={() => handleNew()}>{'Fakülte Ekle'}</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
