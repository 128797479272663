import React, {useEffect, useState, useContext} from 'react';
import {Table, Modal} from 'antd';
import {Button} from 'semantic-ui-react';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './task-applications-page.css';

export default function GainsPage(props) {
  const [taskApplications, setTaskApplications] = useState([]);
  const [taskCategories, setTaskCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTaskApplication, setSelectedTaskApplication] = useState('');
  const [selectedTaskApplicationName, setSelectedTaskApplicationName] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(0);

  const generateStatus = (item) => {
    if (!item) return;
    if (item.commissionLeaderAccepted) return 'Tamamlandı';
    else if (item.studentCancelled) return 'Öğrenci İptal İstedi';
    else if (item.companyDeclined) return 'Kurum Tarafından Reddedildi';
    else if (item.commissionLeaderDeclined) return 'Komisyon Başkanı Tarafından Reddedildi';
    else if (item.commissionDeclined) return 'Komisyon Tarafından Reddedildi';
    else if (!item.started) return 'Kurum Başvuruyu Henüz Onaylamadı';
    else if (item.studentCompleted) {
      if (!item.companyAccepted) return 'Kurum Onayı Bekleniyor';
      else if (item.commissionAccepted && item.companyAccepted) return 'Komisyon Başkanı Onayı Bekleniyor';
      else if (item.companyAccepted && !item.studentLoadedFinalFile) return 'Komisyona Sunulması bekleniyor';
      else if (item.companyAccepted) return 'Komisyon Onayı Bekleniyor';
    } else return 'Devam Ediyor';
  };

  const generateColumns = () => {
    const returnThis = [
      {
        title: 'Öğrenci',
        key: '-1',
        render: (el) => {
          const student = students?.find?.((i) => i._id === el.studentId);
          if (!student) return;
          return student.firstName + ' ' + (student.lastName ? student.lastName : '');
        },
      },
      {title: 'Şirket', key: '0', render: (el) => companies?.find?.((i) => i._id === el.companyId)?.firstName},
      {
        title: 'Görev Kategorisi',
        key: '1-',
        render: (el) => taskCategories?.find((item) => item._id === el.taskCategoryId)?.name,
      },
      {
        title: 'Görev Adı',
        key: '1',
        render: (el) => tasks?.find((item) => item._id === el.taskId)?.name,
      },
      {
        title: 'Görev Açıklaması',
        key: '2',
        render: (el) => tasks?.find((item) => item._id === el.taskId)?.description,
        // render: (el) => tasks?.find((item) => item._id === el.taskId)?.steps?.reduce((p, item) => p + (p ? ' , ' : '') + item, ''),
      },
      {title: 'Durum', key: '3', render: (el) => generateStatus(el)},
      {
        title: 'Düzenle',
        key: '7',
        fixed: 'right',
        width: 90,
        render: (el) => (
          <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/taskApplicationEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>
        ),
      },
    ];
    if (user.isAdmin || user.isMaster) {
      returnThis.push({
        title: 'Sil',
        key: '0',
        render: (el) => (
          <div>
            <Button
              color="red"
              onClick={() => {
                setShowModal(true);
                setSelectedTaskApplication(el._id);
                setSelectedTaskApplicationName(tasks?.find((item) => item._id === el.taskId)?.name);
              }}
            >
              Sil
            </Button>
          </div>
        ),
      });
    }
    if (user.role == 'student') {
      returnThis.push({
        title: 'İptal İsteği',
        key: '0',
        render: (el) => (
          <div>
            <Button
              color="red"
              onClick={() => {
                setShowModal(true);
                setSelectedTaskApplication(el._id);
                setSelectedTaskApplicationName(tasks?.find((item) => item._id === el.taskId)?.name);
              }}
            >
              İptal
            </Button>
          </div>
        ),
      });
    }
    return returnThis;
  };

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const getTaskApplications = async () => {
    const {data} = await axiosClient.get(apiURL.taskApplication, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskApplications(data);
    }
  };

  const getTaskCategories = async () => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskCategories(data);
    }
  };

  const getTasks = async () => {
    const {data} = await axiosClient.get(apiURL.task, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTasks(data);
    }
  };

  const getCompanies = async () => {
    const {data} = await axiosClient.get(apiURL.companies, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCompanies(data);
    }
  };

  const getStudents = async () => {
    const {data} = await axiosClient.get(apiURL.students, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setStudents(data.filter((el) => el.role === 'student'));
    }
  };

  const deleteTaskApplication = async () => {
    await axiosClient.delete(apiURL.taskApplication + '?_id=' + selectedTaskApplication, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
  };

  const cancelTaskApplication = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {_id: selectedTaskApplication, studentCancelled: true},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
  };

  useEffect(() => {
    getTaskApplications();
    getCompanies();
    getTaskCategories();
    getTasks();
    getStudents();
  }, []);

  useEffect(() => {
    setColumns(generateColumns());
  }, [companies, taskCategories, tasks, students]);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={taskApplications} scroll={{x: 800, y: 300}} />
      {/* {user.role === 'student' && <BottomMenu {...props} url={'/taskApplicationEdit'} />} */}
      <Modal
        title="Görev Siliniyor"
        visible={showModal}
        onOk={() => {
          setShowModal(false);
          if (user.role == 'student') {
            cancelTaskApplication();
            taskApplications.find((el) => el._id === selectedTaskApplication).studentCancelled = true;
            setTaskApplications([...taskApplications]);
          } else {
            deleteTaskApplication();
            setTaskApplications(taskApplications.filter((el) => el._id !== selectedTaskApplication));
          }
        }}
        onCancel={() => setShowModal(false)}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>
          {user.role == 'student'
            ? selectedTaskApplicationName + ' iptal etmek istiyor musunuz (geri çevrilemez)'
            : selectedTaskApplicationName + ' silmek istiyor musunuz (geri çevrilemez)'}
        </p>
      </Modal>
    </div>
  );
}
