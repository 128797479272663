import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Table, Input, Tooltip, Modal} from 'antd';
import {FilterOutlined} from '@ant-design/icons';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import BottomMenu from 'ui/components/bottom-menu';
import './students-page.css';
import {Button, Checkbox} from 'semantic-ui-react';

export default function UsersPage(props) {
  const [students, setStudents] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(false);
  const [selectedStudentName, setSelectedStudentName] = useState(false);
  const [availableCommissions, setAvailableCommissions] = useState([]);

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  const columns = [
    {title: 'Adı', width: 100, dataIndex: 'firstName', key: '1'},
    {title: 'Soyadı', width: 100, dataIndex: 'lastName', key: '2'},
    {title: 'Sınıfı', dataIndex: 'grade', key: '3'},
    {title: 'Numarası', dataIndex: 'idNumber', key: '4'},
    {title: 'Email', dataIndex: 'email', key: '5'},
    user.role !== 'teacher'
      ? {title: 'Onay', key: '6', render: (el) => (el.availableCommissions?.indexOf(user.commissionId) >= 0 ? 'Evet' : 'Hayır')}
      : {},
    {
      title: 'Düzenle',
      key: '7',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/studentEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
    // {title: 'Action', key: 'operation', fixed: 'right', width: 100, render: () => <Link to="/main" />},
  ];

  const adminColumns = [
    {title: 'Adı', width: 100, dataIndex: 'firstName', key: '1'},
    {title: 'Soyadı', width: 100, dataIndex: 'lastName', key: '2'},
    {title: 'Sınıfı', dataIndex: 'grade', key: '3'},
    {title: 'Numarası', dataIndex: 'idNumber', key: '4'},
    {title: 'Email', dataIndex: 'email', key: '5'},
    {title: 'Universite', key: '0', render: (el) => universities?.find?.((i) => i._id === el.universityId)?.name},
    {title: 'Fakülte', key: '0', render: (el) => departments?.find?.((i) => i._id === el.departmentId)?.name},
    {title: 'Bölüm', key: '0', render: (el) => commissions?.find?.((i) => i._id === el.commissionId)?.name},
    {
      title: 'Sil',
      key: '0',
      render: (el) => (
        <div>
          <Button
            color="red"
            onClick={() => {
              setShowModal(true);
              setSelectedStudent(el._id);
              setSelectedStudentName(el.firstName + ' ' + (el.lastName ? el.lastName : ''));
            }}
          >
            Sil
          </Button>
        </div>
      ),
    },

    // {title: 'Action', key: 'operation', fixed: 'right', width: 100, render: () => <Link to="/main" />},
  ];

  if (user.role === 'teacher') {
    const approveColumn = {
      title: 'Onay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <Checkbox
          disabled={loading}
          checked={el.approved}
          onChange={async (e, {checked}) => {
            setLoading(true);
            el.approved = checked;
            setStudents([...students]);
            await axiosClient.put(
              apiURL.user,
              {_id: el._id, approved: checked},
              {
                headers: {
                  Authorization: 'ARGA ' + localStorage.getItem('token'),
                },
              }
            );
            setLoading(false);
          }}
          toggle
        />
      ),
      // render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    };
    columns.push(approveColumn);
    adminColumns.push(approveColumn);
  }

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      name: `Edrward ${i}`,
      age: 32,
      address: `London Park no. ${i}`,
    });
  }

  const getUsers = async () => {
    const {data} = await axiosClient.get(apiURL.users, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setStudents(data.filter((el) => el.role === 'student'));
    }
  };

  const getUniversities = async () => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setUniversities(data);
  };
  const getCommissions = async () => {
    const {data} = await axiosClient.get(apiURL.commission, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setCommissions(data);
  };
  const getDepartments = async () => {
    const {data} = await axiosClient.get(apiURL.department, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setDepartments(data);
  };

  const deleteStudent = async () => {
    console.log('selectedStudent', selectedStudent);
    await axiosClient.delete(apiURL.user + '?_id=' + selectedStudent, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
  };

  useEffect(() => {
    if (user.isMaster || user.isAdmin) {
      getUniversities();
      getDepartments();
      getCommissions();
    }
    getUsers();
  }, []);

  return (
    <div className="users_container">
      <Input
        placeholder="Filter"
        suffix={
          <Tooltip title="Click to filter">
            <FilterOutlined style={{color: 'rgba(0,0,0,.45)'}} />
          </Tooltip>
        }
      />
      <Table columns={user.isMaster || user.isAdmin ? adminColumns : columns} dataSource={students} scroll={{x: 800, y: 300}} />
      <Modal
        title="Kullanıcı Siliniyor"
        visible={showModal}
        onOk={() => {
          setShowModal(false);
          deleteStudent();
          setStudents(students.filter((el) => el._id !== selectedStudent));
        }}
        onCancel={() => setShowModal(false)}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>{selectedStudentName + ' silmek istiyor musunuz (geri çevrilemez)'}</p>
      </Modal>
    </div>
  );
}
