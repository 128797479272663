import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import LottieAnimation from 'ui/components/lottie';

import online_education from 'resources/images/online_educate.jpg';
import design from 'resources/images/design.jpg';
import graduate from 'resources/images/graduate3.jpeg';
import groupWorking from 'animation/group-working.json';
import styles from './about.module.scss';
import {LanguageContext} from 'context';
import {translations} from 'resources';

export default function About() {
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  return (
    <section id="section_about" className={styles.section_about_container}>
      <Fade bottom>
        <div className={styles.section_about_content_container}>
          <h2 className={styles.about_title}>unicDEF</h2>
          <div className={styles.paragraph_container}>
            <p className={styles.about_text_2}>{translations[appLanguage]['description1']}</p>
            <img className={styles.paragraph_image} src={online_education} alt="online_education" />
          </div>

          <div className={styles.paragraph_container}>
            <img className={styles.paragraph_image2} src={graduate} alt="online_education" />
            <div>
              <p className={styles.about_text}>{translations[appLanguage]['description2']}</p>
              {/* <p className={styles.about_text}>
                Çoğunlukla her birim özellikle mezun durumundaki öğrencilerin de mağdur olmaması amacıyla kendi içinde yaz dönemi staj eksikliklerini
                "proje, ödev veya dosya hazırlığı" ile telafi edebilmesine yönelik kararlar almaya başlamıştır. Ancak stajda kazanılması gereken temel
                beceriler düşünüldüğünde; bu süreci gerçekçi bir zeminde, dijital çeşitliliğin fırsatlarından yararlanarak mümkün olduğunca katılımcı
                bir üslupla sürdürmek amacıyla alternatif uzaktan bir eğitim modeline ihtiyacı da beraberinde getirmiştir. Bu durum özellikle farklı
                staj programlarının öğretileri ve öğrencilere kazanımlarının da dikkate alındığı dijital bir platformda sunulan dinamik bir modelin
                gerekliliğini öne çıkarmaktadır.
              </p> */}
            </div>
          </div>

          {/* <div style={{paddingTop: '60px'}} className={styles.paragraph_container}>
            <div>
              <p className={styles.about_text}>
                Yaşanan bu gelişmeler ışığında proje önerisi; öğrencilerin kazandıkları deneyim ve yükseköğretimin en kritik tamamlayıcılarından biri
                olarak kabul edilen; mezuniyet esasları kapsamında zorunluluğu bulunan staj programları, pandemi sürecinde devam edilebilmesi amacıyla
                dijital olanakları avantaja çevirerek verimliliği artıracak online bir model önerisi sunmaktadır. Böylece geliştirilen model ile içten
                ve dıştan girilecek staj talepleri ile öncelikle sektörün küçülmesinden dolayı staj yapamayan öğrencinin mağduriyeti giderilmektedir.
                Ayrıca staj sürecinde öğrencinin verdiği emek ile bu dönemde sosyal sorumluluk projelerine, Covid-19 kaynaklı planlama projelerine,
                üniversitedeki araştırma projelerine; destek sağlanmaktadır.
              </p>
              <p className={styles.about_text}>
                Proje ile ortaya konulan konunun özgün değeri ise; Covid-19 sürecinde ve sonrasında zaman ve mekan kısıtlamalarını ortadan kaldırarak;
                çok yönlü bir yaklaşımla süreç nedeniyle sosyal sorumluluk çatısı altında farklı faaliyetleri sürece dahil edebilmesi; tüm sürecin
                dijital ortamda kaydedilerek geri dönüşlerin ve süreç yönetiminin kolaylığı gibi avantajları sayesinde, MİTEDİS (Mimarlık ve Tasarım
                Eğitimi Dijital Staj) programı ile staj olanağı sunulması ve öğrenci kazanımına yönelik verim ve kalitenin artırılmasına bir çözüm
                önerisi sunmaktadır.
              </p>
            </div>
            <img className={styles.paragraph_image} src={design} alt="online_education" />
          </div> */}
          {/* <Link to="/signup" className={styles.about_btn}>
            Hemen Başlayın
          </Link> */}
        </div>
      </Fade>
    </section>
  );
}
