import React, {useContext, useEffect, useState} from 'react';
import {Icon, Select, Input, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './gain-edit-page.module.css';

export default function GainEditPage(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [point, setPoint] = useState(1);
  const [code, setCode] = useState('');
  const [gainType, setGainType] = useState('');
  const [gainSubType, setGainSubType] = useState('');
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, settings_card_container, settings_header, settings_row, settings_row_label} = styles;

  let pageSettings = parsePageSettings(props.match.params.id);
  const gainTypeOptions = [
    {value: 'Teknik', key: '0 ', text: 'Teknik'},
    {value: 'Aktarılabilir', key: '1 ', text: 'Aktarılabilir'},
  ];

  const teknikOptions = [
    {value: 'Teknoloji / Program Kullanma', key: '0 ', text: 'Teknoloji / Program Kullanma'},
    {value: 'Sunum Teknikleri', key: '1 ', text: 'Sunum Teknikleri'},
    {value: 'Tasarım ve Çizim', key: '2 ', text: 'Tasarım ve Çizim'},
    {value: 'Uygulama Bilgisi', key: '3 ', text: 'Uygulama Bilgisi'},
  ];

  const aktarilabilirOptions = [
    {value: 'Öz yönetim Becerisi', key: '0 ', text: 'Öz yönetim Becerisi'},
    {value: 'Düşünsel Beceri', key: '1 ', text: 'Düşünsel Beceri'},
    {value: 'Takim Çalışması', key: '2 ', text: 'Takim Çalışması'},
    {value: 'İletişim Becerileri', key: '3 ', text: 'İletişim Becerileri'},
    {value: 'Mesleki Sorgulama Becerileri', key: '4 ', text: 'Mesleki Sorgulama Becerileri'},
  ];

  const getGain = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setDescription(data.description);
      setPoint(data.point);
      setCode(data.code);
      set_id(data._id);
      setGainType(data.gainType);
      setGainSubType(data.gainSubType);
    }
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.gain,
        {_id, description, code, point, gainSubType, gainType},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.gain,
        {description, code, point, gainSubType, gainType},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/gains');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getGain(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Beceri:'}</div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Beceri Tipi : '}</div>
          <Select Dropdown value={gainType} onChange={(e, {value}) => setGainType(value)} className={styles.selectInput} options={gainTypeOptions} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Beceri Alt Tipi : '}</div>
          <Select
            Dropdown
            value={gainSubType}
            onChange={(e, {value}) => setGainSubType(value)}
            className={styles.selectInput}
            options={gainType === 'Teknik' ? teknikOptions : aktarilabilirOptions}
          />
        </div>
        {/* <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Adı'} />
        </div> */}
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Açıklaması : '}</div>
          <Input value={description} onChange={(e) => setDescription(e.target.value)} placeholder={'Beceri Açıklaması'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kodu : '}</div>
          <Input value={code} onChange={(e) => setCode(e.target.value)} placeholder={'Beceri Kodu'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Puanı : '}</div>
          <Input value={point} onChange={(e) => setPoint(e.target.value)} placeholder={'Beceri Puanı'} />
        </div>
        <div>
          <Button onClick={() => handleClick()}>{_id ? 'Güncelle' : 'Kaydet'}</Button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
