import React, {useEffect, useState, useContext} from 'react';
import {Table, Tabs, Tooltip} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './printReport.css';
import {Button, Input} from 'semantic-ui-react';
import {parsePageSettings} from 'helpers/page';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip as ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import {PolarArea, Radar, Bar} from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  ChartToolTip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement
);

const levels = [
  {
    maxPoint: 5,
    color: '#FED5D5',
    risk: 'ÇOK DÜŞÜK',
    result: 'ÖNEMSİZ RİSK',
    action: 'Belirlenen riskler kabul edilebilir düzeydedir ve kontrol önlemleri almaya gerek duyulmayabilir.',
  },
  {
    maxPoint: 10,
    color: '#FFBDBC',
    risk: 'DÜŞÜK',
    result: 'KATLANILABİLİR RİSK',
    action:
      'Belirlenen riskler katlanılabilir düzeydedir.  Mevcut ve gerekli kontroller yapılırken ilave önlemlere gerek duyulmayabilir.',
  },
  {
    maxPoint: 15,
    color: '#FF7D7D',
    risk: 'ORTA',
    result: 'ORTA DÜZEY RİSK',
    action:
      'Belirlenen riskleri kabul edilebilir düzeye düşürmek için gerekli kontroller yapılmalı ve ek önlemler alınmalıdır. Bu önlemler zaman alabilir.',
  },
  {
    maxPoint: 20,
    risk: 'YÜKSEK',
    color: '#C91A30',
    result: ' ÖNEMLİ RİSK ',
    action:
      'Belirlenen riskler kabul edilebilir düzeye getirilene kadar tasarıma derhal müdahale edilmeli, gerekli kontroller sıklıkla yapılmalı, ek önlemler alınmalı ve kabul edilebilir seviyeye gelene kadar risk azaltma önlemleri devam etmelidir.',
  },
  {
    maxPoint: 25,
    risk: 'ÇOK YÜKSEK',
    color: '#C91A30',
    result: 'KATLANILMAZ RİSK',
    action:
      'Belirlenen risk katlanılamaz düzeydedir ve tasarıma derhal müdahale edilmelidir. Risk seviyesi azaltılıncaya ve kabul edilebilir seviyeye gelinceye kadar gerekli kontroller çok sık yapılmalı, ek önlemler alınmalı ve gerekirse değiştirilerek yeniden tasarlanmalıdır.',
  },
];

export default function PrintReport(props) {
  const [categories, setCategories] = useState([]);
  const [homework, setHomework] = useState();
  const [studentNote, setStudentNote] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [cycle, setCycle] = useState();
  const [cycleId, setCycleId] = useState();
  const [homeworkCategories, setHomeworkCategories] = useState([]);
  const [level1Categories, setLevel1Categories] = useState([]);
  const [selectedLevel1Category, setSelectedLevel1Category] = useState('');
  const [categoryAverages, setCategoryAverages] = useState([]);
  const [notes, setNotes] = useState([]);
  const [reportFiles, setReportFiles] = useState([]);

  const [level2Categories, setLevel2Categories] = useState([]);
  const [selectedLevel2Category, setSelectedLevel2Category] = useState('');
  const [level3Categories, setLevel3Categories] = useState([]);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState('');
  const [level4Categories, setLevel4Categories] = useState([]);
  const [selectedLevel4Category, setSelectedLevel4Category] = useState('');
  const [selectedNote, setSelectedNote] = useState('');

  let pageSettings = parsePageSettings(props.match.params.id);

  const tableData = [];

  const level1TabItems = level1Categories.map((el) => ({label: el.name, key: el._id}));
  const level2TabItems = level2Categories.map((el) => ({label: el.name, key: el._id}));

  const level3TabItems = level3Categories.map((el) => ({label: el.name, key: el._id}));

  const level4TabItems = level4Categories.map((el) => ({
    label: <div style={{whiteSpace: 'pre-wrap', textAlign: 'initial'}}>{el.name}</div>,
    key: el._id,
  }));

  const {
    state: {user, isSigned},
  } = useContext(UserContext);

  useEffect(() => {
    if (categories.length < 1) return;
    const level1Categories = categories.filter((el) => !el.parentId).sort((a, b) => a.priority - b.priority);
    setLevel1Categories(level1Categories);
    // console.log('level1Categories', level1Categories, categories);
    if (level1Categories.length > 0) {
      setSelectedLevel1Category(level1Categories[0]._id);
    }
  }, [categories]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel1Category) return;
    const level2Categories = categories
      .filter((el) => el.parentId === selectedLevel1Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel2Categories(level2Categories);
    if (level2Categories.length > 0) {
      setSelectedLevel2Category(level2Categories[0]._id);
    }
  }, [categories, selectedLevel1Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel2Category) return;
    const level3Categories = categories
      .filter((el) => el.parentId === selectedLevel2Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel3Categories(level3Categories);
    if (level3Categories.length > 0) {
      setSelectedLevel3Category(level3Categories[0]._id);
    }
  }, [categories, selectedLevel2Category]);

  useEffect(() => {
    if (categories.length < 1 || !selectedLevel3Category) return;
    const level4Categories = categories
      .filter((el) => el.parentId === selectedLevel3Category)
      .sort((a, b) => a.priority - b.priority);

    setLevel4Categories(level4Categories);
    if (level4Categories.length > 0) {
      setSelectedLevel4Category(level4Categories[0]._id);
    }
  }, [categories, selectedLevel3Category]);

  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };
  const setHomeworkScore = async () => {
    const {data} = await axiosClient.post(
      apiURL.homeworkScore,
      {_id: pageSettings.homeworkId, categories: homeworkCategories},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    if (Array.isArray(data)) {
      setCategories(data);
    }
  };

  const getHomework = async () => {
    const {data} = await axiosClient.get(apiURL.homeworkFromId + '?_id=' + pageSettings.homeworkId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?.[0]) {
      setHomework(data[0]);
    }
  };
  const getCycle = async () => {
    const {data} = await axiosClient.get(apiURL.cycle + '?_id=' + cycleId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data) {
      setCycle(data);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    console.log(
      'homeworkCategories',
      homeworkCategories
        .filter((el) => el.volume > 0 && (el.frequency > 0 || el.dangerLevel > 0))
        .map((el) => categories.find((el2) => el2._id === el.categoryId)?.name)
    );
    if (homeworkCategories.length > 0) {
      const categoryAverages = [];
      const calcAverageFirst = () => {
        categories.forEach((el) => {
          const frequencies = [];
          const dangerLevels = [];
          const volumes = [];
          const subCategories = homeworkCategories.filter(
            (el2) => el._id === categories.find((el3) => el3._id === el2.categoryId)?.parentId
          );
          let total = 0;
          let maxVolume = 0,
            maxFrequency = 0,
            maxDangerLevel = 0;
          const average = Math.round(
            subCategories.reduce((a, b) => {
              const volume = categories.find((el2) => el2._id === b.categoryId)?.volume || 0;

              const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
              if (score) total++;
              if (b?.frequency) {
                frequencies.push(b?.frequency);
                if (maxFrequency < b?.frequency) maxFrequency = b?.frequency;
              } else if (b?.maxFrequency) {
                if (maxFrequency < b?.maxFrequency) maxFrequency = b?.maxFrequency;
              }
              if (b?.dangerLevel) {
                dangerLevels.push(b?.dangerLevel);
                if (maxDangerLevel < b?.dangerLevel) maxDangerLevel = b?.dangerLevel;
              } else if (b?.maxDangerLevel) {
                if (maxDangerLevel < b?.maxDangerLevel) maxDangerLevel = b?.maxDangerLevel;
              }
              if (volume) {
                volumes.push(volume);
                if (maxVolume < volume) maxVolume = volume;
              } else if (b?.maxVolume) {
                if (maxVolume < b?.maxVolume) maxVolume = b?.maxVolume;
              }
              return a + score;
            }, 0) / total
          );
          el.averageScore = average;
          if (frequencies.length > 0) {
            el.frequencyScore = Math.round(frequencies.reduce((a, b) => a + b, 0) / frequencies.length);
          }
          if (dangerLevels.length > 0) {
            el.dangerLevelScore = Math.round(dangerLevels.reduce((a, b) => a + b, 0) / dangerLevels.length);
          }
          if (volumes.length > 0) {
            el.volumeScore = Math.round(volumes.reduce((a, b) => a + b, 0) / volumes.length);
          }
          el.maxVolume = maxVolume;
          el.maxFrequency = maxFrequency;
          el.maxDangerLevel = maxDangerLevel;

          // console.log('average', average, el.name);
        });
      };
      const calcAverage = () => {
        categories.forEach((el) => {
          const subCategories = categories.filter((el2) => el._id === el2.parentId);
          let total = 0;
          const frequencies = [];
          const dangerLevels = [];
          const volumes = [];
          let maxVolume = 0,
            maxFrequency = 0,
            maxDangerLevel = 0;
          const average = Math.round(
            subCategories.reduce((a, b) => {
              const volume = categories.find((el2) => el2._id === b.categoryId)?.volume || 0;

              const score = b.averageScore || volume * Math.max(b?.frequency || 0, b?.dangerLevel || 0) || 0;
              if (b?.frequency) {
                frequencies.push(b?.frequency);
                if (maxFrequency < b?.frequency) maxFrequency = b?.frequency;
              } else if (b?.maxFrequency) {
                if (maxFrequency < b?.maxFrequency) maxFrequency = b?.maxFrequency;
              }
              if (b?.dangerLevel) {
                dangerLevels.push(b?.dangerLevel);
                if (maxDangerLevel < b?.dangerLevel) maxDangerLevel = b?.dangerLevel;
              } else if (b?.maxDangerLevel) {
                if (maxDangerLevel < b?.maxDangerLevel) maxDangerLevel = b?.maxDangerLevel;
              }
              if (volume) {
                volumes.push(volume);
                if (maxVolume < volume) maxVolume = volume;
              } else if (b?.maxVolume) {
                if (maxVolume < b?.maxVolume) maxVolume = b?.maxVolume;
              }
              if (score) total++;
              return a + score;
            }, 0) / total
          );
          if (average) {
            el.averageScore = average;
          }
          if (frequencies.length > 0) {
            el.frequencyScore = Math.round(frequencies.reduce((a, b) => a + b, 0) / frequencies.length);
          }
          if (dangerLevels.length > 0) {
            el.dangerLevelScore = Math.round(dangerLevels.reduce((a, b) => a + b, 0) / dangerLevels.length);
          }
          if (volumes.length > 0) {
            el.volumeScore = Math.round(volumes.reduce((a, b) => a + b, 0) / volumes.length);
          }
          if (!el.maxVolume) el.maxVolume = maxVolume;
          if (!el.maxFrequency) el.maxFrequency = maxFrequency;
          if (!el.maxDangerLevel) el.maxDangerLevel = maxDangerLevel;
          // console.log('average', average, el.name);
        });
      };
      calcAverageFirst();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      calcAverage();
      const averages = categories
        .filter((el) => el.averageScore)
        .map((el) => ({averageScore: el.averageScore, name: el.name, _id: el._id}));
      setCategoryAverages(averages);
      console.log('averrages', averages);
    }
  }, [homeworkCategories, categories]);

  useEffect(() => {
    if (cycle) getCycle();
  }, [cycleId]);
  useEffect(() => {
    if (homework) {
      setHomeworkCategories(homework.categories || []);
      setFile(homework.file || '');
      setNotes(homework.notes || []);
      setReportFiles(homework.reportFiles || []);
      setCycleId(homework.cycleId || '');
      setFileName(homework.fileName || '');
      setStudentNote(homework.studentNote || '');
      console.log('homeworkhomework', homework);
    }
  }, [homework]);
  useEffect(() => {
    if (pageSettings.homeworkId) getHomework();
  }, [pageSettings.homeworkId]);

  const handleClick = () => {
    setHomeworkScore();
    props.history.goBack();
  };
  // useEffect(() => {
  //   const leafCategories = categories.filter((el) => !categories.find((el2) => el2.parentId === el._id));
  //   leafCategories.forEach((el) => {
  //     const parents = [];
  //     const getParents = (category) => {
  //       if (!category) return;
  //       parents.push(category);
  //       if (category.parentId) getParents(categories.find((el2) => el2._id === category.parentId));
  //     };
  //     if (el.parentId) getParents(categories.find((el2) => el2._id === el.parentId));
  //     // el.parents = parents;
  //     el.isLeaf = true;
  //   });

  //   console.log(leafCategories);
  //   setLeafCategories(leafCategories);
  //   // axiosClient.put(apiURL.categoryAll, leafCategories, {
  //   //   headers: {
  //   //     Authorization: 'ARGA ' + localStorage.getItem('token'),
  //   //   },
  //   // });
  // }, [categories]);

  const renderChart = (chartItems, label = 'Ana Kategori Puanı') => {
    const options = {
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    const data = {
      labels: chartItems.map((el) => el.label),
      datasets: [
        {
          label: 'Proje Puanı',
          data: [5, 10],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Genel Ortalama',
          data: [7, 14],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '300px', height: '300px'}}>
          <PolarArea
            options={{
              scales: {},
            }}
            data={{
              labels: chartItems.map((el) => el.label),
              datasets: [
                {
                  label,
                  data: chartItems.map((el) => el.averageScore),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>
        {chartItems?.length > 2 ? (
          <div style={{width: '300px', height: '300px'}}>
            <Radar
              data={{
                labels: chartItems.map((el) => el.label),
                datasets: [
                  {
                    label: 'Proje Puanı',
                    data: chartItems.map((el) => el.averageScore),
                    backgroundColor: 'rgba(29,226,180,0.2)',
                    borderColor: 'rgba(29,226,180,1)',
                    borderWidth: 1,
                  },
                  {
                    label: 'Genel Ortalama',
                    data: chartItems.map((el) => el.averageScore).map((el) => Math.round(el + Math.random() * 4 - 2)),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        ) : (
          <div style={{width: '300px', height: '300px'}}>
            <Bar options={options} data={data} />
          </div>
        )}
      </div>
    );
  };

  const renderResult = (categoryId, withNote, parentLevel) => {
    const category = categories.find((el) => el._id === categoryId);
    const homeworkCategory = homeworkCategories.find((el) => el.categoryId === categoryId);
    const level = levels.find(
      (el) => el.maxPoint >= categoryAverages?.find((el2) => el2._id === categoryId)?.averageScore
    );
    const averageScore = categoryAverages?.find((el2) => el2._id === categoryId)?.averageScore;
    if (parentLevel === 3)
      tableData.push({
        key: category.name,
        name: category.name,
        averageScore: averageScore,
      });

    if (!level) return;
    return (
      <div style={{display: 'flex'}}>
        <div>
          <div style={{margin: '5px'}}>
            <div style={{fontWeight: 600, width: 'fit-content'}}>RİSK Puanı</div>
            <div style={{backgroundColor: level.color}}>{averageScore}</div>
          </div>
          <div style={{margin: '5px'}}>
            <div style={{fontWeight: 600, width: 'fit-content'}}>RİSK SEVİYESİ</div>
            <div style={{backgroundColor: level.color}}>{level.risk}</div>
          </div>
          <div style={{margin: '5px'}}>
            <div style={{fontWeight: 600, width: 'fit-content'}}>SONUÇ</div>
            <div style={{backgroundColor: level.color}}>{level?.result}</div>
          </div>
        </div>

        <div style={{margin: '5px'}}>
          <div style={{fontWeight: 600}}>EYLEM</div>
          <div style={{width: '400px'}}>{level?.action}</div>
          {category?.frequencyMessages?.[category?.maxFrequency - 1] && (
            <>
              <div>Alınacak Sıklık Önlemi:</div>
              <div style={{width: '400px'}}>{category?.frequencyMessages?.[category?.maxFrequency - 1]}</div>
            </>
          )}
          {category?.dangerLevelMessages?.[category?.maxDangerLevel - 1] && (
            <>
              <div>Alınacak Konum Önlemi:</div>
              <div style={{width: '400px'}}>{category?.dangerLevelMessages?.[category?.maxDangerLevel - 1]}</div>
            </>
          )}
          {category?.volumeMessages?.[category?.maxVolume - 1] && (
            <>
              <div>Alınacak Şiddet Önlemi:</div>
              <div style={{width: '400px'}}>{category?.volumeMessages?.[category?.maxVolume - 1]}</div>
            </>
          )}
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexDirection: 'column',
            }}
          >
            {reportFiles
              .filter((el) => el.includes(categoryId))
              .map((el) => (
                <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                  <a href={el} download={el}>
                    <img style={{width: '400px'}} src={el} />
                  </a>
                </div>
              ))}
          </div>
          {withNote && notes.find((el) => el.categoryId === categoryId)?.note && (
            <div style={{margin: '5px', fontWeight: 500, fontSize: '20px'}}>
              <div style={{fontWeight: 600}}>Ek Not: </div>
              <div style={{width: '400px'}}>{notes.find((el) => el.categoryId === categoryId)?.note}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCategory = () => {
    const level1Categories = categories.filter((el) => !el.parentId).sort((a, b) => a.priority - b.priority);
    return (
      <div style={{margin: '5px'}}>
        {level1Categories.map((level1Category) => {
          const level2Categories = categories
            .filter((el) => el.parentId === level1Category._id)
            .sort((a, b) => a.priority - b.priority);
          const level2ChartItems = level2Categories
            .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
            .map((el) => ({
              label: el.name,
              key: el._id,
              averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
            }));

          return (
            <>
              <div style={{fontSize: '36px', fontWeight: 600}}>{level1Category.name}</div>
              <div style={{width: '100%', margin: '5px'}}>
                {renderChart(level2ChartItems, 'Ana Kategori Risk Puanı')}
              </div>
              {level2Categories.map((level2Category, index1) => {
                const level3Categories = categories
                  .filter((el) => el.parentId === level2Category._id)
                  .sort((a, b) => a.priority - b.priority);
                const level3ChartItems = level3Categories
                  .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
                  .map((el) => ({
                    label: el.name,
                    key: el._id,
                    averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
                  }));
                return (
                  <>
                    <div style={{fontSize: '30px', fontWeight: 600}}>{index1 + 1 + '. ' + level2Category.name}</div>
                    <div style={{width: '100%', margin: '8px'}}>
                      <div>
                        <div> {renderResult(level2Category._id, false, 2)}</div>
                        <div>{renderChart(level3ChartItems, 'Modül Risk Puanı')}</div>
                      </div>
                    </div>
                    {level3Categories.map((level3Category, index2) => {
                      const level4Categories = categories
                        .filter((el) => el.parentId === level3Category._id)
                        .sort((a, b) => a.priority - b.priority);

                      const level4ChartItems = level4Categories
                        .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
                        .map((el) => ({
                          label: el.name,
                          key: el._id,
                          averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
                        }));

                      return (
                        <>
                          <div style={{fontSize: '26px', fontWeight: 600}}>
                            {index1 + 1 + '.' + (index2 + 1) + '. ' + level3Category.name}
                          </div>
                          <div>
                            <div> {renderResult(level3Category._id, false, 3)}</div>
                            <div>{renderChart(level4ChartItems, 'Alt Kategori Risk Puanı')}</div>
                          </div>
                          {level4Categories.map((level4Category, index3) => {
                            const level5Categories = categories
                              .filter((el) => el.parentId === level4Category._id)
                              .sort((a, b) => a.priority - b.priority);

                            const level5ChartItems = level5Categories
                              .filter((el) => categoryAverages.find((el2) => el2._id === el._id)?.averageScore)
                              .map((el) => ({
                                label: el.name,
                                key: el._id,
                                averageScore: categoryAverages.find((el2) => el2._id === el._id)?.averageScore,
                              }));

                            return (
                              <>
                                <div style={{fontSize: '22px', fontWeight: 600}}>
                                  {index1 + 1 + '.' + (index2 + 1) + '.' + (index3 + 1) + '. ' + level4Category.name}
                                </div>
                                <div>
                                  <div> {renderResult(level4Category._id, false, 4)}</div>
                                  <div>{renderChart(level5ChartItems, 'Alt Kategori Risk Puanı')}</div>
                                </div>
                                <div style={{width: '700px'}}>
                                  {level5Categories.map((level5Category, index4) => {
                                    return (
                                      <>
                                        <div style={{fontSize: '18px', fontWeight: 600}}>
                                          {index1 +
                                            1 +
                                            '.' +
                                            (index2 + 1) +
                                            '.' +
                                            (index3 + 1) +
                                            '.' +
                                            (index4 + 1) +
                                            '. ' +
                                            level5Category.name}
                                        </div>
                                        <div>
                                          <div> {renderResult(level5Category._id, true, 5)}</div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                                <div>----------------------------------------------------</div>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div
      // className="users_container"
      style={{
        // position: 'absolute',
        // top: '0px',
        // left: '0px',
        // zIndex: 100,
        width: '100vw',
        height: '100vh',
        background: 'white',
        overflow: 'unset',
      }}
    >
      <div style={{padding: '32px', display: 'flex', justifyContent: 'flex-end'}}>
        <Button primary onClick={() => window.print()}>
          Print
        </Button>
      </div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
        <div style={{margin: '10px', width: '200px'}}>
          <div style={{fontWeight: 600}}>Ödev Dosyası</div>
          {file ? (
            <a href={file} download={file}>
              {fileName}
            </a>
          ) : (
            <div>Ödev Yüklenmemiş!!!</div>
          )}
        </div>
        <div>
          <div style={{margin: '10px', width: '200px'}}>
            <div style={{fontWeight: 600}}>Öğrenci Notu</div>
            <div>{studentNote || '--'}</div>
          </div>
        </div>
        {renderResult(categoryAverages?.[0]?._id)}
      </div>

      {renderCategory()}
      <div style={{margin: 8, fontWeight: 600, fontSize: 24}}>Özet Tablo</div>
      <Table
        style={{width: '600px'}}
        columns={[
          {
            title: 'Kategori',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Puan',
            dataIndex: 'averageScore',
            key: 'averageScore',
          },
        ]}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  );
}
