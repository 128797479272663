import React, {useEffect, useState} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import './university-page.module.css';

export default function GainsPage(props) {
  const [universities, setUniversities] = useState([]);

  const columns = [
    {title: 'İsmi', dataIndex: 'name', key: '0'},
    {title: 'Fakülteler', key: '1', render: (el) => el?.departments?.length},
    {title: 'Komisyonlar', key: '2', render: (el) => el?.commissions?.length},
    {title: 'Kullanıcılar', key: '3', render: (el) => el?.users?.length},
    {
      title: 'Düzenle',
      key: '4',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/universityEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const getUniversities = async (_id) => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setUniversities(data);
    }
  };

  useEffect(() => {
    getUniversities();
  }, []);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={universities} scroll={{x: 800, y: 300}} />
      <BottomMenu {...props} url={'/universityEdit'} />
    </div>
  );
}
