import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, Checkbox} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './task-edit-page.module.css';

export default function GainEditPage(props) {
  const [_id, set_id] = useState('');
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [steps, setSteps] = useState(['']);
  const [description, setDescription] = useState('');
  const [taskTimeInDays, setTaskTimeInDays] = useState(0);
  const [taskCategoryId, setTaskCategoryId] = useState('');
  const [options, setOptions] = useState([]);
  const [taskCategories, setTaskCategries] = useState([]);
  const [commissionSettings, setCommissionSettings] = useState({});
  const [gains, setGains] = useState([]);
  const [myTaskCategory, setMyTaskCategory] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [changed, setChanged] = useState(false);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, gain_container, settings_card_container, settings_header, settings_row, settings_row_label} =
    styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getTask = async (_id) => {
    const {data} = await axiosClient.get(apiURL.task + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setDescription(data.description || '');
      setCommissionSettings(data.commissionSettings || {});
      setSteps(data.steps?.length > 0 ? data.steps : ['']);
      set_id(data._id);
      setTaskCategoryId(data.taskCategoryId);
      setTaskTimeInDays(data.taskTimeInDays || 1);
    }
  };

  const getTaskCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskCategries(data);
      setOptions(data.map((el) => ({text: el.name, value: el._id, key: el._id})));
    }
  };

  const getGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setGains(data);
  };

  const handleClick = async () => {
    if (_id) {
      await axiosClient.put(
        apiURL.task,
        {_id, name, steps, description, taskCategoryId, commissionSettings, taskTimeInDays},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.task,
        {name, steps, description, taskCategoryId, commissionSettings, taskTimeInDays},
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/tasks');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getTask(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getTaskCategories();
    getGains();
  }, []);

  useEffect(() => {
    setMyTaskCategory(taskCategories.find((el) => el._id === taskCategoryId) || {});
  }, [taskCategories, taskCategoryId]);

  const renderSteps = () => {
    return steps.map((el, i) => {
      if (user.role !== 'company' && i === steps.length - 1) return;
      return (
        <div className={styles.input_container}>
          <div className={styles.step_label}>{user.role !== 'company' ? '- ' + steps[i] : 'Adım ' + (i + 1)}</div>
          {user.role === 'company' && (
            <Input
              value={steps[i]}
              onChange={(e) => {
                if (user.role === 'company') {
                  let newSteps = [...steps];
                  newSteps[i] = e.target.value;
                  newSteps = newSteps.filter((el) => el);
                  if (newSteps.length < 1) setSteps(['']);
                  else if (newSteps[newSteps.length - 1] !== '') setSteps([...newSteps, '']);
                  else setSteps(newSteps);
                }
                setChanged(true);
              }}
              placeholder={'Görev Adımı'}
            />
          )}
        </div>
      );
    });
  };

  const renderGains = () => {
    return myTaskCategory?.gains?.map((el, i) => {
      return (
        <div className={gain_container}>
          <div>
            <div> {gains?.find((gain) => gain._id === el)?.code}</div>
            {showDetails && <div> {gains?.find((gain) => gain._id === el)?.description}</div>}
          </div>

          <div>
            <Checkbox
              checked={commissionSettings?.[user.commissionId]?.unGains?.indexOf?.(el) >= 0 ? false : true}
              onChange={(e, {checked}) => {
                if (!commissionSettings[user.commissionId]) commissionSettings[user.commissionId] = {};
                if (!commissionSettings[user.commissionId].unGains) commissionSettings[user.commissionId].unGains = [];
                if (!checked) commissionSettings[user.commissionId].unGains.push(el);
                else commissionSettings[user.commissionId].unGains = commissionSettings[user.commissionId].unGains.filter((item) => item !== el);
                console.log('commissionSettings[user.commissionId].unGains', commissionSettings[user.commissionId].unGains);
                setCommissionSettings({...commissionSettings});
                setChanged(true);
              }}
              toggle
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Görev İlanı'}</div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Adı : ' + (user.role !== 'company' ? name : '')}</div>
          {user.role === 'company' && <Input value={name} onChange={(e) => setName(e.target.value) || setChanged(true)} placeholder={'Görev Adı'} />}
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>
            {'Görevin Kategorisi : ' + (user.role !== 'company' ? options.find((el) => el.value === taskCategoryId)?.text : '')}
          </div>
          {user.role === 'company' && (
            <Dropdown
              fluid
              selection={true}
              search={true}
              options={options}
              value={taskCategoryId}
              onChange={(e, {value}) => setTaskCategoryId(value) || setChanged(true)}
            />
          )}
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Süresi(gün) : ' + (user.role !== 'company' ? taskTimeInDays : '')}</div>
          {user.role === 'company' && (
            <Input
              type="number"
              min="1"
              max="200"
              value={taskTimeInDays}
              onChange={(e) => setTaskTimeInDays(e.target.value) || setChanged(true)}
              placeholder={'Görev Süresi(gün)'}
            />
          )}
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Açıklaması : ' + (user.role !== 'company' ? description : '')}</div>
          {user.role === 'company' && (
            <form className={'ui form ' + styles.connection_input}>
              <textarea
                value={description}
                onChange={({target: {value}}) => user.role === 'company' && (setDescription(value) || setChanged(true))}
                placeholder={'Görev Açıklması'}
              />
            </form>
          )}
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adımlar : '}</div>
          {renderSteps()}
        </div>
        {user.role === 'teacher' && (
          <div className={styles.toggle_container}>
            <div className={styles.input_label}>{'Görevi Onayla : '}</div>
            <Checkbox
              checked={commissionSettings?.[user.commissionId]?.selected}
              onChange={(e, {checked}) => {
                if (!commissionSettings[user.commissionId]) commissionSettings[user.commissionId] = {};
                commissionSettings[user.commissionId].selected = checked;
                setCommissionSettings({...commissionSettings});
                setChanged(true);
              }}
              toggle
            />
          </div>
        )}
        {user.role === 'teacher' && commissionSettings?.[user.commissionId]?.selected && (
          <div style={{marginBottom: '50px'}} className={styles.input_container}>
            <div className={styles.input_label}>{'Kazanımlar : '}</div>
            <Checkbox checked={showDetails} onChange={(e, {checked}) => setShowDetails(checked)} label="Kazanım Detaylarını Göster" />
            {renderGains()}
          </div>
        )}
        {changed && (
          <div className={styles.saveButton}>
            <Button color="blue" onClick={() => handleClick()}>
              {_id ? 'Güncelle' : 'Kaydet'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
