import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Input, Button, TextArea} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './categories-edit-page.module.css';
import {Checkbox, InputNumber} from 'antd';

export default function GainEditPage(props) {
  // const [name, setName] = useState('');
  const [name, setName] = useState('');
  const [priority, setPriority] = useState(1);
  const [volume, setVolume] = useState(0);
  const [isLeaf, setIsLeaf] = useState(false);
  const [frequencyDisabled, setFrequencyDisabled] = useState(false);
  const [dangerLevelDisabled, setDangerLevelDisabled] = useState(false);
  const [precautions, setPrecautions] = useState(['']);
  const [risks, setRisks] = useState(['']);
  const [dangers, setDangers] = useState(['']);
  const [categories, setCategories] = useState([]);
  const [note, setNote] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [frequencyMessages, setFrequencyMessages] = useState([]);
  const [dangerLevelMessages, setDangerLevelMessages] = useState([]);
  const [volumeMessages, setVolumeMessages] = useState([]);

  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {
    settings_container,
    account_settings_container,
    settings_card_container,
    settings_header,
    settings_row,
    settings_row_label,
  } = styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    setCategories(data);
  };

  const handleClick = async () => {
    const parentCategories = selectedCategories.filter((el) => el);
    if (pageSettings?._id) {
      await axiosClient.put(
        apiURL.category,
        {
          _id: pageSettings?._id,
          category: {
            name,
            precautions: precautions.filter((el) => el),
            dangers: dangers.filter((el) => el),
            risks: risks.filter((el) => el),
            isLeaf,
            frequencyMessages,
            dangerLevelMessages,
            volumeMessages,
            frequencyDisabled,
            dangerLevelDisabled,
            priority,
            volume,
            note,
            parentId: parentCategories[parentCategories.length - 1],
          },
        },
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    } else {
      await axiosClient.post(
        apiURL.category,
        {
          category: {
            name,
            precautions: precautions.filter((el) => el),
            dangers: dangers.filter((el) => el),
            risks: risks.filter((el) => el),
            isLeaf,
            frequencyMessages,
            dangerLevelMessages,
            volumeMessages,
            frequencyDisabled,
            dangerLevelDisabled,
            priority,
            volume,
            note,
            parentId: parentCategories[parentCategories.length - 1],
          },
        },
        {
          headers: {
            Authorization: 'ARGA ' + localStorage.getItem('token'),
          },
        }
      );
    }
    props.history.push('/categories');
  };

  useEffect(() => {
    if (pageSettings._id) {
      getCategories(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (pageSettings._id && categories?.length) {
      const currentCategory = categories.find((el) => el._id === pageSettings._id);
      if (currentCategory) {
        console.log('currentCategory', currentCategory);
        setRisks(currentCategory.risks || []);
        setDangers(currentCategory.dangers || []);
        setPrecautions(currentCategory.precautions || []);
        setName(currentCategory.name || '');
        setIsLeaf(currentCategory.isLeaf);
        setDangerLevelMessages(currentCategory.dangerLevelMessages || []);
        setVolumeMessages(currentCategory.volumeMessages || []);
        setFrequencyMessages(currentCategory.frequencyMessages || []);
        setFrequencyDisabled(currentCategory.frequencyDisabled);
        setDangerLevelDisabled(currentCategory.dangerLevelDisabled);
        setPriority(currentCategory.priority || 1);
        setVolume(currentCategory.volume || 0);
        setNote(currentCategory.note);
        const parents = [];
        const getParent = (category) => {
          if (category?.parentId) {
            parents.push(category.parentId);
            getParent(categories.find((el) => el._id === category.parentId));
          }
        };
        getParent(currentCategory);
        setSelectedCategories(parents.reverse());
      }
    }
  }, [categories, pageSettings._id]);

  const renderCategories = () => {
    const returnThis = [];
    let hasChild = !selectedCategories.length;
    if (!hasChild && categories.find((el) => el.parentId === selectedCategories[selectedCategories.length - 1]))
      hasChild = true;
    for (let i = 0; i < selectedCategories.length + (hasChild ? 1 : 0); i++) {
      let subCategories =
        i === 0
          ? categories.filter((el) => !el.parentId)
          : categories.filter((el) => el.parentId === selectedCategories[i - 1] && el._id !== pageSettings._id);
      subCategories = subCategories.filter((el) => !el.isLeaf);
      returnThis.push(
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Üst Kategori ' + (selectedCategories.length ? i + 1 : '')}</div>
          <Dropdown
            fluid
            selection={true}
            clearable
            // multiple={true}
            search={true}
            options={subCategories.map((el, i) => ({key: i, value: el._id, text: el.name}))}
            value={selectedCategories[i]}
            // placeholder="Kazanım Ekle"
            onChange={(e, {value}) => {
              setSelectedCategories([...selectedCategories.slice(0, i), value]);
            }}
            // onSearchChange={this.handleSearchChange}
          />
        </div>
      );
    }
    return returnThis;
  };

  useEffect(() => {
    if (precautions.at(-1) || !precautions.length) {
      setPrecautions([...precautions, '']);
    }
  }, [precautions]);

  useEffect(() => {
    if (dangers.at(-1) || !dangers.length) {
      setDangers([...dangers, '']);
    }
  }, [dangers]);

  useEffect(() => {
    if ((frequencyMessages.at(-1) || !frequencyMessages.length) && frequencyMessages.length < 5) {
      setFrequencyMessages([...frequencyMessages, '']);
    }
  }, [frequencyMessages]);

  useEffect(() => {
    if ((dangerLevelMessages.at(-1) || !dangerLevelMessages.length) && dangerLevelMessages.length < 5) {
      setDangerLevelMessages([...dangerLevelMessages, '']);
    }
  }, [dangerLevelMessages]);

  useEffect(() => {
    if ((volumeMessages.at(-1) || !volumeMessages.length) && volumeMessages.length < 5) {
      setVolumeMessages([...volumeMessages, '']);
    }
  }, [volumeMessages]);

  useEffect(() => {
    if (risks.at(-1) || !risks.length) {
      setRisks([...risks, '']);
    }
  }, [risks]);
  useEffect(() => {
    console.log('selectedCategoriesselectedCategories', selectedCategories);
  }, [selectedCategories]);

  const renderPrecautions = () => {
    const returnThis = [<div className={styles.input_label}>{'Alınacak Önlemler'}</div>];
    precautions.forEach((el, i) =>
      returnThis.push(
        <div className={styles.input_container}>
          <Input
            value={el}
            onChange={(e) => {
              const newData = [...precautions];
              newData[i] = e.target.value;
              setPrecautions(newData);
            }}
            placeholder={'Önlem giriniz'}
          />
        </div>
      )
    );
    return returnThis;
  };

  const renderDangers = () => {
    const returnThis = [<div className={styles.input_label}>{'Tehlikeler:'}</div>];
    dangers.forEach((el, i) =>
      returnThis.push(
        <div className={styles.input_container}>
          <Input
            value={el}
            onChange={(e) => {
              const newData = [...dangers];
              newData[i] = e.target.value;
              setDangers(newData);
            }}
            placeholder={'Tehlike giriniz'}
          />
        </div>
      )
    );
    return returnThis;
  };

  const renderRisks = () => {
    const returnThis = [<div className={styles.input_label}>{'Riskler'}</div>];
    risks.forEach((el, i) =>
      returnThis.push(
        <div className={styles.input_container}>
          <Input
            value={el}
            onChange={(e) => {
              const newData = [...risks];
              newData[i] = e.target.value;
              setRisks(newData);
            }}
            placeholder={'Risk Giriniz'}
          />
        </div>
      )
    );
    return returnThis;
  };

  const renderMessages = (name, values, setter) => {
    const returnThis = [<div className={styles.input_label}>{name}</div>];
    values.forEach((el, i) =>
      returnThis.push(
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div>{i + 1 + '. '}</div>
          <div className={styles.input_container}>
            <Input
              value={el}
              onChange={(e) => {
                const newData = [...values];
                newData[i] = e.target.value;
                setter(newData);
              }}
              placeholder={name + ' giriniz'}
            />
          </div>
        </div>
      )
    );
    return returnThis;
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Üst Kategoriler:'}</div>
        {/* <div className={styles.input_container}>
          <div className={styles.input_label}>{'Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Adı'} />
        </div> */}
        {!selectedCategories.length && (
          <div className={styles.input_container}>Üst kategori seçmezseniz bir ana kategori oluşturulur!</div>
        )}
        {renderCategories()}
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kategori Adı : '}</div>
          <Input value={name} onChange={(e) => setName(e.target.value)} placeholder={'Beceri Açıklaması'} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Açıklama : '}</div>
          <TextArea value={note} onChange={(e) => setNote(e.target.value)} placeholder={'Açıklama'} />
        </div>
        <div className={styles.input_container}>
          <Checkbox checked={isLeaf} onChange={(e) => setIsLeaf(e.target.checked)}>
            Bu bir uç kategoridir (uç kategorilerin altına yeni kategori eklenmez)
          </Checkbox>
        </div>
        {isLeaf && (
          <>
            <div className={styles.input_container}>
              <Checkbox checked={frequencyDisabled} onChange={(e) => setFrequencyDisabled(e.target.checked)}>
                Sıklık Pasif
              </Checkbox>
            </div>
            <div className={styles.input_container}>
              <Checkbox checked={dangerLevelDisabled} onChange={(e) => setDangerLevelDisabled(e.target.checked)}>
                Konum Pasif
              </Checkbox>
            </div>
          </>
        )}
        <div className={styles.input_container}>
          {renderMessages('Konum Önlemleri', dangerLevelMessages, setDangerLevelMessages)}
        </div>
        <div className={styles.input_container}>
          {renderMessages('Sıklık Önlemleri', frequencyMessages, setFrequencyMessages)}
        </div>
        <div className={styles.input_container}>
          {renderMessages('Şiddet Önlemleri', volumeMessages, setVolumeMessages)}
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kategori Sırası : '}</div>
          <InputNumber value={priority} onChange={(e) => setPriority(e)} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Şiddeti : '}</div>
          <InputNumber value={volume} onChange={(e) => setVolume(e)} />
        </div>
        {renderDangers()}
        {/* {renderDangers()} */}
        {renderRisks()}
        {renderPrecautions()}
        <div>
          <Button secondary onClick={() => props.history.goBack()}>
            İptal
          </Button>
          <Button primary disabled={!name} onClick={() => handleClick()}>
            {pageSettings?._id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
