import React, {useContext, useEffect, useState} from 'react';
import {Header, Dropdown, Button, Checkbox} from 'semantic-ui-react';
import {Table, Modal, Input} from 'antd';
import {Icon} from 'semantic-ui-react';

import {Link} from 'react-router-dom';

import {apiURL, axiosClient} from 'service';
import {LanguageContext, UserContext} from 'context';
import {parsePageSettings} from 'helpers/page';

import {translations} from 'resources';
import styles from './task-application-edit-page.module.css';

const {TextArea} = Input;

export default function GainEditPage(props) {
  const [_id, set_id] = useState('');
  const [started, setStarted] = useState(false);
  const [startedPrev, setStartedPrev] = useState(false);
  const [studentCompleted, setStudentCompleted] = useState(false);
  const [companyAccepted, setCompanyAccepted] = useState(false);
  const [companyDeclined, setCompanyDeclined] = useState(false);
  const [commissionDeclined, setCommissionDeclined] = useState(false);
  const [commissionAccepted, setCommissionAccepted] = useState(false);
  const [notes, setNotes] = useState([]);
  const [deletedGains, setDeletedGains] = useState({});
  const [commissionSettings, setCommissionSettings] = useState({});
  const [taskId, setTaskId] = useState('');
  const [taskApplication, setTaskApplication] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [files, setFiles] = useState([]);
  const [presentationFile, setPresentationFile] = useState('');
  const [studentNote, setStudentNote] = useState('');
  const [studentNoteCommission, setStudentNoteCommission] = useState('');
  const [studentNoteForCompany, setStudentNoteForCompany] = useState('');
  const [commissionNote, setCommissionNote] = useState('');

  // const [name, setName] = useState('');
  const [taskCategoryId, setTaskCategoryId] = useState('');
  const [description, setDescription] = useState('');
  const [finishDate, setFinishDate] = useState(Date.now());
  const [name, setName] = useState('');
  const [options, setOptions] = useState([]);
  const [taskCategories, setTaskCategries] = useState([]);
  const [gains, setGains] = useState([]);
  const [myTaskCategory, setMyTaskCategory] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [changed, setChanged] = useState(false);
  const [filePercent, setFilePercent] = useState(0);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned},
  } = useContext(UserContext);
  const {settings_container, account_settings_container, gain_container, settings_card_container, settings_header, settings_row, settings_row_label} =
    styles;

  let pageSettings = parsePageSettings(props.match.params.id);

  const getTask = async (taskId) => {
    const {data} = await axiosClient.get(apiURL.task + '?_id=' + taskId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setName(data.name);
      setDescription(data.description || '');
      setCommissionSettings(data.commissionSettings || {});
      setTaskCategoryId(data.taskCategoryId);
    }
  };
  const getTaskApplication = async (_id) => {
    const {data} = await axiosClient.get(apiURL.taskApplication + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data._id) {
      // setName(data.name);
      setTaskApplication(data);
      setStarted(!!data.started);
      setStartedPrev(!!data.started);
      setStudentCompleted(!!data.studentCompleted);
      setCompanyAccepted(!!data.companyAccepted);
      setCompanyDeclined(!!data.companyDeclined);
      setCommissionDeclined(!!data.commissionDeclined);
      setCommissionAccepted(!!data.commissionAccepted);
      setDeletedGains(data.deletedGains || {});
      setNotes(data.notes || []);
      setFiles(data.files || []);
      set_id(data._id);
      setTaskId(data.taskId);
      getTask(data.taskId);
      setPresentationFile(data.presentationFile || '');
      setFinishDate(data.finishDate || Date.now());
    }
  };

  const getTaskCategories = async (_id) => {
    const {data} = await axiosClient.get(apiURL.category, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setTaskCategries(data);
      setOptions(data.map((el) => ({text: el.name, value: el._id, key: el._id})));
    }
  };

  const getGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setGains(data);
  };

  const handleClick = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {_id, started, commissionDeclined, companyDeclined, commissionAccepted, companyAccepted, studentCompleted, notes, deletedGains},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    setTaskApplication({
      ...taskApplication,
      started,
      commissionDeclined,
      companyDeclined,
      commissionAccepted,
      companyAccepted,
      studentCompleted,
      notes,
      deletedGains,
    });

    // await axiosClient.put(
    //   apiURL.task,
    //   {_id: taskId},
    //   {
    //     headers: {
    //       Authorization: 'ARGA ' + localStorage.getItem('token'),
    //     },
    //   }
    // );
    setChanged(false);
  };

  const sendStudentCompleted = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {
        _id,
        studentCompleted: true,
        companyDeclined: false,
        commissionDeclined: false,
        commissionLeaderDeclined: false,
        studentLoadedFinalFile: false,
        studentNoteForCompany,
      },
      {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}}
    );
  };
  const sendStudentLoadedFinalFile = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {_id, studentLoadedFinalFile: true, studentNoteCommission, commissionDeclined: false, commissionLeaderDeclined: false},
      {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}}
    );
  };
  const sendCompanyAccepted = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {
        _id,
        companyAccepted: true,
        companyDeclined: false,
        companyNoteForStudent: studentNote,
        studentLoadedFinalFile: false,
        companyNoteForCommission: commissionNote,
        studentNoteCommission: studentNoteCommission,
      },
      {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}}
    );
  };
  const sendCompanyDeclined = async () => {
    await axiosClient.put(
      apiURL.taskApplication,
      {
        _id,
        companyDeclined: true,
        companyAccepted: false,
        studentCompleted: false,
        companyNoteForStudent: studentNote,
        studentLoadedFinalFile: false,
        companyNoteForCommission: commissionNote,
      },
      {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}}
    );
  };

  const sendCommissionAccepted = async () => {
    const postData = {_id, commissionAccepted: true, commissionDeclined: false};
    if (user.isCommissionLeader) {
      postData.commissionLeaderAccepted = true;
      postData.commissionLeaderNote = commissionNote;
    } else postData.commissionNote = commissionNote;
    await axiosClient.put(apiURL.taskApplication, postData, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
  };
  const sendCommissionDeclined = async () => {
    const postData = {
      _id,
      commissionAccepted: false,
      commissionDeclined: true,
      studentLoadedFinalFile: false,
    };
    if (user.isCommissionLeader) {
      postData.commissionLeaderDeclined = true;
      postData.commissionLeaderNote = commissionNote;
    } else postData.commissionNote = commissionNote;
    await axiosClient.put(apiURL.taskApplication, postData, {headers: {Authorization: 'ARGA ' + localStorage.getItem('token')}});
  };

  const renderGains = () => {
    return myTaskCategory?.gains
      ?.filter((el) => !(commissionSettings[taskApplication.commissionId]?.unGains?.indexOf?.(el) >= 0))
      ?.map((el, i) => {
        return (
          <div style={{height: '50%'}} className={gain_container}>
            <div>
              <div> {gains?.find((gain) => gain._id === el)?.code}</div>
              {showDetails && <div> {gains?.find((gain) => gain._id === el)?.description}</div>}
            </div>

            <div>
              <Checkbox
                checked={deletedGains?.indexOf?.(el) >= 0 ? false : true}
                onChange={(e, {checked}) => {
                  if (!checked) setDeletedGains([...deletedGains, el]);
                  else setDeletedGains(deletedGains.filter((el2) => el2 !== el));
                  setChanged(true);
                }}
                toggle
              />
            </div>
          </div>
        );
      });
  };

  useEffect(() => {
    if (pageSettings._id) {
      getTaskApplication(pageSettings._id);
    }
    console.log('pageSettings', pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSettings._id]);

  useEffect(() => {
    if (user.isSigned) {
      if (!(user.isAdmin || user.isMaster)) props.history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getTaskCategories();
    getGains();
  }, []);

  useEffect(() => {
    setMyTaskCategory(taskCategories.find((el) => el._id === taskCategoryId) || {});
  }, [taskCategories, taskCategoryId]);

  const deleteTaskApplication = async () => {
    await axiosClient.delete(apiURL.taskApplication + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
  };

  const fileUpload = async (file, replaceUrl = '', isFinalFile) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
      onUploadProgress: (ProgressEvent) => {
        console.log('progress :', (ProgressEvent.loaded / ProgressEvent.total) * 100);
        setFilePercent((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    };

    try {
      let response = await axiosClient.post(
        (isFinalFile ? apiURL.taskApplicationFinalFile : apiURL.taskApplicationFile) +
          '?_id=' +
          _id +
          (replaceUrl ? '&replaceUrl=' + replaceUrl : '') +
          '&name=' +
          file.name.substring(0, file.name.lastIndexOf('.')),
        formData,
        config
      );
      setFilePercent(0);
      console.log('res', response);
      return response.data;
    } catch (error) {
      setFilePercent(0);
    }
  };

  const onFiledelete = async (url) => {
    const fileUrls = await axiosClient.delete(apiURL.taskApplicationFile + '?_id=' + _id + '&url=' + url, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    console.log('fileUrls', fileUrls.data);
    if (Array.isArray(fileUrls.data)) setFiles(fileUrls.data);
  };

  const onFileChange = async (files, replaceUrl) => {
    const fileArray = [];
    console.log('files.length', files.length);
    if (!files?.length) return;
    for (let i = 0; i < files.length; i++) fileArray.push(files.item(i));
    console.log('files.length2', files.length);

    if (files?.[0]) {
      console.log('file size', files[0].size);

      var file = files[0];
      console.log('file', file);
      const fileUrls = await fileUpload(file, replaceUrl);
      if (Array.isArray(fileUrls)) setFiles(fileUrls);
      // setPhotoQuery(new Date());
    }
  };

  const onFinalFileChange = async (files, replaceUrl) => {
    const fileArray = [];
    console.log('files.length', files.length);
    if (!files?.length) return;
    for (let i = 0; i < files.length; i++) fileArray.push(files.item(i));
    console.log('files.length2', files.length);

    if (files?.[0]) {
      console.log('file size', files[0].size);

      var file = files[0];
      console.log('file', file);
      const resp = await fileUpload(file, replaceUrl, true);
      if (resp) setPresentationFile(resp);
      // setPhotoQuery(new Date());
    }
  };

  const generateStatus = (item) => {
    if (!item) return;
    if (item.commissionLeaderAccepted) return 'Tamamlandı';
    else if (item.studentCancelled) return 'Öğrenci İptal İstedi';
    else if (item.companyDeclined) return 'Kurum Tarafından Reddedildi';
    else if (item.commissionLeaderDeclined) return 'Komisyon Başkanı Tarafından Reddedildi';
    else if (item.commissionDeclined) return 'Komisyon Tarafından Reddedildi';
    else if (!item.started) return 'Kurum Başvuruyu Henüz Onaylamadı';
    else if (item.studentCompleted) {
      if (!item.companyAccepted) return 'Kurum Onayı Bekleniyor';
      else if (item.commissionAccepted && item.companyAccepted) return 'Komisyon Başkanı Onayı Bekleniyor';
      else if (item.companyAccepted && !item.studentLoadedFinalFile) return 'Komisyona Sunulması bekleniyor';
      else if (item.companyAccepted) return 'Komisyon Onayı Bekleniyor';
    } else return 'Devam Ediyor';
  };

  const renderFiles = () => {
    return files.map((url) => {
      const name = url.substring(url.lastIndexOf('______') + '______'.length);
      return (
        <div style={{display: 'flex'}}>
          <a style={{margin: '5px'}} href={url} download={name}>
            {name}
          </a>
          {user.role !== 'teacher' && (
            <label style={{cursor: 'pointer', margin: '5px', color: 'blue'}}>
              Değiştir
              <input type="file" onChange={({target: {files}}) => onFileChange(files, url)} />
            </label>
          )}

          {user.role !== 'teacher' && (
            <label style={{cursor: 'pointer', margin: '5px', color: 'red'}} onClick={() => onFiledelete(url)}>
              Sil
            </label>
          )}
        </div>
      );
    });
  };

  const renderFinalFile = () => {
    let name;
    console.log('presentationFile', presentationFile);
    let finalFileFixed = presentationFile;
    if (finalFileFixed) {
      name = finalFileFixed.substring(finalFileFixed.lastIndexOf('_final______') + '_final______'.length);
    }
    return (
      <div>
        <div style={{margin: '5px', fontWeight: '500'}}>Sunum Dosyası :</div>
        {finalFileFixed && (
          <div style={{display: 'flex'}}>
            <a style={{margin: '5px'}} href={finalFileFixed} download={name}>
              {name}
            </a>

            {user.role === 'student' && (
              <label style={{cursor: 'pointer', margin: '5px', color: 'blue'}}>
                Değiştir
                <input type="file" onChange={({target: {files}}) => onFinalFileChange(files, finalFileFixed)} />
              </label>
            )}
          </div>
        )}
        {filePercent > 0 && <div>{'Dosya Yükleniyor: %' + parseInt(filePercent)}</div>}

        {!finalFileFixed && user.role === 'student' && (
          <div style={{margin: '10px', fontWeight: '500'}}>
            <label style={{cursor: 'pointer'}}>
              Sunum Dosyası Yükle
              <input type="file" onChange={({target: {files}}) => onFinalFileChange(files, '')} />
              <Icon name="file" />
            </label>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{'Görev'}</div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Adı : ' + name}</div>
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görevin Kategorisi : ' + options.find((el) => el.value === taskCategoryId)?.text}</div>
        </div>
        {started && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Son Teslim Tarihi : ' + new Date(finishDate).toLocaleDateString()}</div>
          </div>
        )}
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Açıklaması : ' + (user.role !== 'company' ? description : '')}</div>
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Görev Durumu : ' + generateStatus(taskApplication)}</div>
        </div>
        {taskApplication.companyNoteForStudent && user.role === 'student' && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Kurumun geri bildirimi : ' + taskApplication.companyNoteForStudent}</div>
          </div>
        )}
        {user.role === 'company' && taskApplication.studentNoteForCompany && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Öğrenci Açıklaması : ' + taskApplication.studentNoteForCompany}</div>
          </div>
        )}
        {user.role === 'student' && taskApplication.commissionNote && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Komisyon geri bildirimi : ' + taskApplication.commissionNote}</div>
          </div>
        )}
        {user.role === 'student' && taskApplication.commissionLeaderNote && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Komisyon Başkanı geri bildirimi : ' + taskApplication.commissionLeaderNote}</div>
          </div>
        )}
        {user.role === 'teacher' && taskApplication.companyNoteForCommission && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Kurumun geri bildirimi : ' + taskApplication.companyNoteForCommission}</div>
          </div>
        )}
        {user.role === 'teacher' && taskApplication.studentNoteCommission && (
          <div className={styles.input_container}>
            <div className={styles.input_label}>{'Öğrenci açıklaması : ' + taskApplication.studentNoteCommission}</div>
          </div>
        )}
        <div className={styles.input_container}>
          <div className={styles.input_label}>
            {'Toplam Kazanım Puanı : ' +
              myTaskCategory?.gains
                ?.filter?.(
                  (el) => !(commissionSettings[taskApplication.commissionId]?.unGains?.indexOf?.(el) >= 0) && !(deletedGains?.indexOf?.(el) >= 0)
                )
                ?.reduce?.((p, c) => p + gains?.find((gain) => gain._id === c)?.point || 0, 0) || 0}
          </div>
        </div>
      </div>
      {user.role === 'company' && !startedPrev && (
        <div className={styles.toggle_container}>
          <div className={styles.input_label}>{'Başvuruyu Onayla'}</div>
          <Checkbox checked={started} onChange={(e, {checked}) => setStarted(checked) || setChanged(true)} toggle />
        </div>
      )}
      {files.length > 0 && <div style={{margin: '10px', fontWeight: '500'}}>Dosyalar :</div>}
      {files.length > 0 && <div>{renderFiles()}</div>}
      {filePercent > 0 && <div>{'Dosya Yükleniyor: %' + parseInt(filePercent)}</div>}

      {!taskApplication.studentCompleted && user.role !== 'teacher' && (
        <div style={{margin: '10px', fontWeight: '500'}}>
          <label style={{cursor: 'pointer'}}>
            Dosya Yükle
            <input type="file" onChange={({target: {files}}) => onFileChange(files, '')} />
            <Icon name="file" />
          </label>
        </div>
      )}
      {user.role === 'teacher' && taskApplication.studentLoadedFinalFile && <div>{renderFinalFile()}</div>}

      {!taskApplication.studentCancelled &&
        taskApplication.studentCompleted &&
        taskApplication.companyAccepted &&
        !taskApplication.commissionAccepted &&
        !taskApplication.commissionDeclined &&
        !taskApplication.commissionLeaderDeclined &&
        taskApplication.studentLoadedFinalFile &&
        !user.isCommissionLeader &&
        user.role === 'teacher' && (
          <div style={{margin: '5px'}}>
            <Button
              color="green"
              onClick={() => {
                setModalType(4);
                setShowModal(true);
              }}
            >
              Görev Sunumunu Onayla
            </Button>
          </div>
        )}
      {!taskApplication.studentCancelled &&
        taskApplication.studentCompleted &&
        taskApplication.companyAccepted &&
        !taskApplication.commissionAccepted &&
        !taskApplication.commissionDeclined &&
        !taskApplication.commissionLeaderDeclined &&
        taskApplication.studentLoadedFinalFile &&
        !user.isCommissionLeader &&
        user.role === 'teacher' && (
          <div style={{margin: '5px'}}>
            <Button
              color="red"
              onClick={() => {
                setModalType(5);
                setShowModal(true);
              }}
            >
              Görevi Reddet
            </Button>
          </div>
        )}

      {!taskApplication.studentCancelled &&
        taskApplication.studentCompleted &&
        taskApplication.companyAccepted &&
        !taskApplication.commissionDeclined &&
        !taskApplication.commissionLeaderDeclined &&
        !taskApplication.commissionLeaderAccepted &&
        taskApplication.studentLoadedFinalFile &&
        user.isCommissionLeader &&
        user.role === 'teacher' && (
          <div style={{margin: '5px'}}>
            <Button
              color="green"
              onClick={() => {
                setModalType(4);
                setShowModal(true);
              }}
            >
              Görev Sunumunu Onayla
            </Button>
          </div>
        )}
      {!taskApplication.studentCancelled &&
        taskApplication.studentCompleted &&
        taskApplication.companyAccepted &&
        !taskApplication.commissionDeclined &&
        !taskApplication.commissionLeaderDeclined &&
        !taskApplication.commissionLeaderAccepted &&
        taskApplication.studentLoadedFinalFile &&
        user.isCommissionLeader &&
        user.role === 'teacher' && (
          <div style={{margin: '5px'}}>
            <Button
              color="red"
              onClick={() => {
                setModalType(5);
                setShowModal(true);
              }}
            >
              Görevi Reddet
            </Button>
          </div>
        )}

      {user.role === 'teacher' && (
        <div className={styles.input_container}>
          <div className={styles.input_label}>{'Kazanımlar : '}</div>
          <Checkbox checked={showDetails} onChange={(e, {checked}) => setShowDetails(checked)} label="Kazanım Detaylarını Göster" />
          {renderGains()}
        </div>
      )}
      {changed && (
        <div className={styles.saveButton}>
          <Button color="blue" onClick={() => handleClick()}>
            {_id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </div>
      )}

      <Modal
        title={
          modalType === 0
            ? 'Görev Siliniyor'
            : modalType === 1
            ? 'Görevi Tamamla'
            : modalType === 2 || modalType === 4
            ? 'Görevi Onayla'
            : modalType === 5
            ? 'Görevi Reddet'
            : 'Sunum Dosyasını Yükleyiniz'
        }
        visible={showModal}
        onOk={async () => {
          setShowModal(false);
          if (modalType === 0) {
            await deleteTaskApplication();
            props.history.push('/taskApplications');
          } else if (modalType === 1) {
            await sendStudentCompleted();
            props.history.push('/taskApplications');
          } else if (modalType === 2) {
            await sendCompanyAccepted();
            props.history.push('/taskApplications');
          } else if (modalType === 3) {
            await sendCompanyDeclined();
            props.history.push('/taskApplications');
          } else if (modalType === 4) {
            await sendCommissionAccepted();
            props.history.push('/taskApplications');
          } else if (modalType === 5) {
            await sendCommissionDeclined();
            props.history.push('/taskApplications');
          } else if (modalType === 6) {
            await sendStudentLoadedFinalFile();
            props.history.push('/taskApplications');
          }
        }}
        onCancel={() => setShowModal(false)}
        okText="Evet"
        cancelText="Hayır"
        okButtonProps={{disabled: modalType === 6 ? !presentationFile : false}}
      >
        {modalType === 0 && <p>{name + ' silmek istiyor musunuz (geri çevrilemez)'}</p>}
        {modalType === 1 && <p>{name + ' Görev tamamlama isteği yollamak istiyor musunuz?(geri çevrilemez) '}</p>}
        {modalType === 1 && <p>{'Bu aşamadan sonra görevi kurum onayladığı takdirde , görevi komisyona sunma hakkı kazanacaksınız '}</p>}

        {/* {modalType === 1 && <p>{'Bu aşamadan sonra kurum isteğinizi onaylarsa görevi komisyona sunma hakkı kazanacaksınız'}</p>} */}
        {modalType === 2 && <p>{'Görevi tamamlama isteğini kabul etmek istiyor musunuz?(geri çevrilemez)'}</p>}
        {modalType === 3 && <p>{'Görevi tamamlama isteğini REDDETMEK istiyor musunuz?(geri çevrilemez)'}</p>}
        {modalType === 4 && <p>{'Görev sunumunu kabul edip görevi onaylamak istiyor musunuz?(geri çevrilemez)'}</p>}
        {modalType === 5 && <p>{'Görevi REDDETMEK istiyor musunuz?(geri çevrilemez)'}</p>}
        {(modalType === 5 || modalType === 4 || modalType === 3 || modalType === 2) && <p>{'Öğrenciye Geri bildirim düş:'}</p>}
        {(modalType === 5 || modalType === 4 || modalType === 3 || modalType === 2) && (
          <TextArea
            placeholder="'Geri Bildiriminizi buraya yazınız...'"
            value={studentNote}
            onChange={({target}) => setStudentNote(target.value)}
          ></TextArea>
        )}

        {(modalType === 3 || modalType === 2) && <p>{'Komisyona Geri bildirim düş:'}</p>}
        {(modalType === 3 || modalType === 2) && (
          <TextArea
            placeholder="'Geri Bildiriminizi buraya yazınız...'"
            value={commissionNote}
            onChange={({target}) => setCommissionNote(target.value)}
          ></TextArea>
        )}
        {modalType === 6 && <p>{'Görevi komisyona sunmak için lütfen sunum dosyanızı aşağıdan yükleyiniz'}</p>}
        {modalType === 6 && renderFinalFile()}
        {modalType === 6 && (
          <TextArea
            placeholder="'Komisyona gösterilmek üzere açıklama giriniz'"
            value={studentNoteCommission}
            onChange={({target}) => setStudentNoteCommission(target.value)}
          ></TextArea>
        )}

        {modalType === 1 && (
          <TextArea
            placeholder="Kuruma gönderilmek üzere açıklama giriniz"
            value={studentNoteForCompany}
            onChange={({target}) => setStudentNoteForCompany(target.value)}
          ></TextArea>
        )}
      </Modal>

      <div>
        {taskApplication.studentCancelled && user.role === 'company' && (
          <div>
            <Button
              color="red"
              onClick={() => {
                setModalType(0);
                setShowModal(true);
              }}
            >
              İptal isteğini onayla
            </Button>
          </div>
        )}
        {!taskApplication.studentCancelled &&
          !taskApplication.studentCompleted &&
          !taskApplication.companyAccepted &&
          !taskApplication.commissionAccepted &&
          taskApplication.started &&
          user.role === 'student' &&
          files.length > 0 && (
            <div>
              <Button
                color="red"
                onClick={() => {
                  setModalType(1);
                  setShowModal(true);
                }}
              >
                Görevi Tamamla
              </Button>
            </div>
          )}
        {!taskApplication.studentCancelled &&
          taskApplication.studentCompleted &&
          taskApplication.companyAccepted &&
          !taskApplication.commissionAccepted &&
          // !taskApplication.commissionDeclined &&
          !taskApplication.studentLoadedFinalFile &&
          user.role === 'student' && (
            <div>
              <Button
                color="red"
                onClick={() => {
                  setModalType(6);
                  setShowModal(true);
                }}
              >
                Sunum Dosyasını Yükle
              </Button>
            </div>
          )}
        {!taskApplication.studentCancelled &&
          taskApplication.studentCompleted &&
          !taskApplication.companyDeclined &&
          !taskApplication.companyAccepted &&
          user.role === 'company' && (
            <div style={{margin: '5px'}}>
              <Button
                color="green"
                onClick={() => {
                  setModalType(2);
                  setShowModal(true);
                }}
              >
                Görevi Onayla
              </Button>
            </div>
          )}
        {!taskApplication.studentCancelled &&
          taskApplication.studentCompleted &&
          !taskApplication.companyDeclined &&
          !taskApplication.companyAccepted &&
          user.role === 'company' && (
            <div style={{margin: '5px'}}>
              <Button
                color="red"
                onClick={() => {
                  setModalType(3);
                  setShowModal(true);
                }}
              >
                Görevi Reddet
              </Button>
            </div>
          )}
      </div>
    </div>
  );
}
