import React, {useEffect, useState} from 'react';
import {Table} from 'antd';

import BottomMenu from 'ui/components/bottom-menu';

import {apiURL, axiosClient} from 'service';
import './commission-page.module.css';

export default function GainsPage(props) {
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [commissions, setCommissions] = useState([]);

  const columns = [
    {title: 'Universite', key: '0', render: (el) => universities?.find?.((i) => i._id === el.universityId)?.name},
    {title: 'Fakülte', key: '1', render: (el) => departments?.find?.((i) => i._id === el.departmentId)?.name},
    {title: 'İsim', key: '2', dataIndex: 'name'},
    {title: 'Kullanıcılar', key: '3', render: (el) => el?.users?.length},
    {
      title: 'Detay',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/commissionEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const getUniversities = async (_id) => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setUniversities(data);
    }
  };
  const getDepartments = async (_id) => {
    const {data} = await axiosClient.get(apiURL.department, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setDepartments(data);
    }
  };
  const getCommissions = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setCommissions(data);
    }
  };

  useEffect(() => {
    getCommissions();
    getUniversities();
    getDepartments();
  }, []);

  return (
    <div className="users_container">
      <Table columns={columns} dataSource={commissions} scroll={{x: 800, y: 300}} />
      {/* <BottomMenu {...props} url={'/universityEdit'} /> */}
    </div>
  );
}
