import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import LottieAnimation from 'ui/components/lottie';
import Fade from 'react-reveal/Fade';

import data_dashboard from 'animation/data_dashboard.json';
//import rising_bubbles from 'animation/rising_bubbles.json';
import c1 from 'resources/images/1c.png';
import c2 from 'resources/images/2c.png';
import c3 from 'resources/images/3c.png';
import d1 from 'resources/images/d1.png';
import d2 from 'resources/images/d2.png';
import d3 from 'resources/images/d3.png';

import backdrop_floating_bubbles from 'animation/backdrop_floating_bubbles.json';
import styles from './activities.module.scss';
import {LanguageContext} from 'context';
import {translations} from 'resources';

export default function Activities() {
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  return (
    <section id="section_activities" className={styles.section_activities_container}>
      <Fade bottom>
        <div className={styles.activities_background}>
          <LottieAnimation loop lotti={backdrop_floating_bubbles} height={'100%'} width={'100%'} />
        </div>
        <h2 className={styles.section_activities_title}>{translations[appLanguage]['events']}</h2>

        <div className={styles.activities_cards_container}>
          <Fade left>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={c1} alt="konferans1" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 1</h4>
								<h6 className={styles.activity_card_content_sub_title}>Tanıtım</h6>
								<p className={styles.activity_card_content_text}>Yapılan ilk konferans, proje ekibi ve akademik mentörlerin tanışması ve projenin mentörlere tanıtılmasını kapsamaktadır. Tanıtım sonrası akademik mentörlerin proje ile ilişkin soruları cevaplanmış ve bir sonraki toplantının konusu ve mentörlerden istenen görevler hakkında bilgi verilmiştir.</p>
							</div> */}
            </div>
          </Fade>

          <Fade right>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={c2} alt="konferans2" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 2</h4>
								<h6 className={styles.activity_card_content_sub_title}>Staj Beceri ve Kazanımları</h6>
								<p className={styles.activity_card_content_text}>Yapılan ikinci konferans, öncesinde kapsamlı bir literatür taraması yoluyla hazırlanan staj beceri ve kazanımları ile ilişkin öneri ve tartışmaların yapıldığı bir süreci kapsamaktadır. Toplantı öncesi  beceri ve kazanımların yer aldığı dosyaları inceleyen akademik mentör grubu bu toplantıda staj beceri ve kazanımlarına yönelik eleştiriler yapmış ve bu eleştiriler doğrultusunda tablo revize edilmiştir.</p>
							</div> */}
            </div>
          </Fade>
        </div>
        <div className={styles.activities_cards_container}>
          <Fade left>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={c3} alt="konferans1" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 1</h4>
								<h6 className={styles.activity_card_content_sub_title}>Tanıtım</h6>
								<p className={styles.activity_card_content_text}>Yapılan ilk konferans, proje ekibi ve akademik mentörlerin tanışması ve projenin mentörlere tanıtılmasını kapsamaktadır. Tanıtım sonrası akademik mentörlerin proje ile ilişkin soruları cevaplanmış ve bir sonraki toplantının konusu ve mentörlerden istenen görevler hakkında bilgi verilmiştir.</p>
							</div> */}
            </div>
          </Fade>

          <Fade right>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={d1} alt="konferans2" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 2</h4>
								<h6 className={styles.activity_card_content_sub_title}>Staj Beceri ve Kazanımları</h6>
								<p className={styles.activity_card_content_text}>Yapılan ikinci konferans, öncesinde kapsamlı bir literatür taraması yoluyla hazırlanan staj beceri ve kazanımları ile ilişkin öneri ve tartışmaların yapıldığı bir süreci kapsamaktadır. Toplantı öncesi  beceri ve kazanımların yer aldığı dosyaları inceleyen akademik mentör grubu bu toplantıda staj beceri ve kazanımlarına yönelik eleştiriler yapmış ve bu eleştiriler doğrultusunda tablo revize edilmiştir.</p>
							</div> */}
            </div>
          </Fade>
        </div>
        <div className={styles.activities_cards_container}>
          <Fade left>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={d2} alt="konferans1" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 1</h4>
								<h6 className={styles.activity_card_content_sub_title}>Tanıtım</h6>
								<p className={styles.activity_card_content_text}>Yapılan ilk konferans, proje ekibi ve akademik mentörlerin tanışması ve projenin mentörlere tanıtılmasını kapsamaktadır. Tanıtım sonrası akademik mentörlerin proje ile ilişkin soruları cevaplanmış ve bir sonraki toplantının konusu ve mentörlerden istenen görevler hakkında bilgi verilmiştir.</p>
							</div> */}
            </div>
          </Fade>

          <Fade right>
            <div className={styles.activity_card}>
              <img className={styles.activity_card_image} src={d3} alt="konferans2" />
              {/* <div className={styles.activity_card_content}>
								<h4 className={styles.activity_card_content_main_title}>Konferans 2</h4>
								<h6 className={styles.activity_card_content_sub_title}>Staj Beceri ve Kazanımları</h6>
								<p className={styles.activity_card_content_text}>Yapılan ikinci konferans, öncesinde kapsamlı bir literatür taraması yoluyla hazırlanan staj beceri ve kazanımları ile ilişkin öneri ve tartışmaların yapıldığı bir süreci kapsamaktadır. Toplantı öncesi  beceri ve kazanımların yer aldığı dosyaları inceleyen akademik mentör grubu bu toplantıda staj beceri ve kazanımlarına yönelik eleştiriler yapmış ve bu eleştiriler doğrultusunda tablo revize edilmiştir.</p>
							</div> */}
            </div>
          </Fade>
        </div>
      </Fade>
    </section>
  );
}
