import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {Icon} from 'semantic-ui-react';

import {LanguageContext} from 'context';
import {translations} from 'resources';
import styles from './bottom-menu-modal.module.css';

export default function BottomMenuModal(props) {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {modal_container, menu_container, menu_item_container, menu_item_name, menu_item_icon_container} = styles;

  return ReactDOM.createPortal(
    <div onClick={props.closeModal} className={modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={menu_container}>
        <div onClick={props.closeModal} className={menu_item_container}>
          <div
            onClick={() => {
              props.history.push(props.url);
            }}
            className={menu_item_name}
          >
            {translations[appLanguage]['g41']}
          </div>
          <div className={menu_item_icon_container}>
            <Icon name="plus" />
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
