import React, {useEffect, useState, useContext} from 'react';
import {Table, Button} from 'antd';
import {Checkbox} from 'semantic-ui-react';

import BottomMenu from 'ui/components/bottom-menu';
// import {PolarArea, defaults, Doughnut} from 'react-chartjs-2';

import {apiURL, axiosClient} from 'service';
import {UserContext} from 'context';
import './main-page.css';
import styles from './main-page.module.css';

export default function GainsPage(props) {
  const [gains, setGains] = useState([]);
  const [userGains, setUserGains] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [selectedGains, setSelectedGains] = useState([]);
  const [finishedGains, setFinishedGains] = useState([]);
  const [unFinishedGains, setUnFinishedGains] = useState([]);
  const [_id, set_id] = useState('');
  const [changed, setChanged] = useState(false);
  const [teknikGains, setTeknikGains] = useState([]);
  const [aktarilabilirGains, setAktarilabilirGains] = useState([]);
  const [finishedTeknikPoint, setFinishedTeknikPoint] = useState(0);
  const [unfinishedTeknikPoint, setUnfinishedTeknikPoint] = useState(0);
  const [finishedAktarilabilirPoint, setFinishedAktarilabilirPoint] = useState(0);
  const [unfinishedAktarilabilirPoint, setUnfinishedAktarilabilirPoint] = useState(0);

  const {
    state: {user, isSigned},
    getUser,
  } = useContext(UserContext);

  const studentColumns = [
    {title: 'Türü', dataIndex: 'gainType', width: '80px', key: '3'},
    {title: 'Alt Türü', dataIndex: 'gainSubType', key: '4'},
    {title: 'Kodu', dataIndex: 'code', width: '70px', key: '2'},
    // {title: 'İsim', dataIndex: 'name', key: 'name', fixed: 'left'},
    {title: 'Açıklama', dataIndex: 'description', width: '300px', key: '0'},
    {title: 'Puan', dataIndex: 'point', width: '70px', key: '1'},
    {
      title: 'Listeme Ekle',
      key: 'operation',
      fixed: 'right',
      width: 90,
      // render: (el) => <a href={`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`}>Detay</a>,
      render: (el) => (
        <Checkbox
          checked={selectedGains.indexOf(el._id) >= 0}
          onChange={(e, {checked}) => {
            if (checked) setSelectedGains([...selectedGains, el._id]);
            else setSelectedGains(selectedGains.filter((item) => item !== el._id));
            setChanged(true);
          }}
          toggle
        />
      ),
      // render: (el) => <a onClick={() => props.history.push(`${process.env.PUBLIC_URL}/gainEdit${JSON.stringify({_id: el._id})}`)}>Düzenle</a>,
    },
  ];

  const handleClick = async () => {
    setChanged(false);
    await axiosClient.put(
      apiURL.user,
      {_id, selectedGains},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
  };

  const getGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.gain, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (Array.isArray(data)) {
      setGains(data);
    }
  };

  const getCompletedGains = async (_id) => {
    const {data} = await axiosClient.get(apiURL.getCategorizedGains, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });

    if (data.finishedGains) setFinishedGains(data.finishedGains);
    if (data.unFinishedGains) setUnFinishedGains(data.unFinishedGains);
  };

  const getUniversities = async (_id) => {
    const {data} = await axiosClient.get(apiURL.university, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setUniversities(data);
  };
  const getDepartments = async (_id) => {
    const {data} = await axiosClient.get(apiURL.department, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setDepartments(data);
  };
  const getCommissions = async (_id) => {
    const {data} = await axiosClient.get(apiURL.commission, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (Array.isArray(data)) setCommissions(data);
  };

  const calcPoint = () => {
    return gains.filter((el) => selectedGains.indexOf(el._id) >= 0).reduce((prev, val) => prev + val.point, 0);
  };

  const calcCompletedPoint = () => {
    return finishedGains?.reduce?.((prev, val) => prev + val.point, 0) || 0;
  };

  const renderSelectedGains = () => {
    return gains
      .filter((el) => selectedGains.indexOf(el._id) >= 0)
      .map((item) => (
        <div
          style={{
            margin: '5px',
            fontSize: '20px',
            border: 'solid 1px',
            borderColor: finishedGains.indexOf(item._id) >= 0 ? 'green' : 'cadetblue',
            padding: '0px 3px',
          }}
        >
          <span style={{margin: '5px'}}>{item.code}</span>
        </div>
      ));
  };

  useEffect(() => {
    getGains();
    getUniversities();
    getDepartments();
    getCommissions();
    getCompletedGains();
  }, []);
  useEffect(() => {
    if (user && isSigned) {
      setSelectedGains(user.selectedGains || []);
      set_id(user._id);
    }
  }, [user, isSigned]);
  useEffect(() => {
    if (user && isSigned && gains) {
      setUserGains(gains?.filter?.((el) => user.selectedGains?.indexOf(el._id) >= 0));
    }
  }, [user, gains, isSigned]);
  useEffect(() => {
    setAktarilabilirGains(userGains?.filter?.((el) => el.gainType === 'Aktarılabilir'));
    setTeknikGains(userGains?.filter?.((el) => el.gainType === 'Teknik'));
  }, [userGains]);
  useEffect(() => {
    console.log('aktarilabilirGains', aktarilabilirGains);

    // setFinishedAktarilabilirPoint(aktarilabilirGains.filter((el) => finishedGains.indexOf(el._id) >= 0)?.reduce?.((p, c) => p + c.point, 0) || 0);
    setFinishedAktarilabilirPoint(finishedGains.filter((el) => el.gainType === 'Aktarılabilir')?.reduce?.((p, c) => p + c.point, 0) || 0);
    setUnfinishedAktarilabilirPoint(unFinishedGains.filter((el) => el.gainType === 'Aktarılabilir')?.reduce?.((p, c) => p + c.point, 0) || 0);

    setFinishedTeknikPoint(finishedGains.filter((el) => el.gainType === 'Teknik')?.reduce?.((p, c) => p + c.point, 0) || 0);
    setUnfinishedTeknikPoint(unFinishedGains.filter((el) => el.gainType === 'Teknik')?.reduce?.((p, c) => p + c.point, 0) || 0);
    console.log('selectedGains', selectedGains);
  }, [finishedGains, unFinishedGains]);

  const teknikData = {
    datasets: [
      {
        data: [finishedTeknikPoint, unfinishedTeknikPoint],
        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#36A2EB', '#944FB0'], // "#E7E9ED",
        label: 'My dataset', // for legend
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Tamamlanan', 'Devam Eden'],
  };
  const aktarilabilirData = {
    datasets: [
      {
        data: [finishedAktarilabilirPoint, unfinishedAktarilabilirPoint],
        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#36A2EB', '#944FB0'], // "#E7E9ED",
        label: 'My dataset', // for legend
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Tamamlanan', 'Devam Eden'],
  };

  const renderStudent = () => {
    return (
      <div className="users_container">
        {/* <div className={styles.header_container}>
          <div>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{user.firstName + ' ' + user.lastName}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>
              {universities?.find?.((el) => el._id === user.universityId)?.name}
            </div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{departments?.find?.((el) => el._id === user.departmentId)?.name}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{commissions?.find?.((el) => el._id === user.commissionId)?.name}</div>
          </div>
          <div style={{textAlign: 'center', color: '#FF6384', maxWidth: '150px'}}>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>
              {commissions?.find?.((el) => el._id === user.commissionId)?.minGainPoint || 0}
            </div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{'Hedef Kazanım Puanı'}</div>
          </div>
          <div style={{textAlign: 'center', color: '#4BC0C0', maxWidth: '150px'}}>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{calcPoint()}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{'Seçilen Kazanım Puanları'}</div>
          </div>
          <div style={{textAlign: 'center', color: 'limegreen', maxWidth: '150px'}}>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{calcCompletedPoint()}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{'Edinilmiş Kazanım Puanları'}</div>
          </div>
        </div> */}

        {user.role === 'teacher' && (
          <div style={{margin: '5px', fontWeight: '500', fontSize: 'large'}}>
            <span style={{margin: '5px'}}>Öğrenci Sayısı:</span>
            <span style={{margin: '5px'}}>
              {commissions?.find?.((el) => el._id === user.commissionId)?.users?.filter?.((user) => user.role === 'student')?.length}
            </span>
          </div>
        )}

        {/* {user.role === 'student' && <div style={{margin: '5px', fontWeight: '700', fontSize: 'larger'}}>Seçilen Kazanımlar:</div>}
        {user.role === 'student' && (
          <div style={{margin: '5px', display: 'flex', minHeight: '50px', alignItems: 'center'}}>{renderSelectedGains()}</div>
        )} */}
        <div className={styles.content_container}>
          {/* <div style={{maxWidth: 300, backgroundColor: 'white'}}>
            <div className={styles.chart_title}>Teknik Beceriler</div>
            <Polar height="400" data={data} />
          </div> */}
          {/* <div style={{maxWidth: 300, backgroundColor: 'white'}}>
            <div className={styles.chart_title}>Teknik Beceriler</div>
            <Doughnut height="400" data={teknikData} />
          </div>
          <div style={{maxWidth: 300, backgroundColor: 'white'}}>
            <div className={styles.chart_title}>Aktarılabilir Beceriler</div>
            <Doughnut height="400" data={aktarilabilirData} />
          </div> */}
        </div>
      </div>
    );
  };

  const renderTeacher = () => {
    return (
      <div className="users_container">
        <div className={styles.header_container}>
          {/* <div>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{user.firstName + ' ' + user.lastName}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>
              {universities?.find?.((el) => el._id === user.universityId)?.name}
            </div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{departments?.find?.((el) => el._id === user.departmentId)?.name}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{commissions?.find?.((el) => el._id === user.commissionId)?.name}</div>
          </div>
          <div style={{textAlign: 'center', color: 'limegreen', maxWidth: '150px'}}>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{'---'}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{'Öğrenci Sayısı'}</div>
          </div> */}
        </div>
        <div className={styles.content_container}>
          <div style={{maxWidth: 300}}>
            {/* <Polar height="400" data={teknikData} /> */}
          </div>
        </div>
      </div>
    );
  };
  const renderCompany = () => {
    return (
      <div className="users_container">
        <div className={styles.header_container}>
          <div>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{user.firstName}</div>
            {/* <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>
              {universities?.find?.((el) => el._id === user.universityId)?.name}
            </div> */}
            {/* <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{departments?.find?.((el) => el._id === user.departmentId)?.name}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{commissions?.find?.((el) => el._id === user.commissionId)?.name}</div> */}
          </div>
          {/* <div style={{textAlign: 'center', color: 'limegreen', maxWidth: '150px'}}>
            <div style={{margin: '5px', fontWeight: '500', fontSize: 'x-large'}}>{'---'}</div>
            <div style={{margin: '5px', fontWeight: '400', fontSize: 'large'}}>{'Stajyer Sayısı'}</div>
          </div> */}
        </div>
        {/* <div className={styles.content_container}>
          <div style={{maxWidth: 300}}>
            <Polar height="400" data={teknikData} />
          </div>
        </div> */}
      </div>
    );
  };

  if (user.role === 'student') return renderStudent();
  else if (user.role === 'teacher') return renderTeacher();
  else return renderCompany();
}
