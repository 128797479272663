import React, {useEffect, useContext} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import detectBrowserLanguage from 'detect-browser-language';

//Pages
import LandingPage from 'ui/pages/landing-page';
import Home from 'ui/pages/home';
import SignInPage from 'ui/pages/authentication/signin-page';
import SignUpPage from 'ui/pages/authentication/signup-page';
import EmailVerificationPage from 'ui/pages/authentication/email-verification-page';
import PasswordResetPage from 'ui/pages/authentication/password-reset-page';
import UsersPage from 'ui/pages/students-page';
import CompaniesPage from 'ui/pages/companies-page';
import TeachersPage from 'ui/pages/teachers-page';
import GainsPage from 'ui/pages/gains-page';
import UniversitiesPage from 'ui/pages/university-page';
import TasksPage from 'ui/pages/tasks-page';
import TaskApplications from 'ui/pages/task-applications-page';
import TaskApplyPage from 'ui/pages/task-apply-page';
import UniversityEditPage from 'ui/pages/university-edit-page';
import DepartmentEditPage from 'ui/pages/department-edit-page';
import CommissionEditPage from 'ui/pages/commission-edit-page';
import CommissionSettingsPage from 'ui/pages/commission-settings-page';
import StudentEditPage from 'ui/pages/student-edit-page';
import CompanyEditPage from 'ui/pages/company-edit-page';
import TeacherEditPage from 'ui/pages/teacher-edit-page';
import TaskEditPage from 'ui/pages/task-edit-page';
import TaskApplicationEditPage from 'ui/pages/task-application-edit-page';
import MainPage from 'ui/pages/main-page';
import MessagesPage from 'ui/pages/messages-page';
import NewChatPage from 'ui/pages/new-chat-page';
import ChatPage from 'ui/pages/chat-page';

import CommissionsPage from 'ui/pages/commission-page';

import Categories from 'ui/pages/categories-page';
import AccountSettingsPage from 'ui/pages/settings/account-settings-page';
import GainEditPage from 'ui/pages/gain-edit-page';
import CategoriesEditPage from 'ui/pages/categories-edit-page';

import Sidebar from 'ui/components/sidebar';
import Topbar from 'ui/components/topbar';
import {LanguageContext, UserContext, SignInContext, SignUpContext} from 'context';
import history from './history';
import styles from './app.module.css';
import homeworkCycles from 'ui/pages/homework-cycles';
import homeworkCycleEditPage from 'ui/pages/homework-cycle-edit-page';
import homeworks from 'ui/pages/homeworks/';
import loadHomework from 'ui/pages/loadHomework';
import ScoringPage from 'ui/pages/scoring';
import ReportPage from 'ui/pages/report';
import printReportPage from 'ui/pages/printReport';
import generalReports from 'ui/pages/generalReports';

function App() {
  const {
    changeAppLanguage,
    state: {languageSetted},
  } = useContext(LanguageContext);
  const {
    state: {user, isSigned, unreadMessages},
    getMessageCount,
    getUser,
  } = useContext(UserContext);
  const {
    state: {isSignInSuccess},
  } = useContext(SignInContext);
  const {
    state: {isSignUpSuccess},
  } = useContext(SignUpContext);
  const {main_container, main_content_container, warning_container} = styles;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/');
    }
  });
  useEffect(() => {
    if (user._id) getMessageCount();
  }, [user]);
  useEffect(() => {
    (isSignInSuccess || isSignUpSuccess) && getUser();
  }, [isSignInSuccess, isSignUpSuccess]); //eslint-disable-line

  useEffect(() => {
    if (!languageSetted) {
      let lang = detectBrowserLanguage();
      console.log('lang', lang);
      if (lang === 'tr' || lang === 'tr-TR') lang = 'tr';
      else if (lang === 'ar') lang = 'arb';
      else lang = 'en';
      changeAppLanguage(lang);
    }
    localStorage.getItem('token') && getUser();
  }, []); //eslint-disable-line

  const MainContainer = () => (
    <div className={main_container}>
      {!window.location.pathname.includes('printReport') && <Topbar />}

      {!window.location.pathname.includes('printReport') &&
        (user.isMaster || user.isAdmin || user.approved ? (
          <div className={main_content_container}>
            <Sidebar />
            <Route path="/main" exact component={MainPage} />
            <Route path="/users" exact component={UsersPage} />
            <Route path="/gains" exact component={GainsPage} />
            <Route path="/companies" exact component={CompaniesPage} />
            <Route path="/teachers" exact component={TeachersPage} />
            <Route path="/commissions" exact component={CommissionsPage} />
            <Route path="/categories" exact component={Categories} />
            <Route path="/cycles" exact component={homeworkCycles} />
            <Route path="/cyclesForHomework" exact component={homeworkCycles} />
            <Route path="/cycleEdit:id" exact component={homeworkCycleEditPage} />
            <Route path="/scoringHomework:id" exact component={ScoringPage} />
            <Route path="/report:id" exact component={ReportPage} />

            <Route path="/homeworks:id" exact component={homeworks} />
            <Route path="/loadHomeWork:id" exact component={loadHomework} />
            <Route path="/tasks" exact component={TasksPage} />
            <Route path="/taskApplications" exact component={TaskApplications} />
            <Route path="/universities" exact component={UniversitiesPage} />
            <Route path="/universityEdit" exact component={UniversityEditPage} />
            <Route path="/universityEdit:id" exact component={UniversityEditPage} />
            <Route path="/applyTask:id" exact component={TaskApplyPage} />
            <Route path="/taskEdit" exact component={TaskEditPage} />
            <Route path="/taskEdit:id" exact component={TaskEditPage} />
            <Route path="/taskApplicationEdit:id" exact component={TaskApplicationEditPage} />
            <Route path="/departmentEdit" exact component={DepartmentEditPage} />
            <Route path="/departmentEdit:id" exact component={DepartmentEditPage} />
            <Route path="/commissionSettings" exact component={CommissionSettingsPage} />
            <Route path="/commissionEdit" exact component={CommissionEditPage} />
            <Route path="/commissionEdit:id" exact component={CommissionEditPage} />
            <Route path="/studentEdit:id" exact component={StudentEditPage} />
            <Route path="/companyEdit:id" exact component={CompanyEditPage} />
            <Route path="/teacherEdit:id" exact component={TeacherEditPage} />
            <Route path="/gainEdit" exact component={GainEditPage} />
            <Route path="/gainEdit:id" exact component={GainEditPage} />
            <Route path="/categoryEdit" exact component={CategoriesEditPage} />
            <Route path="/categoryEdit:id" exact component={CategoriesEditPage} />
            <Route path="/accountSettings" exact component={AccountSettingsPage} />
            <Route path="/messages" exact component={MessagesPage} />
            <Route path="/newChat" exact component={NewChatPage} />
            <Route path="/chat:id" exact component={ChatPage} />
            <Route path="/generalReports" exact component={generalReports} />
          </div>
        ) : (
          isSigned && <div className={warning_container}> Üyeliğiniz Henüz Onaylanmadı Lütfen Bekleyiniz </div>
        ))}
    </div>
  );

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/signup" exact component={SignUpPage} />
          <Route path="/signin" exact component={SignInPage} />
          <Route path="/verify_email" exact component={EmailVerificationPage} />
          <Route path="/reset_password" exact component={PasswordResetPage} />
          <Route path="/printReport:id" exact component={printReportPage} />
          <Route path="/generalReports/:tab/:category" exact component={generalReports} />

          <Route component={MainContainer} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
